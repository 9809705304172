import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import content from 'content';
import { Utils, Analytics } from 'scripts';
import { getDateFromString } from 'scripts/Dates';
import styled from 'styled-components';
import Header from 'components/layout/header';
import HeaderIframe from 'components/layout/headerIframe';
import SEO from 'components/seo';
import { Wrapper } from 'components/layout/wrapper';
import { Button } from 'components/button';
import NoPrefAvatar from 'icons/noprefavatar.png';
import configData from '../../config.json';

import { getAvatarStyle } from './style';

const inIFrame = Utils.inIFrame();
const StyledAvatar = styled.img(getAvatarStyle);

const ConfirmationScreen = ({
    selectedDate,
    selectedTimeslot,
    storeRef,
    employees,
    bookingId,
    resourceId,
    wechatID,
    language,
}) => {
    useEffect(() => {
        Analytics({
            type: 'Confirmation',
            label: `${storeRef}|${
                selectedTimeslot && selectedTimeslot.replace(':', '')
            }|${bookingId}`,
        });
    }, []);

    // workaround for sonarcloud code smell issue
    const contentWindow = window;

    // const { context, selectedDate, selectedTimeslot } = this.props;
    let selectedSalesRepImage;

    if (resourceId) {
        const employee = employees.find((x) => x.id === resourceId);

        if (employee && employee.image) selectedSalesRepImage = employee.image;
    }
    const formatDate = (date) => {
        const fullDate = getDateFromString(date, 'yyyy-mm-dd');
        if (!(fullDate instanceof Date)) {
            return null;
        }

        const D = fullDate.getDay();
        const d = fullDate.getDate();
        const m = fullDate.getMonth();
        const Y = fullDate.getFullYear();
        const formatDay = !wechatID ? `${moment.weekdays(D)},`:''

        return  wechatID || language==='zh-cn' ?`${Y}年${m + 1}月${d}日` : `${formatDay} ${d} ${moment.months(m)}`;
    };

    const sendEventMessage = () => {
        contentWindow.parent.postMessage(
            'closeRadicalPersonalModal',
            configData.TARGET_ORIGIN.parent
        );
    };

    return (
        <>
            {inIFrame ? (
                <HeaderIframe title={content.confirmAppointmentTitle} />
            ) : (
                <>
                    <SEO title={content.metaData.confirmAppointmentTitle} />
                    <Header />
                </>
            )}

            <main>
                <Wrapper isSmall isVerticalAligned>
                    {(selectedSalesRepImage && (
                        <StyledAvatar src={selectedSalesRepImage} alt="" />
                    )) || <StyledAvatar src={NoPrefAvatar} alt="" />}

                    <h1>{content.confirmationHeader}</h1>
                    <p>
                        {content.formatString(
                            !wechatID && language !== 'zh-cn'
                                ? content.confirmationText
                                : content.confirmationTextSMS,
                            {
                                date: formatDate(selectedDate),
                                time: selectedTimeslot,
                            }
                        )}
                    </p>
                    <Button
                        label={content.btn.done}
                        outlineStyle
                        onClick={() => {
                            Analytics({
                                type: 'Explore',
                                label: `${storeRef}|${
                                    selectedTimeslot &&
                                    selectedTimeslot.replace(':', '')
                                }`,
                            });
                            if (window.parent === window) {
                                window.location.href = 'https://suitsupply.com';
                            } else {
                                sendEventMessage();
                            }
                        }}
                    />
                </Wrapper>
            </main>
        </>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        context: reducer.context,
        selectedDate: reducer.selectedDate,
        selectedTimeslot: reducer.selectedTimeslot,
        storeRef: reducer.storeRef,
        selectedSalesRep: reducer.selectedSalesRep,
        employees: reducer.employees,
        bookingId: reducer.bookingId,
        serviceId: reducer.serviceId,
        resourceId: reducer.resourceId,
        wechatID: reducer.wechatID,
        language: reducer.language,
    };
};

ConfirmationScreen.propTypes = {
    context: PropTypes.shape({
        mode: PropTypes.string,
    }).isRequired,
    selectedDate: PropTypes.string,
    selectedTimeslot: PropTypes.string,
    storeRef: PropTypes.string,
    employees: PropTypes.arrayOf(PropTypes.object).isRequired,
    bookingId: PropTypes.string,
    resourceId: PropTypes.string,
    wechatID: PropTypes.string,
};

ConfirmationScreen.defaultProps = {
    selectedDate: null,
    selectedTimeslot: null,
    storeRef: null,
    bookingId: null,
    resourceId: null,
    wechatID: null,
};

export default connect(mapStateToProps)(ConfirmationScreen);
