import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { actionTypes } from 'redux/actions';
import { connect } from 'react-redux';
import { history } from 'redux/store';
import { Config } from 'scripts';

import BackButton from 'components/backButton';

import { push } from 'connected-react-router';

import { getHeaderStyle } from './style';

const StyledHeader = styled.header(getHeaderStyle);

const Header = ({ reroute, setState }) => {
    return (
        <StyledHeader>
            {history &&
                history.location &&
                history.location.pathname === Config.ROUTES.USER_DATA && (
                    <BackButton
                        onClick={() => {
                            setState({
                                preSelectedTimeslot: null,
                            });

                            history.push('/booking');
                        }}
                    />
                )}
        </StyledHeader>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        storeId: reducer.storeId,
        reservationData: reducer.reservationData,
        preSelectedTimeslot: reducer.preSelectedTimeslot,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        goBackToBooking: (payload) =>
            dispatch({ type: actionTypes.GO_BACK_BOOKING, payload }),
        reroute: (payload) => dispatch(push(payload)),
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};

Header.propTypes = {
    reservationData: PropTypes.shape({
        authToken: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.object),
    }),
    reroute: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
};

Header.defaultProps = {
    reservationData: null,
    storeId: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
