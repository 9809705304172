import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import PropTypes from 'prop-types';
import { actionTypes } from 'redux/actions';
import { Analytics } from 'scripts';
import styled from 'styled-components';
import content from 'content';
import { Loader } from 'components/loader';
import { Wrapper } from 'components/layout/wrapper';
import { Button } from 'components/button';
import { isSafari } from 'react-device-detect';

import {
    getTimeslotsStyle,
    getTimeslotsContainerStyle,
    getTimeslotListItemStyle,
    getTimeslotItemStyle,
    getNoTimeslotContainerStyle,
    getNoTimeslotContentStyle,
    getNoTimeslotHeaderStyle,
} from './style';

import { StyledNoEmployeesBtnWrapper } from 'screens/bookingEmployee/components/employeesList/employeesListStyle';
import {
    StyledAdvisorContainer,
    StyledAdvisorImage,
} from 'screens/bookingEmployee/components/employeesListItem/style';
import { getStyledNoPrefAvatar } from 'screens/bookingEmployee/style';
import BookingImg from 'icons/bookingImg.png';
import { StyledTextAdvisor } from './../../screens/bookingEmployee/components/employeesListItem/style';

const StyledTimeslots = styled.div(getTimeslotsStyle);
const StyledTimeslotsContainer = styled.ul(getTimeslotsContainerStyle);
const StyledTimeslotListItem = styled.li(getTimeslotListItemStyle);
const StyledTimeslot = styled.button(getTimeslotItemStyle);
const StyledNoTimeslotContainer = styled.div(getNoTimeslotContainerStyle);
const StyledNoTimeslotHeader = styled.h3(getNoTimeslotHeaderStyle);
const StyledNoTimeslotContent = styled.div(getNoTimeslotContentStyle);
const StyledNoPrefAvatar = styled.img(getStyledNoPrefAvatar);

const Timeslots = ({
    context,
    error,
    loadingDatesAndTimeslots,
    loadingTimeslots,
    preSelectedTimeslot,
    selectedDate,
    selectedTimeslot,
    setState,
    availableTimeSlots,
    isFromEpp,
    employeeAlreadySelected,
    wechatID,
}) => {
    const availableTimeslots =
        availableTimeSlots &&
        availableTimeSlots
            .filter((el) => el.start.slice(0, 10) === selectedDate)
            .map((el) => ({
                slot: el.start.slice(11, 16),
                date: el.start,
                available: el.available,
                utc: el.utc,
            }));

    const isSelected = (item) => {
        return selectedTimeslot && selectedTimeslot === item;
    };

    const isPreSelected = (item) => {
        return preSelectedTimeslot && preSelectedTimeslot === item;
    };

    const noTimeSlotsAvailable = availableTimeSlots.every(
        (date) => date.available === false
    );

    const formatSlot = (slot) => {
        const { country } = context;
        return country && country === 'us'
            ? moment(slot, 'hh:mm').format('hh:mm A')
            : slot;
    };

    const sendEventMessage = () => {
        employeeAlreadySelected
            ? window.parent.postMessage('scrollToOpeningHours', '*')
            : window.parent.postMessage('closeRadicalPersonalModal', '*');
    };
    const formatButtonLabel = employeeAlreadySelected
        ? content.openingHours
        : content.a11yHeaderBtnClose;

    return (
        <>
            {loadingDatesAndTimeslots && <Loader isBlack />}
            {loadingTimeslots && <Loader isBlack />}
            <Wrapper>
                <StyledTimeslots
                    availableTimeslots={!availableTimeslots.length}
                >
                    {!noTimeSlotsAvailable ? (
                        <>
                            <StyledTimeslotsContainer>
                                {availableTimeslots.map((item) => {
                                    return (
                                        <StyledTimeslotListItem
                                            key={`slot-${item.slot}`}
                                        >
                                            <StyledTimeslot
                                                key={`slot-${item.slot}`}
                                                isDisabled={!item.available}
                                                isPreSelected={
                                                    isPreSelected(item.slot) &&
                                                    !isSelected(item.slot)
                                                }
                                                isSelected={isSelected(
                                                    item.slot
                                                )}
                                                onClick={() => {
                                                    setState({
                                                        selectedTimeslot:
                                                            isSelected(
                                                                item.slot
                                                            )
                                                                ? null
                                                                : item.slot,
                                                    });

                                                    setState({
                                                        selectedTimeslotUtc:
                                                            item.utc,
                                                    });
                                                    if (preSelectedTimeslot) {
                                                        setState({
                                                            preSelectedTimeslot:
                                                                null,
                                                        });
                                                    }
                                                    // clear errors
                                                    if (error) {
                                                        setState({
                                                            error: null,
                                                        });
                                                    }

                                                    Analytics({
                                                        type: 'Time selection',
                                                        label:
                                                            item &&
                                                            item.slot &&
                                                            item.slot.replace(
                                                                ':',
                                                                ''
                                                            ),
                                                    });
                                                }}
                                            >
                                                {formatSlot(item.slot)}
                                            </StyledTimeslot>
                                        </StyledTimeslotListItem>
                                    );
                                })}
                            </StyledTimeslotsContainer>
                            {!selectedTimeslot &&
                                context.mode !== 'reschedule' && (
                                    <StyledAdvisorContainer>
                                        <StyledAdvisorImage>
                                            <StyledNoPrefAvatar
                                                src={BookingImg}
                                                alt=""
                                            />
                                        </StyledAdvisorImage>

                                        <StyledTextAdvisor>
                                            {content.preferTextTime}
                                            <br />
                                            {!wechatID &&
                                                content.preferTextDate}
                                        </StyledTextAdvisor>

                                        <Button
                                            label={content.styleAdvisorsBtn}
                                            outlineStyle
                                            onClick={() => {
                                                setState({
                                                    selectedSalesRep: null,
                                                });
                                                setState({
                                                    reselectSalesRep: true,
                                                });
                                                setState({
                                                    renderSecondScreen: false,
                                                });
                                                setState({
                                                    employeeAlreadySelected: false,
                                                });
                                                setState({
                                                    calendarStartIndex: null,
                                                });
                                            }}
                                        />
                                    </StyledAdvisorContainer>
                                )}
                        </>
                    ) : (
                        <StyledNoTimeslotContainer>
                            <StyledNoTimeslotHeader>
                                {content.noTimeslostAvailable.header}
                            </StyledNoTimeslotHeader>
                            <StyledNoTimeslotContent>
                                {content.noTimeslostAvailable.content}
                            </StyledNoTimeslotContent>
                            <StyledNoEmployeesBtnWrapper>
                                {!isSafari && (
                                    <Button
                                        type="button"
                                        onClick={sendEventMessage}
                                        label={formatButtonLabel}
                                        outlineStyle
                                    />
                                )}

                                <Button
                                    type="button"
                                    label={content.anotherStyleAdvisorBtn}
                                    smallFont
                                    onClick={() => {
                                        setState({
                                            selectedSalesRep: null,
                                        });
                                        setState({
                                            reselectSalesRep: true,
                                        });
                                        setState({
                                            renderSecondScreen: false,
                                        });
                                        setState({
                                            calendarStartIndex: null,
                                        });
                                    }}
                                />
                            </StyledNoEmployeesBtnWrapper>
                        </StyledNoTimeslotContainer>
                    )}
                </StyledTimeslots>
            </Wrapper>
        </>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        context: reducer.context,
        error: reducer.error,
        loadingDatesAndTimeslots: reducer.loadingDatesAndTimeslots,
        loadingTimeslots: reducer.loadingTimeslots,
        preSelectedTimeslot: reducer.preSelectedTimeslot,
        selectedDate: reducer.selectedDate,
        selectedTimeslot: reducer.selectedTimeslot,
        availableTimeSlots: reducer.availableTimeSlots,
        employeeAlreadySelected: reducer.employeeAlreadySelected,
        wechatID: reducer.wechatID,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};

Timeslots.propTypes = {
    context: PropTypes.shape({
        country: PropTypes.string,
        mode: PropTypes.string,
    }).isRequired,
    error: PropTypes.string,
    loadingDatesAndTimeslots: PropTypes.bool.isRequired,
    loadingTimeslots: PropTypes.bool.isRequired,
    preSelectedTimeslot: PropTypes.string,
    selectedDate: PropTypes.string,
    selectedTimeslot: PropTypes.string,
    setState: PropTypes.func.isRequired,
    timeslots: PropTypes.arrayOf(PropTypes.object),
    wechatID: PropTypes.string,
};

Timeslots.defaultProps = {
    error: null,
    preSelectedTimeslot: null,
    selectedDate: null,
    selectedTimeslot: null,
    timeslots: [],
    wechatID: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeslots);
