import React, { Component } from 'react';
import { connect } from 'react-redux';
import TinySlider from 'tiny-slider-react';
import PropTypes from 'prop-types';
import content from 'content';
import styled from 'styled-components';
import moment from 'moment/min/moment-with-locales';

import { actionTypes } from 'redux/actions';
import { Config, Analytics, Utils } from 'scripts';
import { getDateFromString, dateToCustomString } from 'scripts/Dates';
import { CircularLoader } from 'components/circularLoader';
import { ArrowIcon } from 'icons';
import NoPrefAvatar from 'icons/noprefavatar.png';
import { Day } from './day';
import { Loader } from 'components/loader';
import {
    EMPLOYEE_INDEX,
    NOPREFERANCE_INDEX,
} from '../../scripts/Utils/constants';

import {
    getCalendarStyle,
    getCalendarWrapperStyle,
    getCalendarContainerStyle,
    getCalendarItemStyle,
    getControlBtnStyle,
    getEmployeeAvatarContainer,
    getEmployeeAvatar,
    getNoPrefImageContainer,
    getStyledCircularLoaderWrapper,
    getStyledNoPrefAvatar,
    getStyledOpacityDiv,
    getEmployeeUnavailableContainer,
    StyledH3,
    StyledH1,
    StyledEmployeeName,
    StyledTinySlider,
} from './style';

const StyledCalendar = styled.div(getCalendarStyle);
const CalendarWrapper = styled.div(getCalendarWrapperStyle);
const CalendarContainer = styled.div(getCalendarContainerStyle);
const CalendarItem = styled.div(getCalendarItemStyle);
const ControlBtn = styled.button(getControlBtnStyle);
const EmployeeAvatarContainer = styled.div(getEmployeeAvatarContainer);
const EmployeeUnavailableContainer = styled.div(
    getEmployeeUnavailableContainer
);
const EmployeeAvatar = styled.img(getEmployeeAvatar);
const NoPrefImageContainer = styled.div(getNoPrefImageContainer);
const StyledCircularLoaderWrapper = styled.div(getStyledCircularLoaderWrapper);
const StyledNoPrefAvatar = styled.img(getStyledNoPrefAvatar);
const StyledOpacityDiv = styled.div(getStyledOpacityDiv);

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            items: window.innerWidth >= Config.MOBILE ? 5 : 3,
            prevIndex: 0,
            lazyLoadCount: 0,
            noPrefLazyCount: 0,
            calendarLoading: true,
            initialNum: 1,
        };
    }

    // Settings for tiny slider
    settings = {
        controls: false,
        lazyload: true,
        nav: false,
        mouseDrag: true,
        slideBy: 1,
        mode: 'carousel',
        loop: false,
        items: 3,
        edgePadding: 50,
        startIndex: this.props.calendarStartIndex,
        gutter: 3,
        responsive: {
            [Config.IFRAMEWIDTH]: {
                items: 3,
                gutter: 3,
                edgePadding: 0,
                arrowKeys: true,
            },
            767: {
                items: 4,
                gutter: 3,
                edgePadding: 50,
                arrowKeys: true,
            },
            992: {
                items: 5,
                gutter: 10,
                edgePadding: 0,
                arrowKeys: true,
            },
        },
    };

    // Listeners for resize to change number of days displayed
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);

        this.setState({ calendarLoading: false });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    // Number of dates displayed
    updateDimensions = () => {
        this.setState({
            items: window.innerWidth >= Config.MOBILE ? 5 : 3,
        });
    };

    // Checks if given date has available timeslots
    isDisabled = (date) => {
        const slots = this.props.timeslots.filter((el) => {
            return el.date.slice(0, 10) === date;
        });

        return !(
            slots &&
            slots.length &&
            slots.find((el) => el.available === true)
        );
    };

    // Tiny slider function to navigate between slides
    onGoTo = (dir) => {
        this.ts.slider.goTo(dir);
    };

    // Count of available timeslots
    availableTimeslots = (date) => {
        const currentTimeslot = this.props.timeslots.filter(
            (el) => el.available && el.date.slice(0, 10) === date
        )[0];

        return (currentTimeslot && currentTimeslot.spaces) || 0;
    };

    // Change background for initials avatar
    formatAvatarInitials = (employeeAvatar) => {
        return employeeAvatar?.includes('eu.ui-avatars')
            ? employeeAvatar.replace('background=f4f4f4', 'background=ffffff')
            : employeeAvatar;
    };

    // Function for calculating index breakpoint for lazy loading
    lazyIndexBreakpoint = () =>
        this.state.lazyLoadCount * Config.LAZY_LOAD_DAYS_NUMBER +
        Config.SHOW_DAYS_NUMBER;

    //  Calculate breakpoints for no preference
    noPrefIndexBreakpoint = () =>
        this.state.noPrefLazyCount * Config.NO_PREF_LAZY_LOAD_NUMBER +
        Config.NO_PREF_LAZY_LOAD_NUMBER;

    // Formatting data for lazy loading
    formatLazyLoadData = () => {
        const { selectedSalesRep } = this.props;

        return {
            startDate: moment(new Date())
                .add(
                    !selectedSalesRep
                        ? this.noPrefIndexBreakpoint()
                        : this.lazyIndexBreakpoint(),
                    'days'
                )
                .format('YYYY-MM-DD'),
            endDate: moment(new Date())
                .add(
                    !selectedSalesRep
                        ? Config.NO_PREF_LAZY_LOAD_NUMBER +
                              this.noPrefIndexBreakpoint()
                        : Config.LAZY_LOAD_DAYS_NUMBER +
                              this.lazyIndexBreakpoint(),
                    'days'
                )
                .format('YYYY-MM-DD'),
            personId: selectedSalesRep,
            isLazyLoad: true,
        };
    };

    handleIndexChange = (index) => {
        let initialIndex;
        const { selectedSalesRep } = this.props;

        if (selectedSalesRep) {
            initialIndex = EMPLOYEE_INDEX;
        } else {
            initialIndex = NOPREFERANCE_INDEX;
        }

        let firstCondition = (initialIndex - 1) * this.state.initialNum;
        let secondCondition =
            initialIndex * (1 + this.state.initialNum) - this.state.initialNum;

        if (index > firstCondition && index < secondCondition) {
            let loadIndex =
                initialIndex * this.state.initialNum +
                1 -
                this.state.initialNum * 1;
            this.loadMore(loadIndex, index);
            this.setState({ initialNum: this.state.initialNum + 1 });
        }
    };

    // Lazy loading function
    lazyLoadMoreDays = (index) => {
        const {
            bookingToolNumberOfDays,
            getAvailableDates,
            getEmployeeAvailabilities,
            selectedSalesRep,
        } = this.props;
        const { items, prevIndex, lazyLoadCount, noPrefLazyCount } = this.state;

        // Data required for api calls
        let data = this.formatLazyLoadData();

        // Employee lazy loading
        if (
            selectedSalesRep &&
            index > prevIndex &&
            index + items < bookingToolNumberOfDays
        ) {
            getAvailableDates(data);
            getEmployeeAvailabilities(data);
            this.setState({
                lazyLoadCount: lazyLoadCount + 1,
            });
        }

        // No preference lazy loading
        if (
            !selectedSalesRep &&
            index > prevIndex &&
            index + items < bookingToolNumberOfDays
        ) {
            //getAvailableDates(data);
            //getEmployeeAvailabilities(data);
            this.setState({
                noPrefLazyCount: noPrefLazyCount + 1,
            });
        }

        this.setState({ prevIndex: index });
    };

    loadMore(triggerDay, currentIndex) {
        this.lazyLoadMoreDays(triggerDay);
        this.setState({ index: currentIndex });
    }

    render() {
        const {
            availableDates,
            error,
            onClick,
            selectedDate,
            setState,
            employeeAvatar,
            isLazyLoading,
            availableTimeSlots,
            employeeAlreadySelected,
            selectedEmployee,
            storeName,
            wechatID,
            language,
        } = this.props;
        const { index, items, calendarLoading } = this.state;
        let isAllUnavailable = availableTimeSlots.every(
            (data) => data.available === false
        );

        return (
            <>
                {calendarLoading && <Loader isBlack />}

                <StyledCalendar
                    availableDates={availableDates}
                    selectedDate={selectedDate}
                    unavailableTimeslots={
                        isAllUnavailable || availableTimeSlots.length === 0
                    }
                >
                    {!employeeAlreadySelected && (
                        <>
                            <StyledH1>{content.getAppointmentTitle}</StyledH1>
                            <StyledH3>{storeName}</StyledH3>
                        </>
                    )}

                    <CalendarWrapper>
                        {availableDates &&
                        availableDates.length &&
                        !isAllUnavailable &&
                        !availableTimeSlots.length == 0 ? (
                            <>
                                {/* Employee avatar */}
                                {employeeAvatar ? (
                                    <EmployeeAvatarContainer>
                                        <picture>
                                            <source
                                                media="(min-width: 1200px)"
                                                srcSet={this.formatAvatarInitials(
                                                    employeeAvatar
                                                )}
                                            />

                                            <EmployeeAvatar
                                                src={this.formatAvatarInitials(
                                                    employeeAvatar
                                                )}
                                            />
                                        </picture>
                                    </EmployeeAvatarContainer>
                                ) : (
                                    <NoPrefImageContainer>
                                        <StyledNoPrefAvatar
                                            src={NoPrefAvatar}
                                            alt=""
                                        />
                                    </NoPrefImageContainer>
                                )}
                                <StyledEmployeeName>
                                    {selectedEmployee !== 'undefined' &&
                                        selectedEmployee}
                                </StyledEmployeeName>

                                {/* Calendar */}

                                {!isAllUnavailable &&
                                    availableTimeSlots.length !== 0 && (
                                        <CalendarContainer>
                                            {isLazyLoading && (
                                                <>
                                                    <StyledOpacityDiv />
                                                    <StyledCircularLoaderWrapper>
                                                        <CircularLoader />
                                                    </StyledCircularLoaderWrapper>
                                                </>
                                            )}
                                            {/* Arrow buttons */}
                                            <ControlBtn
                                                isHidden={
                                                    index === 0 || isLazyLoading
                                                }
                                                onClick={() =>
                                                    this.onGoTo('prev')
                                                }
                                            >
                                                <ArrowIcon />
                                                <span className="sr-only">
                                                    {content.a11ySliderBtnPrev}
                                                </span>
                                            </ControlBtn>
                                            <ControlBtn
                                                btnNext
                                                isHidden={
                                                    index ===
                                                        availableDates.length -
                                                            items ||
                                                    isLazyLoading
                                                }
                                                onClick={() =>
                                                    this.onGoTo('next')
                                                }
                                            >
                                                <ArrowIcon />
                                                <span className="sr-only">
                                                    {content.a11ySliderBtnNext}
                                                </span>
                                            </ControlBtn>

                                            {/* eslint no-return-assign: "error" */}
                                            {/* Dates slider */}
                                            <StyledTinySlider>
                                                <TinySlider
                                                    onIndexChanged={(e) => {
                                                        this.setState({
                                                            index: e.index,
                                                        });
                                                        this.handleIndexChange(
                                                            e.index
                                                        );
                                                    }}
                                                    settings={this.settings}
                                                    ref={(ts) => (this.ts = ts)}
                                                >
                                                    {availableDates
                                                        .slice(0)
                                                        .map((date) => {
                                                            return (
                                                                <CalendarItem
                                                                    key={`date-${date}`}
                                                                >
                                                                    <Day
                                                                        language={
                                                                            language
                                                                        }
                                                                        wechatID={
                                                                            wechatID
                                                                        }
                                                                        date={
                                                                            date
                                                                        }
                                                                        newDate={Utils.formatDate(
                                                                            date
                                                                        )}
                                                                        availableTimeslots={() =>
                                                                            this.availableTimeslots(
                                                                                date
                                                                            )
                                                                        }
                                                                        isSelected={
                                                                            date ===
                                                                            selectedDate
                                                                        }
                                                                        isDisabled={this.isDisabled(
                                                                            date
                                                                        )}
                                                                        onClick={() => {
                                                                            setState(
                                                                                {
                                                                                    selectedTimeslot:
                                                                                        null,
                                                                                }
                                                                            );
                                                                            setState(
                                                                                {
                                                                                    selectedTimeslotUtc:
                                                                                        null,
                                                                                }
                                                                            );
                                                                            if (
                                                                                !this.isDisabled(
                                                                                    date
                                                                                )
                                                                            ) {
                                                                                onClick(
                                                                                    date,
                                                                                    this.isDisabled(
                                                                                        date
                                                                                    )
                                                                                );
                                                                            }

                                                                            // clear errors
                                                                            if (
                                                                                error
                                                                            ) {
                                                                                setState(
                                                                                    {
                                                                                        error: null,
                                                                                    }
                                                                                );
                                                                            }
                                                                            if (
                                                                                !this.isDisabled(
                                                                                    date
                                                                                )
                                                                            ) {
                                                                                Analytics(
                                                                                    {
                                                                                        type: 'Date selection',
                                                                                        label: dateToCustomString(
                                                                                            getDateFromString(
                                                                                                date,
                                                                                                'yyyy-mm-dd'
                                                                                            ),
                                                                                            'yyyymmdd'
                                                                                        ),
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                </CalendarItem>
                                                            );
                                                        })}
                                                </TinySlider>
                                            </StyledTinySlider>
                                        </CalendarContainer>
                                    )}
                            </>
                        ) : (
                            <EmployeeUnavailableContainer>
                                <EmployeeAvatarContainer>
                                    <picture>
                                        <source
                                            media="(min-width: 1200px)"
                                            srcSet={employeeAvatar}
                                        />
                                        <EmployeeAvatar src={employeeAvatar} />
                                    </picture>
                                </EmployeeAvatarContainer>
                            </EmployeeUnavailableContainer>
                        )}
                    </CalendarWrapper>
                </StyledCalendar>
            </>
        );
    }
}

const mapStateToProps = ({ reducer }) => {
    return {
        context: reducer.context,
        availableDates: reducer.availableDates,
        error: reducer.error,
        loadingDatesAndTimeslots: reducer.loadingDatesAndtimesSlots,
        selectedDate: reducer.selectedDate,
        selectedTimeslot: reducer.selectedTimeslot,
        timeslots: reducer.timeslots,
        employeeAvatar: reducer.employeeAvatar,
        selectedSalesRep: reducer.selectedSalesRep,
        bookingToolNumberOfDays: reducer.bookingToolNumberOfDays,
        isLazyLoading: reducer.isLazyLoading,
        calendarStartIndex: reducer.calendarStartIndex,
        reselectSalesRep: reducer.reselectSalesRep,
        availableTimeSlots: reducer.availableTimeSlots,
        storeRef: reducer.storeRef,
        employeeAlreadySelected: reducer.employeeAlreadySelected,
        selectedEmployee: reducer.selectedEmployee,
        storeName: reducer.storeName,
        isRescheduleScreen: reducer.isRescheduleScreen,
        wechatID: reducer.wechatID,
        language: reducer.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAvailableDates: (payload) =>
            dispatch({
                type: actionTypes.GET_AVAILABLE_DATES,
                payload,
            }),
        getEmployeeAvailabilities: (payload) =>
            dispatch({
                type: actionTypes.GET_EMPLOYEE_AVAILABILITIES,
                payload,
            }),
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};

Calendar.propTypes = {
    context: PropTypes.shape({
        mode: PropTypes.string,
    }).isRequired,
    availableDates: PropTypes.arrayOf(PropTypes.string).isRequired,
    error: PropTypes.string,
    loadingDatesAndTimeslots: PropTypes.bool,
    onClick: PropTypes.func,
    selectedDate: PropTypes.string,
    setState: PropTypes.func.isRequired,
    timeslots: PropTypes.arrayOf(PropTypes.object),
    isLazyLoading: PropTypes.bool,
    wechatID: PropTypes.string,
};

Calendar.defaultProps = {
    error: null,
    loadingDatesAndTimeslots: false,
    onClick: () => {},
    selectedDate: null,
    timeslots: [],
    wechatID: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
