import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    font-family: GT America;
    max-width: 500px;
    margin: auto;
`;
export const StyledEmployeeName = styled.p`
    font-size: 18px;
    margin-bottom: 10px;
    padding-bottom: 20px;
`;

export const StyledHeader = styled.div``;

export const StyledHeading = styled.div`
    font-size: 24px;
    color: #2d2e2c;
    margin-bottom: 40px;
    text-align: center;
`;

export const StyledEmployeeImage = styled.div`
    height: 340px;
    text-align: center;
`;

export const StyledText = styled.div`
    text-align: center;
    color: #757575;
`;

export const StyledHr = styled.hr`
    background-color: #efefef;
    height: 1px;
    border: none;
    margin: 30px 0;
`;

export const StyledTimeHeading = styled.p`
    font-size: 42px;
    font-weight: 300;
    color: #2d2e2c;
    margin: 0;
    margin-bottom: 10px;
`;

export const StyledDate = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: #2d2e2c;
    margin-bottom: 40px;
`;

export const StyledAddress = styled.div`
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: #757575;
    margin: 0;
    padding: 0;
`;

export const StyledAddressBold = styled.p`
    margin: 0;
    color: #2d2e2c;
    padding: 0;
`;

export const StyledRescheduleBtn = styled.button`
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding: 15px 40px;
    width: 48%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        border-color: #2d2e2c;
    }
`;

export const StyledCalendarBtn = styled.button`
    background-color: #2d2e2c;
    color: white;
    border-radius: 4px;
    width: 100%;
    padding: 15px 40px;
    cursor: pointer;
    font-size: 14px;
    margin: 15px 0;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-color: black;
    }
`;
export const StyledCancelBtn = styled.button`
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding: 15px 40px;
    width: 48%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        border-color: #2d2e2c;
    }
`;

export const StyledButtonGroup = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const StyledStoreImage = styled.div`
    margin-bottom: 30px;
    text-align: center;
`;

export const StyledStoreName = styled.p`
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin: 0;
    margin-bottom: 15px;
`;

export const StyledStoreAddress = styled.p`
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin: 0;
`;


export const StyledOpeningHoursBtn = styled.button`
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding: 15px 20px;
    width: 48%;
    margin: 20px auto;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        border-color: #2d2e2c;
    }
`;

export const StyledChineseText = styled.p`
    font-weight: 500;
`;
export const StyledChineseHeading = styled.h3`
    text-align: center;
    margin:0;
    font-weight: 500;
    margin-bottom:20px;
    font-family: GT America;
`;
export const StyledChineseButton = styled.button`
    background-color: #2d2e2c;
    color: white;
    border-radius: 4px;
    width: 50%;
    margin: 30px auto;
    padding: 15px 15px;
    cursor: pointer;
    font-size: 14px;

    transition: all 0.3s ease-in-out;
    &:hover {
        background-color: black;
    }
`;

export const StyledLogoContainer = styled.div`
    margin: 0 auto 50px auto;
`;

export const StyledPhone = styled.p`
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin: 0;
    margin-top:10px;
`

export const StyledPhoneLink = styled.a`
    color:black;
    display:flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    margin-top:10px;
    margin-bottom:50px;
`