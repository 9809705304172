import { mq } from 'styles/mediaqueries';

export const sharedBackButtonStyle = {
    border: 0,
    backgroundColor: 'transparent',
    opacity: 1,
    cursor: 'pointer',
    transition: 'all .3s ease-out',
    padding: '12px 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
    svg: {
        width: 18,
        height: 18,
    },

    '&:hover, &:focus': {
        opacity: 0.5,
    },
};

export const getBackButtonStyle = ({ inIframe }) => {
    return {
        ...sharedBackButtonStyle,
        [mq[1]]: {
            top: inIframe ? 5 : 0,
        },
        [mq[3]]: {
            padding: inIframe ? '12px 15px' : '19px 15px 18px',
        },
    };
};
