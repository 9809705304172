import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { Config } from 'scripts';
import { actionTypes } from './actions';

// Initial State
const initialState = {
    availableDates: [],
    availableTimeSlots: [],
    campaign: '',
    countryCode: '',
    context: {
        country: null,
        locale: 'en',
        mode: Config.MODES.BOOKING,
        showEmployees: false,
    },
    employees: [],
    employeesCached: [],
    error: null,
    fromMail: false,
    defaultCountry: '',
    loading: null,
    loadingDatesAndTimeslots: false,
    loadingTimeslots: false,
    loadingReserve: false,
    reservationData: null,
    selectedDate: null,
    preSelectedTimeslot: null,
    selectedSalesRep: null,
    selectedTimeslot: null,
    selectedTimeslotUtc: null,
    resourceId: '',
    serviceId: '',
    bookingData: {},
    storeName: '',
    rescheduled: false,
    description: null,
    storeId: '',
    storeRef: '',
    timeslots: [],
    selectedEmployee: '',
    waitwhileId: '',
    timeslotsCached: [],
    userData: {
        firstName: '',
        lastName: '',
        email: '',
        lastServedBy: '',
        privateNote: '',
        phone: '',
        phoneValid: false,
        subscription: true,
        hasNewsletter: true,
    },
    employeeDetails: {
        name: '',
        image: '',
    },
    storeData: {},
    skipServices: false,
    fromWeChat: false,
    sympaEmployeeId: '',
    storeLocator: false,
    servicePicker: false,
    duration: 1800,
    appointmentEndTime: '',
    renderSecondScreen: false,
    groupAppointment: false,
    employeeAvatar: '',
    eppName: '',
    employeeAlreadySelected: false,
    bookingToolNumberOfDays: 14,
    isLazyLoading: false,
    calendarStartIndex: null,
    errorScreen: false,
    reselectSalesRep: false,
    bookingId: null,
    appLoading: false,
    dailyLoading: false,
    wechatID: null,
    rescheduleLink: '',
    cancelLink: '',
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.CHANGE_CONTEXT: {
            const ctxKey =
                Object.keys(action.payload) && Object.keys(action.payload)[0];
            return {
                ...state,
                context: { ...state.context, [ctxKey]: action.payload[ctxKey] },
            };
        }
        case actionTypes.SAVE_USER_DATA: {
            const key =
                Object.keys(action.payload) && Object.keys(action.payload)[0];
            return {
                ...state,
                userData: { ...state.userData, [key]: action.payload[key] },
            };
        }
        case actionTypes.SAVE_EMPLOYEE_DETAILS: {
            const key =
                Object.keys(action.payload) && Object.keys(action.payload)[0];
            return {
                ...state,
                employeeDetails: {
                    ...state.employeeDetails,
                    [key]: action.payload[key],
                },
            };
        }
        case actionTypes.SET_STATE: {
            const key =
                Object.keys(action.payload) && Object.keys(action.payload)[0];
            return { ...state, [key]: action.payload[key] };
        }
        default:
            return state;
    }
};

const createRootReducer = (history) =>
    combineReducers({
        reducer,
        router: connectRouter(history),
    });
export default createRootReducer;
