import React from 'react';
import PropTypes from 'prop-types';
import { StoresWrapper } from '../style';
import StoreItem from './StoreItem';
import { Loader } from '../../../components/loader/index';

const StoresList = ({
    filteredCountry,
    isLoading,
    handleInputChange,
    selected,
    stripStoreName,
    formatSelected,
}) => {
    const getHours = (item) => {
        const getCurrentDay = new Date().getUTCDay();

        let dayOfTheWeek;

        switch (getCurrentDay) {
            case 0:
                dayOfTheWeek = 'sundayTbaC';
                break;

            case 1:
                dayOfTheWeek = 'mondayTbaC';
                break;
            case 2:
                dayOfTheWeek = 'tuesdayTbaC';
                break;
            case 3:
                dayOfTheWeek = 'wednesdayTbaC';
                break;
            case 4:
                dayOfTheWeek = 'thursdayTbaC';
                break;
            case 5:
                dayOfTheWeek = 'fridayTbaC';
                break;
            case 6:
                dayOfTheWeek = 'saturdayTbaC';
                break;
            default:
                break;
        }

        return item[dayOfTheWeek];
    };

    return (
        <StoresWrapper>
            {isLoading && <Loader />}
            {filteredCountry &&
                filteredCountry?.stores?.map((item) => {
                    return (
                        <StoreItem
                            key={item.id}
                            name={stripStoreName(
                                item.appointmentBookingStoreLink
                            )}
                            adress={item.address1C}
                            storeId={item.storeCodeC}
                            zipCode={item.zipC}
                            city={item.cityC}
                            hours={getHours(item)}
                            handleInputChange={handleInputChange}
                            selected={selected}
                            formatSelected={formatSelected}
                            filteredCountry={filteredCountry}
                        />
                    );
                })}
        </StoresWrapper>
    );
};

StoresList.propTypes = {
    filteredCountry: PropTypes.shape({
        id: PropTypes.number,
        appointmentBookingStoreLink: PropTypes.string,
        address1C: PropTypes.string,
        cityC: PropTypes.string,
        zipC: PropTypes.string,
        storeCodeC: PropTypes.string,
    }),
    isLoading: PropTypes.bool.isRequired,
    stripStoreName: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    formatSelected: PropTypes.func.isRequired,
    selected: PropTypes.string,
};

StoresList.defaultProps = {
    filteredCountry: {},
    selected: null,
};

export default StoresList;
