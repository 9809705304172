import React, { useEffect } from 'react';
import content from 'content';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

// Images
import NoPrefAvatar from 'icons/noprefavatar.png';

// Scripts
import { actionTypes } from 'redux/actions';
import { Analytics, Config, Utils } from 'scripts';
import { dateToCustomString, addDays } from 'scripts/Dates';

// Components
import SEO from 'components/seo';
import { Alert } from 'components/alert';
import { Button } from 'components/button';
import Calendar from 'components/calendar';
import Timeslots from 'components/timeslot';
import { Loader } from 'components/loader';
import Header from 'components/layout/header';
import { Wrapper } from 'components/layout/wrapper';
import BackButton from 'components/backButton';
import StickyWrapper from 'components/stickyWrapper';
import HeaderIframe from 'components/layout/headerIframe';
import { push } from 'connected-react-router';
import { Employee } from './components/employeesListItem';
// Styles
import {
    StyledButtonWrapper,
    getH1Style,
    getH1Wrapper,
    getStyledErrorScreen,
    StyledH3,
    getStyledNoPrefAvatar,
} from './style';

import {
    StyledEmployeesListWrapper,
    StyledEmployeesList,
    StyledNoEmployeesWrapper,
    StyledNoEmployeesBtnWrapper,
    StyledEmployeesUnvailableHeader,
} from './components/employeesList/employeesListStyle';

import configData from '../../config.json';
import {
    StyledNoPref,
    StyledNoPrefImage,
    StyledEmployeeContainer,
} from './components/employeesListItem/style';

const StyledH1Wrapper = styled.div(getH1Wrapper);
const StyledH1 = styled.h2(getH1Style);
const StyledErrorScreen = styled.div(getStyledErrorScreen);
const StyledNoPrefAvatar = styled.img(getStyledNoPrefAvatar);

const BookingScreenEmployee = (props) => {
    const {
        context,
        error,
        getAvailableDates,
        getEmployeeAvailabilities,
        loadingReserve,
        reschedule,
        reserveTimeslot,
        selectedDate,
        selectedSalesRep,
        selectedTimeslot,
        selectedTimeslotUtc,
        setState,
        storeId,
        employees,
        renderSecondScreen,
        stickyHeight,
        employeeAlreadySelected,
        errorScreen,
        loadingDatesAndTimeslots,
        loadingTimeslots,
        calendarStartIndex,
        appLoading,
        fromMail,
        campaign,
        serviceId,
        selectedEmployee,
        storeName,
        duration,
        servicePicker,
        reroute,
        sympaEmployeeId,
        skipServices,
        groupAppointment,
        storeRef,
        fromUserData,
        wechatID,
    } = props;

    const { mode } = context;
    const isBookingScreen = mode === Config.MODES.BOOKING;
    const isRescheduleScreen = mode === Config.MODES.RESCHEDULE;
    const urlParams = new Utils.Url(window.location.href).query;
    const startDate = dateToCustomString(new Date(), 'yyyy-mm-dd');
    const endDate = dateToCustomString(
        addDays(new Date(), Config.SHOW_DAYS_NUMBER),
        'yyyy-mm-dd'
    );

    const isFromSizePassport = () => {
        return !!(
            serviceId === configData.SERVICE_ID.sizePassport ||
            serviceId === configData.SERVICE_ID.sizePassportCM
        );
    };

    const buttonLabel = isFromSizePassport()
        ? 'Go back to design your own'
        : content.openingHours;

    let lastFittingEmployee;
    const resData = {
        date: selectedDate,
        time: selectedTimeslot,
        dateTimeUtc: selectedTimeslotUtc,
        storeId,
        personId: selectedSalesRep,
    };

    const reschData = {
        date: `${selectedDate}T${selectedTimeslot}`,
        storeId,
        duration,
    };

    const data = {
        startDate: null,
        endDate: null,
        personId: 0,
    };
    const BtnLabel = isBookingScreen
        ? content.btn.goToBooking
        : content.btn.confirmReschedule;

    const isGroupAppointment =
        serviceId === configData.SERVICE_ID.GROUP_SERVICE_ID ||
        serviceId === configData.SERVICE_ID.GROUP_SERVICE_ID_TEST ||
        urlParams?.serviceId === Config.CONSUMER_SERVICES.WEBSTORE_GROUP_TEST ||
        urlParams?.serviceId === Config.CONSUMER_SERVICES.WEBSTORE_GROUP_PRD;

    useEffect(() => {
        const { init } = props;
        setState({ appLoading: false });
        setState({ loadingReserve: false });
        const endingDate = addDays(new Date(), Config.SHOW_DAYS_NUMBER);
        const dates = {
            startDate: dateToCustomString(new Date(), 'yyyy-mm-dd'),
            endDate: dateToCustomString(endingDate, 'yyyy-mm-dd'),
        };

        if (
            (urlParams?.storeRef ||
                urlParams?.storeId ||
            urlParams?.locationId ||
                storeRef ||
                storeId) &&
            !fromUserData
        ) {
            init(dates);
        }

        const employeeId = urlParams && urlParams.employeeId;

        const isFromEpp = !!employeeId;

        Analytics({
            type: `Selected_Service`,
            label: `${
                !isGroupAppointment ? Config.SERVICE_NAME(serviceId) : 'Group'
            }`,
        });

        if (isFromEpp) {
            data.personId = employeeId;
            data.startDate = startDate;
            data.endDate = endDate;

            setState({ employeeAlreadySelected: true });
            setState({ selectedSalesRep: urlParams && urlParams.employeeId });
            setState({ renderSecondScreen: true });
            getAvailableDates(data);
        }
    }, []);

    const inIFrame = Utils.inIFrame();

    const isDisabled = (person) => {
        if (!person) {
            return selectedSalesRep || selectedSalesRep === 0
                ? selectedSalesRep !== 0
                : false;
        }
        return selectedSalesRep || selectedSalesRep === 0
            ? !(person.id === selectedSalesRep)
            : false;
    };

    const handleDateSelect = (date) => {
        setState({ selectedDate: date });
    };

    const selectSalesRep = (e, person) => {
        e.stopPropagation();
        if (person) {
            setState({ selectedEmployee: person.name });
            setState({ resourceId: person.id });
        }

        // Clear pre selected timeslot
        if (isBookingScreen) {
            setState({ selectedTimeslot: null });
            setState({ selectedTimeslotUtc: null });
        }

        // Data for api calls
        data.personId = person && person.id;
        data.startDate = startDate;
        data.endDate = endDate;

        // Set end date if no preference selected
        if (!data.personId) {
            data.endDate = dateToCustomString(
                addDays(new Date(), Config.NO_PREF_LAZY_LOAD_NUMBER),
                'yyyy-mm-dd'
            );
        }

        // Redux states
        setState({ selectedSalesRep: person ? person.id : null });
        setState({ employeeAvatar: person && person.image });
        setState({ sympaEmployeeId: person && person.employeeId });
        setState({
            selectedDate: startDate,
        });

        // Api calls
        getAvailableDates(data);
        getEmployeeAvailabilities(data);

        // Analytics
        Analytics({
            type: 'Style advisor selection',
            label: person
                ? person.name
                : `${selectedEmployee || 'No preference'}`,
        });

        // Second screen of new flow with calendar and timeslots
        setState({ renderSecondScreen: true });
    };

    const checkForLastFittingEmployee = (arr) => {
        lastFittingEmployee = employees.find(
            ({ lastFitting }) => lastFitting === true
        );
        if (lastFittingEmployee) {
            return arr.slice(1);
        }
        return arr;
    };

    checkForLastFittingEmployee(employees);

    const handleAboutMe = (person) => {
        // handle UTM tags
        let utm;
        if (fromMail) {
            if (campaign === 'AlterationReady') {
                utm = Config.UTM.emailAlteradionReady;
            } else if (campaign === 'TryAtStore') {
                utm = Config.UTM.emailTryAtStore;
            } else if (campaign === 'CustomMade') {
                utm = Config.UTM.emailCustomMade;
            }
        } else {
            utm = Config.UTM.iFrame;
        }
        if (wechatID) {
            return;
        }
        if (!person.image.includes('signature')) {
            window.open(
                `${Config.EPP_URL}${person.employeeId}/${person.name}${utm}&storeId=${storeId}`
            );
        }
    };

    const getAllAvailable = employees.filter((el) => el.availability === true);
    const getAllAvailableWithoutLastFitting = employees.filter(
        (el) => el.availability === true && el.lastFitting !== true
    );

    const getAllUnavailable = employees.filter(
        (el) => el.availability === false && el.image !== null
    );
    const isAllAvailable = employees.every((el) => el.availability === true);

    const availableEmployeeStyle = {
        
    };

    const unavailableEmployeeStyle = {
        height: `calc(100% - ${stickyHeight}px)`,
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
    };

    const sendEventMessage = () => {
        window.parent.postMessage('closeRadicalPersonalModal', '*');
    };
    return (
        <>
            {appLoading && <Loader isBlack />}
            {loadingDatesAndTimeslots && <Loader isBlack />}
            {loadingTimeslots && <Loader isBlack />}
            {!errorScreen && renderSecondScreen ? (
                <>
                    {/* Second screen */}
                    {!inIFrame && <Header />}

                    <HeaderIframe
                        title={content.getAppointmentTitle}
                        inIframe={inIFrame}
                    />

                    {mode !== 'reschedule' &&
                        (employeeAlreadySelected ? (
                            <BackButton
                                onClick={() => {
                                    reroute(Config.ROUTES.SERVICES);
                                    setState({ servicePicker: false });
                                    setState({ errorScreen: false });
                                    setState({ fromUserData: false });
                                }}
                            />
                        ) : (
                            <BackButton
                                onClick={() => {
                                    setState({ selectedEmployee: null });
                                    setState({ selectedSalesRep: null });
                                    setState({ resourceId: null });
                                    setState({ reselectSalesRep: true });
                                    setState({ renderSecondScreen: false });
                                    setState({ calendarStartIndex: null });
                                    setState({ errorScreen: false });
                                    setState({ fromUserData: false });
                                }}
                            />
                        ))}
                    {(serviceId === configData.SERVICE_ID.GROUP_SERVICE_ID ||
                        serviceId ===
                            configData.SERVICE_ID.GROUP_SERVICE_ID_TEST) &&
                        employeeAlreadySelected && (
                            <BackButton onClick={sendEventMessage} />
                        )}
                    {(!!calendarStartIndex || calendarStartIndex === 0) && (
                        <Calendar
                            onClick={(date, disabled) =>
                                handleDateSelect(date, disabled)
                            }
                        />
                    )}

                    {/* {!selectedSalesRep && (
                        <Calendar
                            onClick={(date, disabled) =>
                                handleDateSelect(date, disabled)
                            }
                        />
                    )} */}

                    <Timeslots />

                    {selectedTimeslot && (
                        <StyledButtonWrapper>
                            <Button
                                label={BtnLabel}
                                isLoading={loadingReserve}
                                onClick={() => {
                                    if (isRescheduleScreen) {
                                        reschedule(reschData);
                                        setState({ rescheduled: true });
                                        setState({ bookingData: resData });
                                        return;
                                    }

                                    setState({ bookingData: resData });
                                    reserveTimeslot(resData);
                                    Analytics({
                                        type: `${
                                            isGroupAppointment
                                                ? 'Group'
                                                : 'Individual'
                                        }_Book_Appointment`,
                                        label: sympaEmployeeId
                                            ? `${sympaEmployeeId}-${selectedEmployee}`
                                            : 'No preference',
                                    });
                                }}
                            />
                        </StyledButtonWrapper>
                    )}
                </>
            ) : (
                <>
                    {/* First screen */}
                    {isRescheduleScreen && (
                        <SEO
                            title={content.metaData.rescheduleAppointmentTitle}
                        />
                    )}

                    <StickyWrapper
                        refreshHeight={employees && employees.length > 0}
                    >
                        {!inIFrame && <Header />}
                        {inIFrame && (
                            <HeaderIframe
                                title={content.getAppointmentTitle}
                                inIframe={inIFrame}
                            />
                        )}
                        {employeeAlreadySelected && (
                            <BackButton onClick={() => sendEventMessage} />
                        )}
                        {groupAppointment && (
                            <BackButton
                                onClick={() => {
                                    reroute('/app-type');
                                    setState({ employees: [] });
                                    setState({ errorScreen: false });
                                    setState({ fromUserData: false });
                                }}
                            />
                        )}
                        {servicePicker && !groupAppointment && (
                            <BackButton
                                onClick={() => {
                                    reroute('/services');
                                    setState({ servicePicker: false });
                                    setState({ employees: [] });
                                    setState({ errorScreen: false });
                                    setState({ fromUserData: false });
                                }}
                            />
                        )}
                        {skipServices && (
                            <BackButton
                                onClick={() => {
                                    reroute('/');
                                    setState({ employees: [] });
                                    setState({ errorScreen: false });
                                    setState({ fromUserData: false });
                                }}
                            />
                        )}
                        {!errorScreen &&
                            getAllAvailable &&
                            getAllAvailable.length > 0 && (
                                <>
                                    <StyledH1Wrapper>
                                        <StyledH1 inIframe={Utils.inIFrame()}>
                                            {
                                                content.bookingEmployee
                                                    .advisorTitle
                                            }
                                        </StyledH1>

                                        <StyledH3>{storeName}</StyledH3>
                                    </StyledH1Wrapper>
                                </>
                            )}
                    </StickyWrapper>

                    {errorScreen ? (
                        <StyledErrorScreen>
                            <p>{content.errorBadApiCallHeader}</p>
                            <p>{content.errorbadApiCallBody}</p>
                            <Button
                                label={content.takeBack}
                                onClick={() => sendEventMessage}
                            />
                        </StyledErrorScreen>
                    ) : (
                        <main
                            style={
                                !getAllAvailable.length &&
                                !getAllUnavailable.length
                                    ? unavailableEmployeeStyle
                                    : availableEmployeeStyle
                            }
                        >
                            <Wrapper>
                                <StyledEmployeesListWrapper inIFrame={inIFrame}>
                                    {/* no preference item */}
                                    {lastFittingEmployee && (
                                        <StyledEmployeesList>
                                            <Employee
                                                key={lastFittingEmployee.id}
                                                person={lastFittingEmployee}
                                                onClick={(e) => {
                                                    selectSalesRep(
                                                        e,
                                                        lastFittingEmployee
                                                    );
                                                    setState({
                                                        employeeAlreadySelected: false,
                                                    });
                                                }}
                                                onAboutMe={() =>
                                                    handleAboutMe(
                                                        lastFittingEmployee
                                                    )
                                                }
                                                isDisabled={isDisabled(
                                                    lastFittingEmployee
                                                )}
                                                selectedSalesRep={
                                                    selectedSalesRep
                                                }
                                                lastFitting={
                                                    lastFittingEmployee.lastFitting
                                                }
                                                btnLabel={content.btn.select}
                                            />
                                        </StyledEmployeesList>
                                    )}

                                    {getAllAvailable.length > 0 ? (
                                        <>
                                            {/* No preference */}
                                            <StyledNoPref
                                                isDisabled={isDisabled()}
                                                // onClick={() => selectSalesRep()}
                                            >
                                                <StyledEmployeeContainer>
                                                    <StyledNoPrefImage>
                                                        <StyledNoPrefAvatar
                                                            src={NoPrefAvatar}
                                                            alt=""
                                                        />
                                                    </StyledNoPrefImage>
                                                    {
                                                        content.noEmployeePreference
                                                    }
                                                </StyledEmployeeContainer>

                                                <Button
                                                    type="button"
                                                    onClick={(e) => {
                                                        selectSalesRep(e);
                                                    }}
                                                    label={content.btn.select}
                                                    outlineStyle
                                                />
                                            </StyledNoPref>

                                            {/* all available employees */}
                                            <StyledEmployeesList>
                                                {getAllAvailableWithoutLastFitting.map(
                                                    (person) => {
                                                        return (
                                                            <Employee
                                                                key={
                                                                    person.employeeId
                                                                }
                                                                person={person}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    selectSalesRep(
                                                                        e,
                                                                        person
                                                                    );
                                                                    setState({
                                                                        employeeAlreadySelected: false,
                                                                    });
                                                                }}
                                                                onAboutMe={() =>
                                                                    handleAboutMe(
                                                                        person
                                                                    )
                                                                }
                                                                isDisabled={isDisabled(
                                                                    person
                                                                )}
                                                                selectedSalesRep={
                                                                    selectedSalesRep
                                                                }
                                                                lastFitting={
                                                                    person.lastFitting
                                                                }
                                                                btnLabel={
                                                                    content.btn
                                                                        .select
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </StyledEmployeesList>
                                            {!isAllAvailable && (
                                                <StyledEmployeesUnvailableHeader>
                                                    {
                                                        content.currenttlyUnavailable
                                                    }
                                                </StyledEmployeesUnvailableHeader>
                                            )}
                                        </>
                                    ) : (
                                        <StyledNoEmployeesWrapper>
                                            <StyledH1>
                                                {content.noSlotsAvailable.title}
                                            </StyledH1>
                                            <p>
                                                {
                                                    content.noSlotsAvailable
                                                        .descriptionLine1
                                                }
                                                <strong>
                                                    {
                                                        content.noSlotsAvailable
                                                            .descriptionLine2
                                                    }
                                                </strong>
                                            </p>
                                            <StyledNoEmployeesBtnWrapper>
                                                <Button
                                                    type="button"
                                                    onClick={sendEventMessage}
                                                    label={buttonLabel}
                                                    outlineStyle
                                                />
                                            </StyledNoEmployeesBtnWrapper>
                                        </StyledNoEmployeesWrapper>
                                    )}
                                    {/* all unavailable employees */}
                                    {getAllUnavailable && (
                                        <StyledEmployeesList>
                                            {getAllUnavailable.map((person) => {
                                                return (
                                                    <Employee
                                                        key={person.employeeId}
                                                        person={person}
                                                        onClick={(e) =>
                                                            selectSalesRep(
                                                                e,
                                                                person
                                                            )
                                                        }
                                                        onAboutMe={() =>
                                                            handleAboutMe(
                                                                person
                                                            )
                                                        }
                                                        isDisabled={isDisabled(
                                                            person
                                                        )}
                                                        selectedSalesRep={
                                                            selectedSalesRep
                                                        }
                                                        lastFitting={
                                                            person.lastFitting
                                                        }
                                                        btnLabel={
                                                            content.btn
                                                                .unavailable
                                                        }
                                                        btnDisabled
                                                        firstChildBorder={
                                                            getAllAvailable
                                                        }
                                                    />
                                                );
                                            })}
                                        </StyledEmployeesList>
                                    )}
                                </StyledEmployeesListWrapper>
                            </Wrapper>

                            <Wrapper>
                                <>{error && <Alert>{error}</Alert>}</>
                            </Wrapper>
                        </main>
                    )}
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        context: reducer.context,
        employees: reducer.employees,
        employeesCached: reducer.employeesCached,
        error: reducer.error,
        selectedTimeslot: reducer.selectedTimeslot,
        selectedTimeslotUtc: reducer.selectedTimeslotUtc,
        loadingReserve: reducer.loadingReserve,
        selectedDate: reducer.selectedDate,
        selectedSalesRep: reducer.selectedSalesRep,
        storeId: reducer.storeId,
        timeslotsCached: reducer.timeslotsCached,
        userData: reducer.userData,
        renderSecondScreen: reducer.renderSecondScreen,
        employeeAvatar: reducer.employeeAvatar,
        availableDates: reducer.availableDates,
        timeslots: reducer.timeslots,
        reducer,
        stickyHeight: reducer.stickyHeight,
        employeeAlreadySelected: reducer.employeeAlreadySelected,
        errorScreen: reducer.errorScreen,
        loadingDatesAndTimeslots: reducer.loadingDatesAndTimeslots,
        loadingTimeslots: reducer.loadingTimeslots,
        calendarStartIndex: reducer.calendarStartIndex,
        appLoading: reducer.appLoading,
        dailyLoading: reducer.dailyLoading,
        fromMail: reducer.fromMail,
        campaign: reducer.campaign,
        storeRef: reducer.storeRef,
        serviceId: reducer.serviceId,
        bookingData: reducer.bookingData,
        resourceId: reducer.resourceId,
        selectedEmployee: reducer.selectedEmployee,
        storeName: reducer.storeName,
        rescheduled: reducer.rescheduled,
        duration: reducer.duration,
        servicePicker: reducer.servicePicker,
        sympaEmployeeId: reducer.sympaEmployeeId,
        eppName: reducer.eppName,
        skipServices: reducer.skipServices,
        groupAppointment: reducer.groupAppointment,
        fromUserData: reducer.fromUserData,
        wechatID: reducer.wechatID,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAvailableDates: (payload) =>
            dispatch({
                type: actionTypes.GET_AVAILABLE_DATES,
                payload,
            }),
        getEmployeeAvailabilities: (payload) =>
            dispatch({
                type: actionTypes.GET_EMPLOYEE_AVAILABILITIES,
                payload,
            }),
        reserveTimeslot: (payload) =>
            dispatch({ type: actionTypes.RESERVE_TIMESLOT, payload }),
        reschedule: (payload) =>
            dispatch({ type: actionTypes.RESCHEDULE, payload }),
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
        reroute: (payload) => dispatch(push(payload)),
        init: (payload) => dispatch({ type: actionTypes.INIT, payload }),
    };
};

BookingScreenEmployee.propTypes = {
    context: PropTypes.shape({
        mode: PropTypes.string,
        locale: PropTypes.string,
    }).isRequired,
    employees: PropTypes.arrayOf(PropTypes.object).isRequired,
    error: PropTypes.string,
    getAvailableDates: PropTypes.func.isRequired,
    getEmployeeAvailabilities: PropTypes.func.isRequired,
    reschedule: PropTypes.func.isRequired,
    selectedDate: PropTypes.string,
    selectedSalesRep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedTimeslot: PropTypes.string,
    selectedTimeslotUtc: PropTypes.string,
    setState: PropTypes.func.isRequired,
    reserveTimeslot: PropTypes.func.isRequired,
    loadingReserve: PropTypes.bool.isRequired,
    storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userData: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        lastServedBy: PropTypes.string,
    }).isRequired,
    errorScreen: PropTypes.bool,
    init: PropTypes.func.isRequired,
    renderSecondScreen: PropTypes.bool.isRequired,
    stickyHeight: PropTypes.number,
    employeeAlreadySelected: PropTypes.bool.isRequired,
    loadingDatesAndTimeslots: PropTypes.bool.isRequired,
    loadingTimeslots: PropTypes.bool.isRequired,
    calendarStartIndex: PropTypes.number,
    appLoading: PropTypes.bool.isRequired,
    fromMail: PropTypes.bool.isRequired,
    campaign: PropTypes.string,
    serviceId: PropTypes.string.isRequired,
    selectedEmployee: PropTypes.string,
    storeName: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    servicePicker: PropTypes.bool.isRequired,
    reroute: PropTypes.func.isRequired,
    sympaEmployeeId: PropTypes.string,
    skipServices: PropTypes.bool.isRequired,
    groupAppointment: PropTypes.bool,
    storeRef: PropTypes.string,
    fromUserData: PropTypes.bool,
};

BookingScreenEmployee.defaultProps = {
    error: null,
    selectedDate: null,
    selectedSalesRep: null,
    selectedTimeslot: null,
    selectedTimeslotUtc: null,
    storeId: null,
    errorScreen: false,
    campaign: '',
    stickyHeight: null,
    calendarStartIndex: null,
    sympaEmployeeId: null,
    groupAppointment: null,
    storeRef: null,
    fromUserData: false,
    selectedEmployee: null,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingScreenEmployee);
