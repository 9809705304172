import content from 'content';
import moment from 'moment';
import { parseSimpleDate } from './date-format';

const getCalendarDay = (date) => {
    const d = parseSimpleDate(date);
    // should we improve this condition ?
    const days = `${d.getDate()}/${d.getMonth()}`;

    const today = `${new Date().getDate()}/${new Date().getMonth()}`;

    if (days === today) {
        return d && content.today;
    }
    return `${moment(d).format('ddd')}`;
};

export default getCalendarDay;
