import React from 'react';
import {StyledCircularLoader,StyledPulse} from'./style.js';

export const CircularLoader = () => {
    return (
        <StyledCircularLoader>
            <StyledPulse />
        </StyledCircularLoader>
    );
};

