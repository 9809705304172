import { mq } from 'styles/mediaqueries';

export const getAvatarStyle = () => {
    return {
        display: 'block',
        margin: '0 auto 20px',
        width: 100,
        height: 100,

        [mq[0]]: {
            marginBottom: 35,
        },

        [mq[2]]: {
            marginBottom: 28,
        },
    };
};
