export const brandColor = '#2D2E2C'; // used for bg
export const hoverColor = '#1a1a19';
export const borderColor = '#e8e8e7';
export const greymid = '#adadad'; // used in timeslot tab // to review
export const greyDisabled = '#cecece';
export const text = '#2D2E2C';
export const textAlt = '#898989'; // Used with altBg or disabledBg
export const textDisabled = '#C4C4C4';
export const textError = '#C4312D';
export const backgroundLight = '#FAFAFA'
export const background = '#EFEFEF'
export const grey = '#E8E7E5'; // used in Calendar, border
export const alto = '#D0D0D0'; // slider arrow, border
export const dustyGrey = '#939393';
export const secondaryVariation = '#C6C6C6'
export const altBg = '#F4F4F4';
export const disabledBg = '#eaeaea'; // 'rgba(45, 46, 44, 0.1)';
export const errorBg = 'rgba(194, 51, 51, 0.1)';
export const errorBorder = '#C23333';

export const gutter = 20;
export const gutterBig = 70;
