/**
 * Parse date with format yyyy-mm-dd in the current timezone
 * return javascript date object
 */
export function parseSimpleDate(date) {
    if (!date) {
        return new Date();
    }

    const dateSplit = date.split('-');
    return new Date(
        parseInt(dateSplit[0], 10),
        parseInt(dateSplit[1], 10) - 1,
        parseInt(dateSplit[2], 10)
    );
}
