import React from 'react';

export const ErrorIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <g fill="#c23333">
            <path d="M4 14.08l10-9.74 1.62 1.58-10 9.74z" />
            <path d="M6 4.34l10 9.74-1.62 1.58-10-9.74z" />
        </g>
    </svg>
);
