import { createGlobalStyle } from 'styled-components';
import { text } from './variables';
import { mq } from './mediaqueries';

// eslint-disable-next-line import/no-default-export
export default createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }
  html {
    font-size: 62.5%;
  }
  body {
    color: ${text};
    font-size: 1.4rem;
    line-height: 1.5em;
    font-family: GT America,-apple-system,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  main {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    
  }
  .sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
  button {
    font-family: GT America,-apple-system,Arial,sans-serif;
  }
  p {
    font-size: 1.4rem;
    line-height: 20px;
    font-weight: 300;
    text-align: center;

    ${[mq[0]]} {
      font-size: 1.5rem;
    }

    ${[mq[1]]} {
      line-height: 22px;
    }
  }
  h1 {
    font-size: 2rem;
    line-height: 20px;
    font-weight: normal;
    display: block;
    margin: 0 auto;
    text-align: center;

    ${[mq[0]]} {
      font-size: 2.4rem;
      line-height: 28px;
      font-weight: 300;
    }

    ${[mq[1]]} {
      max-width: 75%;
    }

    ${[mq[3]]} {
      font-size: 2.4rem;
      line-height: 28px;
    }

    + p {
      margin: 20px auto 60px;
      margin: 20px auto 30px;

      ${[mq[3]]} {
        margin-top: 16px;
      }
    }
  }
  h2 {
    font-weight: normal;
    font-size: 24px;
    line-height: 21px;
    text-align:center;
    ${[mq[0]]} {
      font-size: 2.4rem;
      line-height: 28px;
      font-weight: 300;
    }

    ${[mq[1]]} {
      max-width: 75%;
    }

    ${[mq[3]]} {
      font-size: 2.4rem;
      line-height: 28px;
    }

    + p {
      margin: 20px auto 60px;
      margin: 20px auto 30px;

      ${[mq[3]]} {
        margin-top: 16px;
      }
    }
  }
  #modal {
    overflow: hidden;
  }
`;
