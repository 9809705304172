export const actionTypes = {
    CHANGE_CONTEXT: 'CHANGE_CONTEXT',
    GET_EMPLOYEE_AVAILABILITIES: 'GET_EMPLOYEE_AVAILABILITIES',
    GET_EMPLOYEE_DETAILS: 'GET_EMPLOYEE_DETAILS',
    GET_EMPLOYEE_LIST: 'GET_EMPLOYEE_LIST',
    GET_COUNTRIES_WITH_STORES: 'GET_COUNTRIES_WITH_STORES',
    GET_AVAILABLE_DATES: 'GET_AVAILABLE_DATES',
    GO_BACK_BOOKING: 'GO_BACK_BOOKING',
    INIT: 'INIT',
    POST_USER_DATA: 'POST_USER_DATA',
    RESCHEDULE: 'RESCHEDULE',
    RESERVE_TIMESLOT: 'RESERVE_TIMESLOT',
    SAVE_USER_DATA: 'SAVE_USER_DATA',
    SET_STATE: 'SET_STATE',
    WE_CHAT_INFO: 'WE_CHAT_INFO',
    SAVE_EMPLOYEE_DETAILS: 'SAVE_EMPLOYEE_DETAILS',
    GET_USER_INFO:'GET_USER_INFO'
};

export const changeContext = (payload) => ({
    type: actionTypes.CHANGE_CONTEXT,
    payload,
});

export const getEmployeeAvailabilities = (payload) => ({
    type: actionTypes.GET_EMPLOYEE_AVAILABILITIES,
    payload,
});
export const getAvailableDates = (payload) => ({
    type: actionTypes.GET_AVAILABLE_DATES,
    payload,
});

export const getEmployeeDetails = (payload) => ({
    type: actionTypes.GET_EMPLOYEE_DETAILS,
    payload,
});

export const getEmployeeList = (payload) => ({
    type: actionTypes.GET_EMPLOYEE_LIST,
    payload,
});
export const getCountriesWithStores = (payload) => ({
    type: actionTypes.GET_COUNTRIES_WITH_STORES,
    payload,
});

export const goBackToBooking = (payload) => ({
    type: actionTypes.GO_BACK_BOOKING,
    payload,
});

export const init = (payload) => ({
    type: actionTypes.INIT,
    payload,
});

export const getWeChatInfo = (payload) => ({
    type: actionTypes.WE_CHAT_INFO,
    payload,
});

export const postUserData = (payload) => ({
    type: actionTypes.POST_USER_DATA,
    payload,
});

export const reschedule = (payload) => ({
    type: actionTypes.RESCHEDULE,
    payload,
});

export const reserveTimeslot = (payload) => ({
    type: actionTypes.RESERVE_TIMESLOT,
    payload,
});

export const saveUserData = (payload) => ({
    type: actionTypes.SAVE_USER_DATA,
    payload,
});

export const saveEmployeeDetails = (payload) => ({
    type: actionTypes.SAVE_EMPLOYEE_DETAILS,
    payload,
});

export const setState = (payload) => ({
    type: actionTypes.SET_STATE,
    payload,
});
