import content from 'content';
import React from 'react';
import { StoreHeader } from 'screens/storeLocator/style';
import { AppointmentTypeHeaderWrapper } from '../style';

const AppointmentTypeHeader = () => {
    return (
        <AppointmentTypeHeaderWrapper>
            <StoreHeader>{content.appointmentTypeHeader}</StoreHeader>
        </AppointmentTypeHeaderWrapper>
    );
};

export default AppointmentTypeHeader;
