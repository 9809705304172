import styled from 'styled-components';

export const ServicePickerHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #efefef;
    padding:50px;
    margin: 0 15px;


`;

export const ServiceItemWrapper = styled.div`
    display: flex;
    justify-content:space-between;
    padding: 15px;
    margin:0 15px;
    align-items: center;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
    animation-duration: 0.6s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
    &:last-child {
        border-bottom: none;
    }
    @keyframes animate-fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    &:hover {
        background-color: #efefef;
        transition: 0.7s ease-out;
    }
`;

export const StyledServiceItemName = styled.p`
    text-align: left;
    margin-left:20px;
    font-size: 18px;
    color: #2d2e2c;
`;

export const StyledIcon = styled.div`
    margin-right:20px;
    
`;