import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-brands-svg-icons';
import * as Icon from '@fortawesome/free-solid-svg-icons'
import { Logo } from 'components/layout/header/SuSuLogo';
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actionTypes } from 'redux/actions';

import moment from 'moment';
import { getDateFromString } from 'scripts/Dates';
import { Loader } from 'components/loader';
import { Config } from 'scripts';
import {
    StyledContainer,
    StyledHeader,
    StyledHeading,
    StyledEmployeeImage,
    StyledText,
    StyledEmployeeName,
    StyledHr,
    StyledTimeHeading,
    StyledDate,
    StyledAddress,
    StyledAddressBold,
    StyledCalendarBtn,
    StyledButtonGroup,
    StyledRescheduleBtn,
    StyledCancelBtn,
    StyledStoreImage,
    StyledStoreName,
    StyledStoreAddress,
    StyledOpeningHoursBtn,
    StyledChineseHeading,
    StyledLogoContainer,
    StyledPhone,
    StyledPhoneLink
} from './style';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const WeChatConfirmation = ({
    selectedTimeslot,
    selectedDate,
    weChatInfo,
    storeData,
    employeeDetails,
    setState,
    appLoading,
    appointmentEndTime,
    cancelLink,
    rescheduleLink,
}) => {
    useEffect(() => {
        setState({ appLoading: true });
        weChatInfo();
    }, [appointmentEndTime]);

    const formatDate = (date) => {
        const fullDate = getDateFromString(date, 'yyyy-mm-dd');
        if (!(fullDate instanceof Date)) {
            return null;
        }

        const Y = fullDate.getFullYear();
        const d = fullDate.getDate();
        const m = fullDate.getMonth();

        return `${Y}年 ${m + 1}月 ${d}日`;
    };

    return (
        <>
            {appLoading ? (
                <Loader />
            ) : (
                <StyledContainer>
                    <StyledLogoContainer>
                        <Logo />
                    </StyledLogoContainer>
                    <StyledHeader>
                        <StyledHeading>预约已成功</StyledHeading>
                        <StyledEmployeeImage>
                            <picture>
                                <source
                                    media="(min-width: 1200px)"
                                    srcSet={employeeDetails?.image}
                                />
                                <img
                                    src={employeeDetails?.image}
                                    alt=""
                                    height={250}
                                />
                            </picture>
                            <StyledEmployeeName>
                                {employeeDetails?.name}
                            </StyledEmployeeName>
                        </StyledEmployeeImage>
                        <StyledText>
                            以下是您此次预约的详细信息。期待很快与您见面！
                        </StyledText>
                        <StyledHr />
                    </StyledHeader>
                    <StyledTimeHeading>{selectedTimeslot}</StyledTimeHeading>
                    <StyledDate>{formatDate(selectedDate)} </StyledDate>
                    <StyledAddress>
                        <StyledAddressBold>
                            Suitsupply {storeData.name}
                        </StyledAddressBold>
                        {storeData.address1C} <br />
                        {storeData.cityC}, {storeData.stateC} {storeData.zipC}{' '}
                        <br />
                        {storeData.countryC}
                    </StyledAddress>
                    <StyledCalendarBtn
                        onClick={() =>
                            atcb_action({
                                name: `SuitSupply appointment - ${employeeDetails.name}`,
                                label: 'Add to calendar',
                                startDate: `${selectedDate}`,
                                endDate: `${selectedDate}`,
                                startTime: `${selectedTimeslot}`,
                                endTime: `${appointmentEndTime}`,
                                options: [
                                    'Apple',
                                    'Google',
                                    'iCal',
                                    'Microsoft365',
                                    'Outlook.com',
                                    'Yahoo',
                                ],
                                iCalFileName: 'Reminder-Event',
                                timeZone: `${timezone}`,
                            })
                        }
                    >
                        加入日历
                    </StyledCalendarBtn>
                    <StyledButtonGroup>
                        <StyledRescheduleBtn
                            onClick={() =>
                                window.open(rescheduleLink, '_blank')
                            }
                            target="_blank"
                        >
                            改期
                        </StyledRescheduleBtn>
                        <StyledCancelBtn
                            onClick={() => window.open(cancelLink)}
                        >
                            取消
                        </StyledCancelBtn>
                    </StyledButtonGroup>

                    <StyledHr />

                    <StyledStoreImage>
                        <picture>
                            <source
                                media="(min-width: 1200px)"
                                srcSet={storeData.headerImageEmailMobileC}
                            />
                            <img
                                src={storeData.headerImageEmailMobileC}
                                alt=""
                                width="100%"
                            />
                        </picture>
                    </StyledStoreImage>
                    <FontAwesomeIcon icon={Icons.faWeixin} size="2x" />
                    <StyledChineseHeading>SuitSupply</StyledChineseHeading>
                    <StyledStoreName>{storeData.name}</StyledStoreName>
                    <StyledStoreAddress>
                        {storeData.address1C} <br />
                        {storeData.zipC} {storeData.name}
                    </StyledStoreAddress>
                    <StyledOpeningHoursBtn
                        onClick={() => window.open(Config.BEIJING_LINK)}
                    >
                        营业时间
                    </StyledOpeningHoursBtn>
                    <StyledHr />
                    <StyledChineseHeading>其它服务</StyledChineseHeading>
                    <StyledPhoneLink href="tel:40082 05572">
                    <FontAwesomeIcon icon={Icon.faPhoneAlt} size="2x" />
                    <StyledPhone>40082 05572</StyledPhone>
                    </StyledPhoneLink>
                </StyledContainer>
            )}
        </>
    );
};
const mapStateToProps = ({ reducer }) => {
    return {
        storeId: reducer.storeId,
        selectedEmployee: reducer.selectedEmployee,
        selectedTimeslot: reducer.selectedTimeslot,
        selectedDate: reducer.selectedDate,
        employeeAvatar: reducer.employeeAvatar,
        storeData: reducer.storeData,
        employeeDetails: reducer.employeeDetails,
        appLoading: reducer.appLoading,
        appointmentEndTime: reducer.appointmentEndTime,
        cancelLink: reducer.cancelLink,
        rescheduleLink: reducer.rescheduleLink,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        weChatInfo: (payload) =>
            dispatch({ type: actionTypes.WE_CHAT_INFO, payload }),
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};
WeChatConfirmation.propTypes = {
    selectedTimeslot: PropTypes.string,
    selectedDate: PropTypes.string,
    weChatInfo: PropTypes.func.isRequired,
    storeData: PropTypes.shape({
        name: PropTypes.string,
        cityC: PropTypes.string,
        address1C: PropTypes.string,
        countryC: PropTypes.string,
        stateC: PropTypes.string,
        zipC: PropTypes.string,
        headerImageEmailMobileC: PropTypes.string,
    }).isRequired,
    employeeDetails: PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
    }).isRequired,
    appLoading: PropTypes.bool.isRequired,
    setState: PropTypes.func.isRequired,
    appointmentEndTime: PropTypes.string,
    rescheduleLink: PropTypes.string,
    cancelLink: PropTypes.string,
};
WeChatConfirmation.defaultProps = {
    selectedDate: null,
    selectedTimeslot: null,
    appointmentEndTime: null,
    rescheduleLink: null,
    cancelLink: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(WeChatConfirmation);
