import { errorBg, errorBorder } from 'styles/variables';
import { mq } from 'styles/mediaqueries';

export const getAlertStyle = () => {
    return {
        backgroundColor: errorBg,
        border: `1px solid ${errorBorder}`,
        borderRadius: 5,
        padding: 15,
        textAlign: 'center',

        [mq[1]]: {
            padding: '15px 45px',
        },
    };
};
