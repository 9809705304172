import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en: {
        a11yHeaderBtnBack: 'Back to the previous step',
        a11yHeaderBtnClose: 'Close',
        a11yHeaderSuSuLogo: 'Suit Supply website',
        a11ySliderBtnPrev: 'See previous date',
        a11ySliderBtnNext: 'See next date',
        a11yTimeslotModalBtnClose: 'Close',
        aboutMe: 'About me',
        bookingCore: {
            defaultTitle: 'Choose date & time',
        },
        appointmentTypeHeader: 'Appointment type',
        individualAppointment: 'Individual Appointment',
        individualDescription1: 'One-on-one styling advice,',
        individualDescription2: 'visit on your own or with friends & family',
        groupAppointment: 'Group Appointment',
        groupDescription1: 'The perfect option for small groups,',
        groupDescription2: 'wedding parties and corporate events.',
        selectService: 'Select service',
        suits: 'Suits',
        customMade: 'Custom Made',
        casual: 'Casual',
        pickUp: 'Pick-up',
        consultation: 'Consultation',
        notSureYet: "I'm not sure yet",
        signUp: 'Sign up to get the latest news on all things Suitsupply',
        groupSize: 'Group size',
        takeBack:'Take me back',
        bookingEmployee: {
            defaultTitle: 'Choose your date \n& style advisor',
            advisorTitle: 'Choose your style advisor',
            dateTitle: 'Choose your date',
            timeslotModalTitle: 'Choose your time',
        },
        people:'people',
        btn: {
            confirm: 'Confirm',
            confirmReschedule: 'Reschedule',
            explore: 'Go to webstore',
            goToBooking: 'Book an appointment',
            rechedule: 'Reschedule',
            schedule: 'Schedule',
            select: 'Book',
            selected: 'Selected',
            unavailable: 'Unavailable',
            done: 'Done',
        },
        confirmAppointmentTitle: 'Complete',
        confirmationHeader: 'Your appointment is booked',
        confirmationText: `See you on {date} at {time}. We've emailed you a confirmation and appointment details.`,
        confirmationTextSMS: `See you on {date} at {time}. We’ve sent you a confirmation message with a link to appointment details.`,
        deleteAppointmentHeader: 'Your appointment has been cancelled',
        deleteAppointmentText:
            'We cancelled your appointment. If this was a mistake, reschedule right away.',
        email: 'Email',
        errorPageContent: 'This page does not exist.',
        errorPageHeader: 'Something went wrong...',
        errors: {
            apiError: 'Api error.',
            timeSlotNotAvailable:
                'The timeslot you picked is not available anymore. Please try another timeslot.',
            addToBasketError: 'Add to basket error.',
            bookingError: 'Booking error.',
            generic: 'Something went wrong. Oops.',
            bookingNotFound: 'The booking could not be found.',
        },
        errorTimeslotNotAvailable:
            'The timeslot your picked is not available anymore. Please try again.',
        errorRescheduleDisabled:
            'Sorry, there are no bookings available for selected style advisor. Please contact the store.',
        errorBadApiCallHeader:
            'An unexpected behavior occurred in our application.',
        errorbadApiCallBody:
            ' Our engineers are already working on it, so it will be fixed soon...',
        firstName: 'First name',
        phone: 'Phone',
        preferTextTime: 'Prefer a different time ',
        preferTextDate: 'or date?',
        phoneExample: '+',
        formErrors: {
            email: 'Invalid email address',
            firstName: 'First name should be at least 2 characters long',
            lastName: 'Last name should be at least 2 characters long',
            required: 'Required',
            phone: 'Invalid phone number',
        },
        getAppointmentTitle: 'Schedule appointment',
        lastName: 'Last name',
        lastFitting: 'Last fitting',
        metaData: {
            defaultTitle: 'Appointment',
            defaultDescription: 'Schedule appointment at Suitsupply',
            cancelAppointmentTitle: 'Cancel your appointement',
            rescheduleAppointmentTitle: 'Reschedule your appointement',
            confirmAppointmentTitle: 'Your appointement is confirmed',
            pageNotFoundTitle: 'This page was not found',
        },
        noAvailableDatesText: 'No dates available yet..',
        noAvailableTimeslotsText: 'No timeslots available yet..',
        noSlotsAvailable: {
            title: 'We’re sorry',
            descriptionLine1: 'Currently no appointments are available, ',
            descriptionLine2: 'but you are always welcome to walk-in anytime.',
        },
        currenttlyUnavailable: 'Currently unavailable',
        openingHours: 'See opening hours',
        noEmployeePreference: 'No preference',
        note: {
            title: 'Help us prepare',
            label: 'Add note',
        },
        noTimeslostAvailable: {
            header: 'I’m sorry',
            content:
                'My schedule for the next two weeks is fully booked. Feel free to walk in to the store anytime and we will do our best to assist you.',
        },
        rescheduleTitle: 'Select a date and time',
        selectStoreTitle: 'Select a store',
        slot: 'slot',
        slots: 'slots',
        styleAdvisorsBtn: 'See all style advisors',
        anotherStyleAdvisorBtn: 'Choose another style advisor',
        today: 'Today',
        timezone: 'All times are {timezone}',
        userDataFormTitle: 'Schedule appointment',
    },
    ch: {
        a11yHeaderBtnBack: 'Back to the previous step',
        a11yHeaderBtnClose: '关闭',
        a11yHeaderSuSuLogo: 'Suit Supply website',
        a11ySliderBtnPrev: 'See previous date',
        a11ySliderBtnNext: 'See next date',
        a11yTimeslotModalBtnClose: '关闭',
        aboutMe: 'About me',
        bookingCore: {
            defaultTitle: 'Choose date & time',
        },
        appointmentTypeHeader: '预约类型',
        individualAppointment: '个人预约',
        individualDescription1: '一对一造型建议，',
        individualDescription2: '婚礼派对和公司活动。',
        groupAppointment: '团体预约',
        groupDescription1: '小型团体预约, ',
        groupDescription2: '婚礼派对和公司活动。',
        selectService: '选择服务',
        suits: '商务',
        customMade: '定制',
        casual: '休闲',
        pickUp: '取货',
        consultation: '咨询',
        notSureYet: '其它',
        signUp: '登录获取Suitsupply的最新资讯',
        groupSize: '团体人数',
        takeBack:'返回',
        bookingEmployee: {
            defaultTitle: 'Choose your date \n& style advisor',
            advisorTitle: '选择您的造型顾问',
            dateTitle: 'Choose your date',
            timeslotModalTitle: 'Choose your time',
        },
        people:'人',
        btn: {
            confirm: '确认',
            confirmReschedule: '重新预约',
            explore: 'Go to webstore',
            goToBooking: '即刻预约',
            rechedule: '再次预约',
            schedule: 'Schedule',
            select: '预约',
            selected: 'Selected',
            unavailable: '预约满',
            done: '完成',
        },
        confirmAppointmentTitle: 'Complete',
        confirmationHeader: '您已预约成功',
        confirmationText: `See you on {date} at {time}. We've emailed you a confirmation and appointment details.`,
        confirmationTextSMS: `预约信息将以手机短信形式告知您，期待与您相见 {date} {time}`,
        deleteAppointmentHeader: '您的预约已被取消',
        deleteAppointmentText: '您的预约已被取消，如需再次预约请点击下方按钮',
        email: '邮箱',
        errorPageContent: 'This page does not exist.',
        errorPageHeader: 'Something went wrong...',
        errors: {
            apiError: 'Api error.',
            timeSlotNotAvailable:
                'The timeslot you picked is not available anymore. Please try another timeslot.',
            addToBasketError: 'Add to basket error.',
            bookingError: 'Booking error.',
            generic: 'Something went wrong. Oops.',
            bookingNotFound: 'The booking could not be found.',
        },
        errorTimeslotNotAvailable:
            'The timeslot your picked is not available anymore. Please try again.',
        errorRescheduleDisabled:
            'Sorry, there are no bookings available for selected style advisor. Please contact the store.',
        errorBadApiCallHeader:
            '应用程序中出现了错误',
        errorbadApiCallBody:
            '我们正在努力修复中，请耐心等待。',
        firstName: '名',
        phone: 'Phone',
        preferTextTime: '如无适合时间段',
        preferTextDate: 'or date?',
        phoneExample: '+',
        formErrors: {
            email: '无效邮件地址',
            firstName: 'First name should be at least 2 characters long',
            lastName: 'Last name should be at least 2 characters long',
            required: '必填',
            phone: 'Invalid phone number',
        },
        getAppointmentTitle: '预约时间',
        lastName: '姓',
        lastFitting: 'Last fitting',
        metaData: {
            defaultTitle: 'Appointment',
            defaultDescription: 'Schedule appointment at Suitsupply',
            cancelAppointmentTitle: 'Cancel your appointement',
            rescheduleAppointmentTitle: 'Reschedule your appointement',
            confirmAppointmentTitle: 'Your appointement is confirmed',
            pageNotFoundTitle: 'This page was not found',
        },
        noAvailableDatesText: 'No dates available yet..',
        noAvailableTimeslotsText: 'No timeslots available yet..',
        noSlotsAvailable: {
            title: ' 很抱歉',
            descriptionLine1: '目前预约已满，您可直接前往 ',
            descriptionLine2: '门店或者拨打门店电话进行咨询。',
        },
        currenttlyUnavailable: '预约满',
        openingHours: '营业时间',
        noEmployeePreference: '无需指定人选 ',
        note: {
            title: '备注需求',
            label: '添加',
        },
        noTimeslostAvailable: {
            header: '很抱歉',
            content:
                '我二周内的预约已满， 您可直接到店选购。',
        },
        rescheduleTitle: 'Select a date and time',
        selectStoreTitle: 'Select a store',
        slot: 'slot',
        slots: 'slots',
        styleAdvisorsBtn: '查看所有造型顾问',
        anotherStyleAdvisorBtn: '选择其他销售',
        today: '今日',
        timezone: 'All times are {timezone}',
        userDataFormTitle: '顾客信息',
    },
});
