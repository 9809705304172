import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {
    DropDownContainer,
    DropDownHeader,
    DropDownListContainer,
    ListItem,
    DropDownList,
    StoreHeader,
    StoreHeaderWrapper,
    DropDownIcon

} from './../style';
import content from 'content';
const StoreLocatorHeader = ({
    countries,
    handleCountryChange,
    selectedCountry,
    isOpen,
    toggling
}) => {
    return (
        <StoreHeaderWrapper>
        <DropDownContainer>
        <StoreHeader>{content.selectStoreTitle}</StoreHeader>
        <DropDownHeader onClick={toggling}>{selectedCountry}<DropDownIcon><FontAwesomeIcon  icon={isOpen ? Icons.faAngleUp : Icons.faAngleDown} pull="right"/></DropDownIcon></DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
            {countries &&
                        countries?.map((country) => (
                            <ListItem
                                value={country.country}
                                key={country.country}
                                onClick={() => handleCountryChange(country.country)}
                            >
                                {country.country}
                            </ListItem>
                        ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
      </StoreHeaderWrapper>
    );
};

export default StoreLocatorHeader;
