/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import moment from 'moment/min/moment-with-locales';
import content from 'content';
import { push } from 'connected-react-router';
import { Config, Analytics, Utils } from 'scripts';
import { dateToCustomString, addDays } from 'scripts/Dates';
import configData from '../config.json';
import {
    cancelReservation,
    getDates,
    getEmployeeDetails,
    getEmployeeList,
    postUserData,
    retrieveBookingAndAvailabilities,
    retrieveCustomerData,
    getStoreConfig,
    getStoreConfigWithStoreId,
    getStoreConfigWithWaitwhileId,
    getBookingData,
    reschedule,
} from './endpoints';

import {
    actionTypes,
    changeContext,
    saveEmployeeDetails,
    saveUserData,
    setState,
} from './actions';

import {
    getServiceId,
    getLastFittingId,
    getBookingToolAvailableDates,
    getStoreId,
    getAvailableTimeslots,
    getTimeslots,
    getStoreRef,
    getSelectedTimeslot,
    getContext,
    getSelectedSalesRep,
    getNumberOfDays,
    getRescheduled,
    getDuration,
    getWaitwhileId,
    getEmployeeAlreadySelected,
    getErrorScreen,
    getResourceId,
    getLanguage,
    getSympaEmployeeId,
} from './selectors';

const urlParams = new Utils.Url(window.location.href).query;

function* initEmployeeScreen({ storeId, employeeId, serviceId }) {
    let lastFittingId = yield select(getLastFittingId);
    const language = yield select(getLanguage);
    // fetch params from url
    if (language && language === 'zh-cn') {
        content.setLanguage('ch');
    }

    if (urlParams?.language === 'zh-cn') {
        content.setLanguage('ch');
    }

    if (urlParams?.lastfittingEmployee) {
        lastFittingId = urlParams?.lastfittingEmployee;
    }

    if (serviceId) {
        yield put(setState({ serviceId }));
    }
    if (urlParams?.servicePicker) {
        yield put(setState({ servicePicker: true }));
        yield put(setState({ serviceId: urlParams?.serviceId }));
    }

    if (urlParams?.wechat) {
        yield put(setState({ fromWeChat: true }));
    }

    const waitwhileId = yield select(getWaitwhileId);

    const fromCancel = urlParams?.fromCancel;

    if (fromCancel) {
        yield put(setState({ serviceId: urlParams.serviceId }));
    }

    const employeeList = yield call(getEmployeeList, {
        storeId,
        data: {
            serviceId,
            waitwhileId,
        },
    });

    if (employeeList && employeeList.length > 0) {
        yield put(setState({ selectedDate: null }));

        const employeesFromApi =
            employeeList &&
            employeeList.map((el) => {
                return {
                    id: el.id,
                    name: el.name,
                    image: el.imageUrl ? el.imageUrl : el.imageAlternativeUrl,
                    slug: el.slug,
                    employeeId: el.employeeId,
                    lastFitting: false,
                    availability: el.availability,
                    order: el.order,
                };
            });

        // Shuffle employees
        const employees = Utils.shuffleEmployees(
            employeesFromApi,
            lastFittingId
        );

        // set avatar when coming from reschedule
        const rescheduleSelectedSalesRep = employees.find((employee) => {
            return employee.id === employeeId;
        });

        // set avatar when coming from employee profile page

        if (urlParams?.sfscEmployeeId) {
            const eppAvatar = employees.find((employee) => {
                return employee.employeeId === urlParams?.sfscEmployeeId;
            });
            yield put(
                setState({ employeeAvatar: eppAvatar && eppAvatar.image })
            );
            yield put(
                setState({ selectedEmployee: eppAvatar && eppAvatar.name })
            );
            yield put(
                setState({ sympaEmployeeId: eppAvatar && eppAvatar.employeeId })
            );
        }

        const employeeAlreadySelected = yield select(
            getEmployeeAlreadySelected
        );

        const resourceId = yield select(getResourceId);

        yield all([
            !employeeAlreadySelected &&
                put(changeContext({ showEmployees: true })),
            put(
                setState({
                    employees,
                })
            ),
            put(
                setState({
                    employeesCached: employees,
                })
            ),
            !resourceId &&
                put(
                    setState({
                        resourceId: employees.find(
                            (e) =>
                                e.employeeId &&
                                e.employeeId === urlParams?.sfscEmployeeId
                        )?.id,
                    })
                ),
        ]);
        // Set employee avatar for reschedule mode
        if (urlParams?.booking === Config.MODES.RESCHEDULE) {
            yield put(
                setState({
                    employeeAvatar: rescheduleSelectedSalesRep?.image,
                })
            );
        }

        // Google Analytics
        window.dataLayer.push({
            styleAdvisorSelection: 'true',
        });
    } else {
        // Google Analytics
        window.dataLayer.push({
            styleAdvisorSelection: 'false',
        });
        yield put(setState({ employees: [] }));
        yield put(setState({ errorScreen: true }));
    }
}

function* fetchData() {
    let apiData;
    let availableDates = [];
    let selectedDate;
    let storeId = yield select(getStoreId);
    let storeRef = yield select(getStoreRef);

    yield put(setState({ storeName: storeRef }));

    yield put(setState({ loadingDatesAndTimeslots: true }));
    let locale;
    let country;
    if (urlParams?.language) {
        const defaultCountry = urlParams && urlParams.language;

        yield put(setState({ language: urlParams.language }));
        yield put(setState({ defaultCountry }));

        // set locale for content translation
        locale = urlParams?.language.substring(0, 2);
        country = urlParams?.language.substring(3, 5);
    }

    if (
        urlParams?.serviceId &&
        urlParams?.serviceId !==
            Config.CONSUMER_SERVICES.WEBSTORE_INDIVIDUAL_PRD &&
        urlParams?.serviceId !==
            Config.CONSUMER_SERVICES.WEBSTORE_INDIVIDUAL_TEST &&
        urlParams?.serviceId !== Config.CONSUMER_SERVICES.SIZE_PASSPORT
    ) {
        yield put(setState({ serviceId: urlParams?.serviceId }));
    }

    if (urlParams?.wechatID) {
        yield put(setState({ wechatID: urlParams.wechatID }));
    }

    const selectedEmployeeId = urlParams?.employeeId;

    if (selectedEmployeeId) {
        yield put(setState({ employeeAlreadySelected: true }));
    }

    if (locale && locale !== 'en') {
        content.setLanguage(locale);
        moment.locale(locale);
        yield put(changeContext({ locale }));
    }

    const language = yield select(getLanguage);
    if (language === 'zh-cn') {
        content.setLanguage('ch');
    }

    if (country) {
        yield put(changeContext({ country }));
    }

    if (urlParams?.storeId || urlParams?.locationId) {
        storeId = urlParams?.storeId || urlParams?.locationId;
    }
    const formatStoreRef = (ref) => {
        return ref.split(' ').join('');
    };
    if (urlParams?.storeRef) {
        yield put(setState({ storeName: urlParams.storeRef }));
        storeRef = formatStoreRef(urlParams?.storeRef);
    }
    if (urlParams?.booking && urlParams?.uid) {
        if (urlParams?.booking === Config.MODES.RESCHEDULE) {
            // RESCHEDULE MODE
            // set context mode (booking, reschedule or cancel)
            yield put(changeContext({ mode: urlParams.booking }));
            if (urlParams?.customerId && urlParams?.signature) {
                // CREATION MODE (fills in user details)
                const userData = yield call(retrieveCustomerData, {
                    customerId: urlParams.customerId,
                    signature: urlParams.signature,
                });

                yield all([
                    put(
                        saveUserData({
                            customerId: userData && userData.customerId,
                        })
                    ),
                    put(saveUserData({ email: userData && userData.email })),
                    put(
                        saveUserData({
                            firstName: userData && userData.firstName,
                        })
                    ),
                    put(
                        saveUserData({
                            lastName: userData && userData.lastName,
                        })
                    ),
                    put(
                        saveUserData({
                            lastServedBy: userData && userData.lastServedBy,
                        })
                    ),
                    put(saveUserData({ phone: userData && userData.phone })),
                    put(
                        saveUserData({
                            hasNewsletter: userData && userData.hasNewsletter,
                        })
                    ),
                ]);
            }

            const bookingData = yield call(getBookingData, {
                bookingId: urlParams?.uid,
            });

            yield put(setState({ duration: bookingData?.duration }));

            if (bookingData === 'error') {
                window.location.href = configData.REDIRECT_URL;
            }

            const storeConfig = yield call(getStoreConfigWithStoreId, {
                storeId,
            });

            yield put(
                setState({
                    storeName: storeConfig.name,
                })
            );
            yield put(
                setState({
                    resourceId: bookingData?.resourceIds[0],
                })
            );
            yield put(
                setState({
                    waitwhileId: bookingData?.locationId,
                })
            );
            if (storeConfig) {
                yield put(changeContext({ showEmployees: true }));
            }

            const numberOfDays = yield select(getNumberOfDays);

            if (storeConfig) {
                yield put(
                    setState({
                        bookingToolNumberOfDays: numberOfDays,
                    })
                );
            }
            const numberOfAvailableDays = yield select(
                getBookingToolAvailableDates
            );

            const rescheduleDates = {
                startDate: moment(new Date()).format('YYYY-MM-DD'),
                endDate: moment(new Date())
                    .add(numberOfAvailableDays, 'days')
                    .format('YYYY-MM-DD'),
            };

            apiData = yield call(retrieveBookingAndAvailabilities, {
                dates: rescheduleDates,
                bookingId: urlParams?.uid,
                storeId,
                waitwhileResourceId: bookingData?.resourceIds[0],
                serviceId: bookingData?.serviceIds[0],
                sfscEmployeeId: urlParams?.sfscEmployeeId,
            });

            if (apiData?.days.every((day) => day.spaces < 1)) {
                yield put(setState({ errorScreen: true }));
            }

            if (apiData && apiData.days) {
                const payload = {
                    personId: bookingData?.resourceIds[0],
                    startDate: moment(new Date()).format('YYYY-MM-DD'),
                    endDate: moment(new Date())
                        .add(numberOfAvailableDays, 'days')
                        .format('YYYY-MM-DD'),
                    isLazyLoad: false,
                };

                availableDates =
                    apiData &&
                    apiData.days.map((el) => el.date.substring(0, 10));

                let selection = apiData.availability.find(
                    (el) => el.booked === true
                );

                const preselectedDate = apiData.availability.find((el) => {
                    return el.start === bookingData.date;
                });

                if (!preselectedDate && !selection) {
                    apiData.availability = [
                        ...apiData.availability,
                        bookingData.date,
                    ];
                    selection = bookingData.date;
                    yield put(
                        setState({ additionalTimeslot: bookingData.date })
                    );
                }

                yield put(
                    setState({ availableTimeSlots: apiData.availability })
                );
                if (!selection) {
                    yield put(
                        setState({
                            error: `${content.errors.bookingNotFound}`,
                        })
                    );
                }

                yield put(setState({ lastAction: 'time' }));
                selectedDate =
                    preselectedDate && preselectedDate.start.substring(0, 10);

                yield put(
                    setState({
                        selectedDate,
                    })
                );
                // select day time and slot:
                const hour =
                    selection &&
                    selection.start &&
                    selection.start.slice(11, 16).replace(':', '');

                // mark preselected timeslot
                yield put(
                    setState({
                        preSelectedTimeslot:
                            preselectedDate &&
                            preselectedDate.start.substring(11, 16),
                    })
                );

                if (bookingData?.resourceIds) {
                    yield put(
                        setState({
                            selectedSalesRep: bookingData?.resourceIds[0],
                        })
                    );
                }

                yield call(getEmployeeAvailabilities, { payload, apiData });
                // Init Employee Screen

                if (storeRef || storeId) {
                    yield call(initEmployeeScreen, {
                        apiData,
                        storeId,
                        employeeId: bookingData?.resourceIds[0],
                        serviceId: bookingData?.serviceIds[0],
                    });
                }
                const rescheduled = yield select(getRescheduled);
                if (rescheduled) {
                    put(push(Config.ROUTES.CONFIRM));
                }

                // Google Analytics
                Analytics({
                    type: 'Reschedule',
                    label: `${storeRef}|${hour}`,
                });
            }
        } else if (urlParams?.booking === Config.MODES.CANCEL) {
            // CANCEL MODE
            yield all([
                put(push(Config.ROUTES.CANCEL)),
                put(changeContext({ mode: urlParams.booking })),
            ]);
            const lang = yield select(getLanguage);
            content.setLanguage(lang || 'en');
            yield put(setState({ appLoading: true }));
            apiData = yield call(cancelReservation, {
                bookingId: urlParams.uid,
                storeId: urlParams.storeId,
            });
            if (apiData !== 'error') {
                yield put(setState({ appLoading: false }));
            } else {
                window.location.href = configData.REDIRECT_URL;
            }

            const bookingData = yield call(getBookingData, {
                bookingId: urlParams?.uid,
            });

            yield put(setState({ serviceId: bookingData.serviceIds[0] }));

            if (apiData && apiData.dateTime) {
                // Google Analytics
                Analytics({
                    type: 'Cancel',
                    label: `${storeRef}|${apiData.dateTime
                        .substring(11, 16)
                        .replace(':', '')}`,
                });
            }

            yield all([
                put(
                    setState({
                        selectedDate:
                            apiData &&
                            apiData.dateTime &&
                            apiData.dateTime.slice(0, 10),
                    })
                ),
                put(saveUserData({ email: apiData && apiData.clientEmail })),
                put(saveUserData({ firstName: apiData && apiData.firstName })),
                put(saveUserData({ lastName: apiData && apiData.lastName })),
                put(
                    saveUserData({
                        lastServedBy: apiData && apiData.lastServedBy,
                    })
                ),
                put(
                    setState({
                        selectedTimeslot:
                            apiData &&
                            apiData.dateTime &&
                            apiData.dateTime.slice(11, 16),
                    })
                ),
                put(setState({ storeId: apiData && urlParams?.storeId })),
            ]);

            if (apiData === 'error') {
                yield put(
                    setState({
                        error: `${content.errors.apiError}`,
                    })
                );
            } else {
                yield put(push(Config.ROUTES.CANCEL));
            }
            return;
        }
    } else {
        if (urlParams?.customerId && urlParams?.signature) {
            // CREATION MODE (fills in user details)
            const userData = yield call(retrieveCustomerData, {
                customerId: urlParams.customerId,
                signature: urlParams.signature,
            });
            yield all([
                put(
                    saveUserData({
                        customerId: userData && userData.customerId,
                    })
                ),
                put(saveUserData({ email: userData && userData.email })),
                put(
                    saveUserData({ firstName: userData && userData.firstName })
                ),
                put(saveUserData({ lastName: userData && userData.lastName })),
                put(
                    saveUserData({
                        lastServedBy: userData && userData.lastServedBy,
                    })
                ),
                put(saveUserData({ phone: userData && userData.phone })),
                put(
                    saveUserData({
                        hasNewsletter: userData && userData.hasNewsletter,
                    })
                ),
            ]);
        }
        // BOOKING MODE

        yield put(setState({ storeRef }));

        // Configuration per store: try to populate data with storeRef and fallback to storeId
        let storeConfigData;
        if (urlParams?.storeId) {
            storeConfigData = yield call(getStoreConfigWithStoreId, {
                storeId: urlParams.storeId,
            });
            yield put(setState({ storeName: storeConfigData?.name }));
        } else {
            storeConfigData = yield call(getStoreConfig, { storeId: storeRef });
        }
        if (!storeConfigData) {
            yield put(push('error'));
        }
        if (storeConfigData) {
            yield put(
                setState({
                    waitwhileId: storeConfigData.waitwhileLocationIdC,
                })
            );
        }

        if (storeConfigData && storeConfigData.showEmployees) {
            yield put(
                changeContext({ showEmployees: storeConfigData.showEmployees })
            );
        }

        yield put(
            setState({
                bookingToolNumberOfDays:
                    storeConfigData?.bookingToolNumberOfDays,
            })
        );
        yield put(
            setState({
                countryCode: storeConfigData?.countryCodeC,
            })
        );

        if (urlParams?.utm_medium === 'email') {
            yield put(
                setState({
                    fromMail: true,
                })
            );
            if (urlParams?.utm_campaign === 'AlterationReady') {
                yield put(
                    setState({
                        campaign: 'AlterationReady',
                    })
                );
            } else if (urlParams?.utm_campaign === 'TryAtStore') {
                yield put(
                    setState({
                        campaign: 'TryAtStore',
                    })
                );
            } else if (urlParams?.utm_campaign === 'CustomMade') {
                yield put(
                    setState({
                        campaign: 'CustomMade',
                    })
                );
            }
        }

        // redirect if no store is found
        if (storeConfigData?.storeCodeC === 0) {
            if (window.parent === window) {
                window.location.href = 'https://suitsupply.com';
            } else {
                window.parent.postMessage('redirectRadicalPersonalModal', '*');
                window.top.postMessage('redirectRadicalPersonalModal', '*');
            }
        }

        // if locale hasn't been set, set it from apiData
        if (!locale) {
            moment.locale(storeConfigData?.locale);
        }

        // set available dates
        for (let i = 0; i <= 14; i += 1) {
            availableDates.push(
                moment(new Date()).add(i, 'days').format('YYYY-MM-DD')
            );
        }

        yield put(
            setState({
                availableDates: [...new Set(availableDates)],
            })
        );
        let serviceId;

        if (
            urlParams?.serviceId === Config.CONSUMER_SERVICES.WEBSTORE_GROUP_PRD
        ) {
            serviceId = configData.SERVICE_ID.GROUP_SERVICE_ID;
        } else if (
            urlParams?.serviceId ===
            Config.CONSUMER_SERVICES.WEBSTORE_GROUP_TEST
        ) {
            serviceId = configData.SERVICE_ID.GROUP_SERVICE_ID_TEST;
        } else if (urlParams?.servicePicker) {
            serviceId = urlParams.serviceId;
        } else {
            serviceId = yield select(getServiceId);
        }

        yield put(setState({ storeId: storeConfigData?.storeCodeC }));

        // Init Employee Screen

        yield call(initEmployeeScreen, {
            apiData,
            storeId: storeConfigData?.storeCodeC,
            serviceId,
        });

        // Employee Screen has no pre-selected date:
        const context = yield select(getContext);
        if (context.showEmployees) {
            selectedDate = null;
        }
        const errorScreen = yield select(getErrorScreen);

        if (!context.showEmployees && !errorScreen) {
            const payload = {
                personId: null,
                startDate: moment(new Date()).format('YYYY-MM-DD'),
                endDate: moment(new Date()).add(5, 'days').format('YYYY-MM-DD'),
                isLazyLoad: false,
            };
            yield put(setState({ selectedSalesRep: null }));
            yield put(setState({ renderSecondScreen: true }));
            const employeeAlreadySelected = yield select(
                getEmployeeAlreadySelected
            );
            if (employeeAlreadySelected && !urlParams?.serviceId) {
                yield call(getEmployeeAvailabilities, { payload });
            }
        }

        if (selectedEmployeeId) {
            const endDate = addDays(new Date(), Config.SHOW_DAYS_NUMBER);
            const payload = {
                personId: selectedEmployeeId,
                startDate: dateToCustomString(new Date(), 'yyyy-mm-dd'),
                endDate: dateToCustomString(endDate, 'yyyy-mm-dd'),
            };
            yield put(setState({ storeId: storeConfigData?.storeCodeC }));
            // Flow from EPP for group bookings
            if (
                serviceId === configData.SERVICE_ID.GROUP_SERVICE_ID ||
                serviceId === configData.SERVICE_ID.GROUP_SERVICE_ID_TEST
            ) {
                yield put(setState({ employeeAlreadySelected: true }));
                yield put({ type: 'GET_EMPLOYEE_AVAILABILITIES', payload });
            }
        }
    }

    try {
        yield all([
            put(
                setState({
                    availableDates: [...new Set(availableDates)],
                })
            ),
            put(setState({ storeId: apiData.storeCodeC })),
            put(setState({ selectedDate })),
            put(
                setState({
                    timeslots: apiData && apiData.days,
                })
            ),
            put(
                setState({
                    timeslotsCached: apiData && apiData.days,
                })
            ),
            put(setState({ loadingDatesAndTimeslots: false })),
        ]);
    } catch (e) {
        yield all([
            // put(
            //     setState({
            //         error: `${content.errors.apiError} while loading available dates`,
            //     })
            // ),
            put(setState({ loadingDatesAndTimeslots: false })),
        ]);
    }
}

function* makeReservation() {
    yield put(setState({ loadingReserve: true }));
    yield put(push(Config.ROUTES.USER_DATA));
    yield put(setState({ loadingReserve: false }));
}

function* deleteTimeslotReservation() {
    yield all([
        put(setState({ loadingReserve: false })),
        put(push(Config.ROUTES.BOOKING)),
        put(setState({ bookingData: null })),
        put(setState({ preSelectedTimeslot: null })),
        put(setState({ selectedTimeslot: null })),
        put(setState({ selectedTimeslotUtc: null })),
    ]);
}

function* registerUserData(action) {
    const storeRef = yield select(getStoreRef);
    const selectedTimeslot = yield select(getSelectedTimeslot);

    Analytics({
        type: 'FormSubmission',
        label: `${storeRef}|${
            selectedTimeslot && selectedTimeslot.replace(':', '')
        }`,
    });
    yield all([
        put(setState({ loadingReserve: true })),
        put(setState({ error: null })),
    ]);

    const apiData = yield call(postUserData, action.payload);

    if (apiData === 'error') {
        yield put(
            setState({
                error: `${content.errors.apiError}`,
            })
        );
    } else {
        yield put(push(Config.ROUTES.CONFIRM));
        yield put(setState({ loadingDatesAndTimeslots: false }));
    }
}

function* changeReservation(action) {
    const duration = yield select(getDuration);
    const serviceId = yield select(getServiceId);

    yield put(setState({ loadingReserve: true }));
    try {
        yield call(reschedule, {
            bookingId: urlParams && urlParams.uid,
            duration,
            storeId: action.payload.storeId,
            serviceId: serviceId && Number.parseInt(serviceId, 10),
            data: {
                date: action.payload.date,
                time: action.payload.time,
                dateTimeUtc: action.payload.dateTimeUtc,
                personId: action.payload.personId,
            },
        });

        yield put(push(Config.ROUTES.CONFIRM));
    } catch (e) {
        yield all([
            put(
                setState({
                    error: `${content.errors.apiError} while making timeslot reservation`,
                })
            ),
            put(setState({ loadingReserve: false })),
        ]);
    }
}

// returns apiData per employee for spaces available, dates and availability
function* getEmployeeAvailabilities(action) {
    let storeId = yield select(getStoreId);

    const serviceId = yield select(getServiceId);

    let selectedSalesRep = yield select(getSelectedSalesRep);

    const resourceId = yield select(getSelectedSalesRep);

    if (urlParams?.resourceId) {
        if (!selectedSalesRep) {
            yield put(setState({ selectedSalesRep: urlParams?.resourceId }));
            selectedSalesRep = urlParams?.resourceId;
        }
        if (!resourceId) {
            yield put(setState({ resourceId: urlParams?.resourceId }));
        }

        yield put(setState({ employeeAlreadySelected: true }));
    }

    const selectedDateState = yield select(
        (state) => state?.reducer?.selectedDate
    );

    const reselectSalesRep = yield select(
        (state) => state && state.reducer && state.reducer.reselectSalesRep
    );

    const contextState = yield select(getContext);

    let calendarStartIndex;
    let selectedDate;
    const { isLazyLoad, ...reqData } = action.payload;
    const reducerTimeslots = yield select(getTimeslots);

    const calendarItems = window.innerWidth >= Config.MOBILE ? 5 : 3;

    yield put(setState({ loading: 'overlay' }));

    if (isLazyLoad) {
        yield put(setState({ isLazyLoading: true }));
    } else {
        yield put(setState({ loadingTimeslots: true }));
    }
    if (!isLazyLoad) {
        yield put(setState({ dailyLoading: true }));
    }

    if (urlParams?.locationId) {
        storeId = urlParams?.locationId;
    }
    if (urlParams?.storeId) {
        storeId = urlParams?.storeId;
    }
    let apiData;
    const employeeId = yield select(getSympaEmployeeId);

    if (action.apiData) {
        apiData = action.apiData;
    } else {
        apiData = yield call(getDates, {
            storeId,
            data: Object.assign(reqData, {
                serviceId,
            }),
            selectedSalesRep,
            serviceId,
            sfscEmployeeId: employeeId || urlParams?.sfscEmployeeId,
        });
    }
    yield put(setState({ availableTimeSlots: apiData.availability }));

    const availableTimeSlotsState = yield select(getAvailableTimeslots);

    const reducerState = yield select((state) => state && state.reducer);

    const getAdditionalTimeslot = yield select(
        (state) => state && state.reducer && state.reducer.additionalTimeslot
    );

    reqData.endDate = moment(reqData.endDate)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');

    if (isLazyLoad) {
        yield put(setState({ isLazyLoading: true }));
    } else {
        yield put(setState({ loadingTimeslots: true }));
    }

    if (storeId && serviceId && action) {
        if (!isLazyLoad) {
            yield put(setState({ appLoading: true }));
        }

        if (!isLazyLoad) {
            yield put(setState({ appLoading: false }));
        }
        let additionalAvailability = [
            ...apiData.availability,
            getAdditionalTimeslot,
        ];

        additionalAvailability = additionalAvailability.sort((a, b) =>
            a.start.localeCompare(b.start)
        );
        if (isLazyLoad) {
            if (reducerState.context.mode !== 'reschedule') {
                yield all([
                    put(
                        setState({
                            availableTimeSlots: [
                                ...availableTimeSlotsState,
                                ...apiData.availability,
                            ],
                        })
                    ),
                    put(setState({ isLazyLoading: false })),
                ]);
            } else if (reselectSalesRep) {
                yield all([
                    put(
                        setState({
                            availableTimeSlots: [
                                ...availableTimeSlotsState,
                                ...apiData.availability,
                            ],
                        })
                    ),
                    put(setState({ isLazyLoading: false })),
                ]);
            }
        } else if (getAdditionalTimeslot) {
            yield all([
                put(
                    setState({
                        availableTimeSlots: additionalAvailability,
                    })
                ),
                put(setState({ loadingTimeslots: false })),
            ]);
        } else {
            yield all([
                put(
                    setState({
                        availableTimeSlots: apiData.availability,
                    })
                ),
                put(setState({ loadingTimeslots: false })),
            ]);
        }
    }

    if (!apiData) {
        yield put(setState({ loadingTimeslots: false }));
        return;
    }

    let firstAvailableDay = apiData.days.find(
        (date) => date.available === true
    );

    if (
        urlParams?.booking === Config.MODES.RESCHEDULE &&
        firstAvailableDay?.date &&
        !isLazyLoad
    ) {
        firstAvailableDay = apiData.days.find(
            (date) => date.date.substring(0, 10) === selectedDateState
        );

        if (reselectSalesRep) {
            firstAvailableDay = apiData.days.find(
                (date) => date.available === true
            );
        }
        calendarStartIndex = apiData.days.indexOf(firstAvailableDay);

        selectedDate = firstAvailableDay?.date.substring(0, 10);
    } else if (firstAvailableDay && firstAvailableDay.date) {
        selectedDate = firstAvailableDay.date.substring(0, 10);

        calendarStartIndex = apiData.days.indexOf(firstAvailableDay);
    } else {
        calendarStartIndex = 0;
    }

    if (calendarStartIndex === 11 || calendarStartIndex === 12)
        calendarStartIndex -= calendarItems - 2;
    if (calendarStartIndex === 13) calendarStartIndex -= 2;

    if (!isLazyLoad) {
        yield put(setState({ dailyLoading: false }));
    }

    if (
        !firstAvailableDay &&
        !reselectSalesRep &&
        contextState.mode === 'reschedule'
    ) {
        yield put(setState({ renderSecondScreen: false }));
        yield put(setState({ selectedSalesRep: null }));
        yield put(setState({ loadingTimeslots: false }));
        yield put(setState({ reselectSalesRep: true }));
    }

    try {
        yield put(setState({ timeslots: apiData?.days }));
        yield put(setState({ calendarStartIndex }));

        if (urlParams?.booking === Config.MODES.RESCHEDULE) {
            yield put(setState({ loadingTimeslots: false }));
        }
        if (isLazyLoad) {
            yield all([
                put(
                    setState({
                        timeslots: [...reducerTimeslots, ...apiData.days],
                    })
                ),
            ]);
            if (selectedSalesRep) {
                yield put(setState({ isLazyLoading: false }));
            }
        } else {
            yield all([
                put(setState({ selectedDate })),
                put(setState({ timeslots: apiData?.days })),
                put(setState({ loadingTimeslots: false })),
            ]);
        }
    } catch (e) {
        yield all([
            put(
                setState({
                    error: `${content.errors.apiError} while posting user data`,
                })
            ),
            put(setState({ loadingTimeslots: false })),
        ]);
    }
    yield put(setState({ loading: null }));
}

function* getEmployeeData(action) {
    const selectedSalesRep = yield select(getSelectedSalesRep);
    const storeId = yield select(getStoreId);
    yield call(getEmployeeDetails, {
        employeeId: action.payload,
        storeId,
        selectedSalesRep,
    });
}

function* updateEmployeeList(action) {
    yield put(setState({ loading: 'overlay' }));
    const storeId = yield select(getStoreId);
    const serviceId = yield select(getServiceId);

    let lastFittingId = yield select(getLastFittingId);

    if (urlParams?.lastfittingEmployee) {
        lastFittingId = urlParams?.lastfittingEmployee;
    }
    const employeeList = yield call(getEmployeeList, {
        storeId,
        data: Object.assign(action.payload, {
            serviceId,
            lastFittingId,
        }),
    });
    const employees = [];

    if (employeeList) {
        employeeList.forEach((el) => {
            if (el.availability) {
                employees.push({
                    id: el.id,
                    name: el.name,
                    image: el.imageUrl,
                    slug: el.slug,
                    lastFitting: el.lastFitting,
                });
            }
        });
    }

    yield all([
        put(
            setState({
                employees,
            })
        ),
        put(setState({ loading: null })),
    ]);
}

function* getUserInfo() {
    const userData = yield call(retrieveCustomerData, {
        customerId: urlParams.customerId,
        signature: urlParams.signature,
    });

    yield all([
        put(
            saveUserData({
                customerId: userData && userData.customerId,
            })
        ),
        put(saveUserData({ email: userData && userData.email })),
        put(
            saveUserData({
                firstName: userData && userData.firstName,
            })
        ),
        put(
            saveUserData({
                lastName: userData && userData.lastName,
            })
        ),
        put(
            saveUserData({
                lastServedBy: userData && userData.lastServedBy,
            })
        ),
        put(saveUserData({ phone: userData && userData.phone })),
        put(
            saveUserData({
                hasNewsletter: userData && userData.hasNewsletter,
            })
        ),
    ]);
}

function* weChatInformation() {
    const bookingData = yield call(getBookingData, {
        bookingId: urlParams?.uid,
    });
    const storeData = yield call(getStoreConfigWithWaitwhileId, {
        storeId: bookingData?.locationId,
    });
    const selectedDate = bookingData?.date.split('T')[0];

    const converter = new Date(bookingData?.date);
    const options = { hour: 'numeric', minute: 'numeric' };
    const timeSlot = converter.toLocaleTimeString('en-GB', options);

    const waitwhileResourceId = bookingData?.resourceIds[0];

    const employeeDetails = yield call(getEmployeeDetails, {
        waitwhileResourceId,
    });

    // Format cancel and reschedule links
    const formatCancelLink =
        window.location.hostname === 'suitsupply.com' ||
        window.location.hostname === 'appointment.suitsupply.com'
            ? `${Config.CANCEL_LINKS.PRODUCTION}&uid=${urlParams?.uid}&storeId=${storeData?.storeCodeC}&wechatID=1&language=zh-cn`
            : `${Config.CANCEL_LINKS.TEST}&uid=${urlParams?.uid}&storeId=${storeData?.storeCodeC}&wechatID=1&language=zh-cn`;

    const formatRescheduleLink =
        window.location.hostname === 'suitsupply.com' ||
        window.location.hostname === 'appointment.suitsupply.com'
            ? `${Config.RESCHEDULE_LINKS.PRODUCTION}&uid=${urlParams?.uid}&storeId=${storeData?.storeCodeC}&wechatID=1&language=zh-cn`
            : `${Config.RESCHEDULE_LINKS.TEST}&uid=${urlParams?.uid}&storeId=${storeData?.storeCodeC}&wechatID=1&language=zh-cn`;

    // Convert duration to hours
    const durationInHours = bookingData?.duration / 60 / 60;
    // Calculate end hours of an appointment to use in add to calendar button

    const addHours = (time, addHour) => {
        const [h, m] = time.split(':');
        const date = new Date();
        date.setHours(h, m, 0);
        const startsWith0 = time[0] === '0';
        const endsWith30 = time[time.length - 2] === '3';

        const formatHours = !startsWith0
            ? date.getHours()
            : `0${date.getHours()}`;

        const formatMinutes = endsWith30
            ? date.getMinutes()
            : `${date.getMinutes()}0`;

        let res;
        if (addHour <= 1) {
            res = `${formatHours + 1}:${formatMinutes}`;
        } else {
            res = `${formatHours + 2}:${formatMinutes}`;
        }
        return res;
    };

    const appointmentEndTime = addHours(timeSlot, durationInHours);
    yield all([
        put(
            saveEmployeeDetails({
                name: employeeDetails?.firstName,
            })
        ),
        put(
            saveEmployeeDetails({
                image: employeeDetails?.roundedImage,
            })
        ),
    ]);
    yield put(setState({ rescheduleLink: formatRescheduleLink }));
    yield put(setState({ cancelLink: formatCancelLink }));
    yield put(setState({ appointmentEndTime }));
    yield put(setState({ selectedTimeslot: timeSlot }));
    yield put(setState({ selectedDate }));
    yield put(setState({ storeData }));
    yield put(setState({ appLoading: false }));
}

export default function* rootSaga() {
    yield all([
        takeEvery(
            actionTypes.GET_EMPLOYEE_AVAILABILITIES,
            getEmployeeAvailabilities
        ),
        takeEvery(actionTypes.WE_CHAT_INFO, weChatInformation),
        takeEvery(actionTypes.GET_USER_INFO, getUserInfo),
        takeEvery(actionTypes.GET_EMPLOYEE_DETAILS, getEmployeeData),
        takeEvery(actionTypes.GET_EMPLOYEE_LIST, updateEmployeeList),
        takeEvery(actionTypes.GO_BACK_BOOKING, deleteTimeslotReservation),
        takeEvery(actionTypes.INIT, fetchData),
        takeEvery(actionTypes.POST_USER_DATA, registerUserData),
        takeEvery(actionTypes.RESCHEDULE, changeReservation),
        takeEvery(actionTypes.RESERVE_TIMESLOT, makeReservation),
    ]);
}
