import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getButtonStyle } from './style';
import { Loader } from '../loader';

const StyledButton = styled.button(getButtonStyle);

export const Button = ({
    label,
    onClick,
    isDisabled,
    isLoading,
    outlineStyle,
    forwardedRef,
    styleAdvisor,
    smallFont,
    isSticky,
}) => {
    return (
        <StyledButton
            type="button"
            isDisabled={isDisabled}
            onClick={onClick}
            outlineStyle={outlineStyle}
            ref={forwardedRef}
            styleAdvisor={styleAdvisor}
            smallFont={smallFont}
            isSticky={isSticky}
        >
            {isLoading && <Loader isBlack={false} />}
            {!isLoading && label}
        </StyledButton>
    );
};

Button.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    outlineStyle: PropTypes.bool,
};

Button.defaultProps = {
    isDisabled: false,
    isLoading: false,
    outlineStyle: false,
    onClick: () => {},
};
