import styled from 'styled-components';

export const StoreLocatorWrapper = styled.div`
    margin-bottom: 20px;
`;

export const StyledHr = styled.hr`
    background-color: #efefef;
    height: 1px;
    border: none;
    margin: 0;
`;

export const StoresWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    overflow: none;
    overflow-y: auto;
    overflow-x: clip;
    max-height: 48vh;
    justify-content: flex-start;

    &:last-child {
        border-bottom: 1px solid gray;
    }
    @media (min-height: 600px) {
        max-height: 58vh;
    }
    @media (min-height: 800px) {
        max-height: 68vh;
    }
    @media (min-height: 1000px) {
        max-height: 73vh;
    }
`;

export const StoreItemWrapper = styled.label`
    display: flex;
    padding: 20px;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid #efefef;
    width: 100%;
    cursor: pointer;
    animation-duration: 0.6s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
    &:last-child {
        border-bottom: none;
    }
    @keyframes animate-fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    &:hover {
        background-color: #efefef;
        transition: 0.7s ease-out;
    }
`;

export const StoreHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #efefef;
    padding: 20px;
`;

export const StoreHeader = styled.h2`
    color: #2d2e2c;
    font-size: '24px';
`;

export const StoreSelect = styled.select`
    padding: 15px 45px 15px 15px;
    border: 1px solid #e1e1e1;
    cursor: pointer;
    margin-top: 30px;
    border-radius: 5px;
    appearance: none;
    width: 200px;
`;

export const StoreSelectWrapper = styled.div`
    position: relative;
    &::after {
        content: '⌄';
        font-size: 2rem;
        top: 36px;
        right: 12px;
        position: absolute;
        font-weight: 500;
        pointer-events: none;
    }
`;

export const StyledStoreItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: right;
    padding-left: 40px;
    text-align: left;
    width: 50%;
`;

export const StyledInput = styled.input`
    // accent-color: #2d2e2c;
    cursor: pointer;
    color: #e1e1e1;
    visibility: hidden;
    margin-left: 10px;
    &::before {
        border: 1px solid #e1e1e1; /* thickness, style, color */
        height: 0.9em; /* height adjusts with font */
        width: 0.9em; /* width adjusts with font */
        border-radius: 50%; /* make it round */
        display: block; /* or flex or inline-block */
        content: ' '; /* won't display without this */
        cursor: pointer; /* appears clickable to mouse users */
        visibility: visible; /* reverse the 'hidden' above */
        margin-left: 10px;
    }

    &:checked::before {
        background: radial-gradient(white 35%, #2d2e2c 35%);
        border: 1px solid #2d2e2c;
    }
`;

export const StoreOption = styled.option`
    cursor: pointer;
    color: #2d2e2c;
`;

export const StyledStoreItemName = styled.p`
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    color: #2d2e2c;
`;

export const StyledStoreItem = styled.p`
    text-align: left;
    padding: 0;
    margin: 0;
`;

export const StyledHours = styled.p`
    text-align: left;
    color: #757575;
`;

export const DropDownContainer = styled('div')`
    width: 200px;
    margin: 0 auto;
`;

export const DropDownHeader = styled('div')`
    padding: 15px 30px 15px 15px;
    border: 1px solid #e1e1e1;
    cursor: pointer;
    margin-top: 30px;
    border-radius: 5px;
    appearance: none;
`;

export const DropDownIcon = styled('div')`
    padding-top: 2px;
    float: right;
`;

export const DropDownListContainer = styled('div')``;

export const DropDownList = styled('ul')`
    padding: 0;
    margin: 0;
    padding-left: 1em;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    font-size: 1.3rem;
    border-radius: 4px;
    font-weight: 500;
    &:first-child {
        padding-top: 0.8em;
    }
    max-height: 465px;
    padding: 18px 0;
    position: fixed;
    width: 200px;
`;

export const ListItem = styled('li')`
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    width: 100%;
    list-style-type: none;
    padding: 1px;
    &:hover {
        color: grey;
        cursor: pointer;
    }
`;
