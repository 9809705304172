import styled from 'styled-components';
import { mq } from 'styles/mediaqueries';

export const StyledEmployeesListWrapper = styled.div`
    margin-bottom: 15px;

    ${[mq[0]]} {
        padding: 20px 0;
    }
`;

export const StyledEmployeesList = styled.ul`
    list-style: none;
    padding-left: 0;
    margin: 0;
`;

export const StyledNoEmployeesWrapper = styled.div`
    text-align: center;
    margin-top: 50px;
    padding: 0 45px;

    p {
        width: 250px;
        margin: 20px auto;
    }

    ${[mq[0]]} {
        padding: 0 45px;
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            width: 265px;
        }
    }
`;

export const StyledNoEmployeesBtnWrapper = styled.div`
    margin-bottom: 70px;

    button {
        padding: 15px 40px;
    }
`;

export const StyledEmployeesUnvailableHeader = styled.div`
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 80px 0 25px 0;
`;
