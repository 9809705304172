import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import 'moment/locale/zh-cn';

import { getDayStyle, getStyledDate } from './style';

const StyledDay = styled.button(getDayStyle);
const StyledDate = styled.span(getStyledDate);

export const Day = ({
    date,
    newDate,
    isSelected,
    isDisabled,
    onClick,
    wechatID,
    language,
}) => {
    useEffect(() => {
        if (wechatID || language === 'zh-cn') {
            moment.locale('zh-cn');
        } else {
            moment.locale('en');
        }
    }, []);

    return (
        <StyledDay
            type="button"
            date={date}
            isSelected={isSelected}
            isDisabled={isDisabled}
            onClick={onClick}
        >
            {newDate}
            <StyledDate isDisabled={isDisabled}>
                {moment.utc(date).format('MMM Do')}
            </StyledDate>
        </StyledDay>
    );
};

Day.propTypes = {
    date: PropTypes.string.isRequired,
    newDate: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

Day.defaultProps = {
    isSelected: false,
    isDisabled: false,
};
