import { Api, Config } from 'scripts';
import { dateToCustomString, addDays } from 'scripts/Dates';

const endDate = addDays(new Date(), Config.SHOW_DAYS_NUMBER);

const dates = {
    startDate: dateToCustomString(new Date(), 'yyyy-mm-dd'),
    endDate: dateToCustomString(endDate, 'yyyy-mm-dd'),
};

const currentHour = new Date().getHours();
const currentMinutes = new Date().getMinutes();
const newMinutes = currentMinutes < 10 ? `0${currentMinutes}` : currentMinutes;
const newHour = currentHour < 10 ? `0${currentHour}` : currentHour;

const getStoreConfig = ({ storeId }) => {
    return Api(
        `/stores/reference/${storeId}`,
        {
            method: 'GET',
        },
        (response) => {
            return response.data;
        },
        () => {}
    );
};

const getStoreConfigWithStoreId = ({ storeId }) => {
    return Api(
        `/stores/storecode/${storeId}`,
        {
            method: 'GET',
        },
        (response) => {
            return response.data;
        },
        () => {}
    );
};
const getStoreConfigWithWaitwhileId = ({ storeId }) => {
    return Api(
        `/stores/waitwhilelocationid/${storeId}`,
        {
            method: 'GET',
        },
        (response) => {
            return response.data;
        },
        () => {}
    );
};
const resolveEmployeeIdentifierUrlParameter = (
    employeeId,
    waitwhileResourceId
) => {
    const checkForWWResourceId = () => {
        if (waitwhileResourceId) {
            return `waitwhileResourceId=${waitwhileResourceId}`;
        }
        return ``;
    };

    return employeeId ? `employeeId=${employeeId}` : checkForWWResourceId();
};
const getDates = ({ storeId, selectedSalesRep, serviceId, sfscEmployeeId }) => {
    return Api(
        `/employees/availability?storeId=${storeId}&startDate=${
            dates.startDate
        }T${newHour}:${newMinutes}&endDate=${
            dates.endDate
        }&${resolveEmployeeIdentifierUrlParameter(
            sfscEmployeeId,
            selectedSalesRep
        )}&waitWhileServiceId=${serviceId}`,
        {
            method: 'GET',
        },
        (response) => {
            return response.data;
        },
        () => {}
    );
};

const getEmployeeDetails = ({ waitwhileResourceId }) => {
    return Api(
        `/waitwhileId/${waitwhileResourceId}`,
        {
            method: 'GET',
        },
        (response) => {
            return response.data;
        },
        () => {}
    );
};

const getEmployeeList = ({ storeId, data }) => {
    return Api(
        `/employees?storeId=${storeId}&waitwhileServiceId=${data.serviceId}&waitwhileLocationId=${data.waitwhileId}`,
        {
            method: 'GET',
        },
        (response) => {
            return response.data;
        },
        () => {}
    );
};

const reserveTimeslot = ({ storeId, date, time, serviceId, personId }) => {
    const data = {
        date,
        time,
        entireBasket: true,
        serviceId,
    };

    if (personId) {
        Object.assign(data, { personId });
    }

    return Api(
        `/stores/${storeId}/basket/item`,
        {
            method: 'post',
            data,
        },
        (response) => {
            return response && response.data;
        },
        (e) => {
            return e && e.response && e.response.status;
        }
    );
};

const deleteTimeslot = ({ storeId, itemId }) => {
    return Api(
        `/stores/${storeId}/basket/${itemId}`,
        {
            method: 'delete',
        },
        (response) => {
            return response && response.data;
        },
        (e) => {
            return e && e.response && e.response.status;
        }
    );
};

const postUserData = ({ data }) => {
    return Api(
        `/booking/create`,
        {
            method: 'post',
            data,
        },
        (response) => {
            return response && response.data;
        },
        () => {
            return 'error';
        }
    );
};
const retrieveBookingAndAvailabilities = ({
    storeId,
    dates,
    waitwhileResourceId,
    serviceId,
    employeeId,
}) => {
    return Api(
        `/employees/availability?storeId=${storeId}&startDate=${
            dates.startDate
        }T${newHour}:${newMinutes}&endDate=${
            dates.endDate
        }&${resolveEmployeeIdentifierUrlParameter(
            employeeId,
            waitwhileResourceId
        )}&waitWhileServiceId=${serviceId}`,
        {
            method: 'GET',
        },
        (response) => {
            return response.data;
        },
        () => {}
    );
};

const reschedule = ({ bookingId, data, duration }) => {
    return Api(
        `/booking/update`,
        {
            method: 'put',
            data: Object.assign(data, {
                id: bookingId,
                duration,
            }),
        },
        (response) => {
            return response && response.data;
        },
        (e) => {
            return e && e.response && e.response.status;
        }
    );
};

const cancelReservation = ({ bookingId }) => {
    return Api(
        `/booking/cancel/${bookingId}`,
        {
            method: 'delete',
        },
        (response) => {
            return response && response.data;
        },
        () => {
            return 'error';
        }
    );
};

// TO DO finalize after integrated with SFSC
const retrieveCustomerData = ({ customerId, signature }) => {
    return Api(
        `/customer/customer/${customerId}`,
        {
            method: 'get',
            headers: {
                Authorization: signature,
            },
        },
        (response) => {
            return response && response.data;
        },
        () => {
            return 'error';
        }
    );
};

const getBookingData = ({ bookingId }) => {
    return Api(
        `/booking/${bookingId}`,
        {
            method: 'get',
        },
        (response) => {
            return response && response.data;
        },
        () => {
            return 'error';
        }
    );
};

export {
    cancelReservation,
    deleteTimeslot,
    getStoreConfig,
    getStoreConfigWithStoreId,
    getStoreConfigWithWaitwhileId,
    getDates,
    getEmployeeDetails,
    getEmployeeList,
    postUserData,
    reschedule,
    reserveTimeslot,
    retrieveBookingAndAvailabilities,
    retrieveCustomerData,
    getBookingData,
};
