import styled from 'styled-components';
import { mq } from 'styles/mediaqueries';
import { text } from 'styles/variables';

export const StyledButtonWrapper = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: calc(100% - 30px);
    margin: 0 15px;

    ${[mq[0]]} {
        position: relative;
        left: auto;
        bottom: auto;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
`;

export const getH1Style = ({ inIframe }) => {
    return {
        margin: '0 auto',
        lineHeight: '23px',
        color: text,
        whiteSpace: inIframe ? 'pre' : 'normal',

        [mq[3]]: {
            fontSize: '3.2rem',
            lineHeight: '37px',
        },
    };
};

export const getH1Wrapper = () => {
    return {
        marginTop: '30px',
        display: 'flex',
        margin: '30px 50px',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '120px',
        backgroundColor: 'white',

        [mq[2]]: {
            minHeight: '189px',
        },
    };
};

export const StyledH3 = styled.h3`
    font-size: 15px;
    color: #757575;
    display: block;
    text-transform: capitalize;
`;

export const getStyledNoPrefAvatar = () => {
    return {
        width: 90,
        height: 90,
        flexShrink: 0,

        [mq[0]]: {
            width: 100,
            height: 100,
        },
    };
};

export const getStyledErrorScreen = () => {
    return {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '16px',
        fontWeight: '300',
        textAlign: 'center',
    };
};
