import React, { Component } from 'react';
import { Analytics } from 'scripts';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { ValidIcon, ErrorIcon } from 'icons';
import {
    StyledFormItem,
    StyledLabel,
    StyledInputWrapper,
    StyledError,
} from '../style';

export class TextareaField extends Component {
    constructor(props) {
        super(props);
        this.state = { isFloating: false, rows: 1, minRows: 1, maxRows: 5 };
        const { value } = this.props;
        if (value) this.state = { isFloating: true };
    }

    componentDidUpdate(newProps) {
        const { selectedTimeslot, storeRef } = this.props;
        const oldProps = this.props;
        if (oldProps.hasError !== newProps.hasError) {
            Analytics({
                type: 'FormError',
                label: `${storeRef}|${
                    selectedTimeslot && selectedTimeslot.replace(':', '')
                }`,
            });
        }
    }

    render() {
        const {
            hasError,
            id,
            isValid,
            label,
            name,
            value,
            validation,
            onInput,
            handleChange,
        } = this.props;

        const { isFloating, rows } = this.state;

        const floatLabel = (val) => {
            if (!val) {
                this.setState({ isFloating: false });
            } else {
                this.setState({ isFloating: true });
            }
        };

        /* eslint-disable no-param-reassign */
        const ResizableTextarea = (event) => {
            const textareaLineHeight = 18;
            const { minRows, maxRows } = this.state;

            const previousRows = event.target.rows;
            event.target.rows = minRows; // reset number of rows in textarea

            const currentRows = Math.floor(
                event.target.scrollHeight / textareaLineHeight
            );

            if (currentRows === previousRows) {
                event.target.rows = currentRows;
            }

            if (currentRows >= maxRows) {
                event.target.rows = maxRows;
                event.target.scrollTop = event.target.scrollHeight;
            }

            this.setState({
                rows: currentRows < maxRows ? currentRows : maxRows,
            });
        };

        return (
            <StyledFormItem>
                <StyledInputWrapper hasError={hasError}>
                    <Field
                        id={id}
                        name={name}
                        component="textarea"
                        rows={rows}
                        validate={validation}
                        onInput={onInput}
                        value={value}
                        onBlur={(e) => {
                            handleChange(e);
                            floatLabel(e.currentTarget.value);
                            ResizableTextarea(e);
                        }}
                        onChange={(e) => {
                            handleChange(e);
                            floatLabel(e.currentTarget.value);
                            ResizableTextarea(e);
                        }}
                    />
                    <StyledLabel htmlFor={id} isFloating={isFloating}>
                        {label}
                    </StyledLabel>
                    {isValid && <ValidIcon />}
                    {hasError && <ErrorIcon />}
                </StyledInputWrapper>

                {hasError && <StyledError>{hasError}</StyledError>}
            </StyledFormItem>
        );
    }
}

TextareaField.propTypes = {
    hasError: PropTypes.string,
    id: PropTypes.string,
    isValid: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
    onInput: PropTypes.func,
    selectedTimeslot: PropTypes.string,
    storeRef: PropTypes.string,
    validation: PropTypes.func,
    handleChange: PropTypes.func,
};

TextareaField.defaultProps = {
    hasError: null,
    id: null,
    isValid: false,
    name: null,
    value: null,
    onInput: () => {},
    selectedTimeslot: null,
    storeRef: null,
    validation: () => {},
    handleChange: () => {},
};
