import content from 'content';
import React from 'react';
import { StoreHeader } from 'screens/storeLocator/style';
import { ServicePickerHeaderWrapper } from '../style';

const ServicePickerHeader = () => {
    return (
        <ServicePickerHeaderWrapper>
            <StoreHeader>{content.selectService}</StoreHeader>
        </ServicePickerHeaderWrapper>
    );
};

export default ServicePickerHeader;
