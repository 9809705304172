import React, { useState } from 'react';
import { ValidIcon, ErrorIcon } from 'icons';
import { StyledFormItem, StyledLabel, StyledPhoneInputWrapper } from '../style';
import { Field } from 'formik';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const PhoneInputField = ({ id, name, saveUserData, countryCode, wechatID,language }) => {
    const [hasError, setHasError] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const formatPhone = (phone, details) => {
        const regex = phone.replace(/[()]/g, '').replace(/[.-\s+]/g, '');

        if (phone[0] === '0') {
            const withoutZero = phone.slice(1, phone.length);
            const finalPhone = withoutZero
                .replace(/[()]/g, '')
                .replace(/[.-\s+]/g, '');

            return '+' + details.dialCode + finalPhone;
        } else if (phone[0] === '+') {
            return '+' + regex;
        } else {
            return '+' + details.dialCode + regex;
        }
    };

    const phoneCheck = (phone) => {
        if (phone[0] === '+' && phone[1] === '+') {
            return false;
        } else if (isNaN(phone[phone.length - 1])) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <StyledFormItem>
            <StyledPhoneInputWrapper hasError={hasError}>
                <StyledLabel htmlFor={id}></StyledLabel>
                <Field name={name}>
                    {({
                        field: { value },
                        form: { isSubmitting, setFieldTouched },
                    }) => {
                        return (
                            <>
                                <IntlTelInput
                                    defaultCountry={
                                        wechatID
                                            ? 'cn'
                                            : countryCode.toLowerCase() || 'nl'
                                    }
                                    disabled={isSubmitting}
                                    fieldId={name}
                                    defaultValue={value !== null ? value : '0'}
                                    fieldName={name}
                                    formatOnInit={false}
                                    preferredCountries={[
                                        'nl',
                                        'gb',
                                        'us',
                                        'de',
                                        'be',
                                        'fr',
                                        'it',
                                    ]}
                                    onPhoneNumberBlur={(e) => {
                                        setFieldTouched(name, true);
                                    }}
                                    customPlaceholder={(data)=>{
                                        return wechatID || language === 'zh-cn' ? '电话' : data
                                    }}
                                    
                                    autoHideDialCode={true}
                                    onPhoneNumberChange={(
                                        validNumber,
                                        phoneNum,
                                        details
                                    ) => {
                                        saveUserData({
                                            phone: formatPhone(
                                                phoneNum,
                                                details
                                            ),
                                        });

                                        if (
                                            validNumber &&
                                            phoneCheck(phoneNum)
                                        ) {
                                            setHasError(false);
                                            setIsValid(true);
                                            saveUserData({
                                                phoneValid: true,
                                            });
                                        } else {
                                            setIsValid(false);
                                            setHasError(true);
                                            saveUserData({
                                                phoneValid: false,
                                            });
                                        }
                                    }}
                                />
                            </>
                        );
                    }}
                </Field>
                {hasError && <ErrorIcon />}
                {isValid && <ValidIcon />}
            </StyledPhoneInputWrapper>
        </StyledFormItem>
    );
};

export default PhoneInputField;
