import React from 'react';
import PropTypes from 'prop-types';
import {
    StoreItemWrapper,
    StyledHours,
    StyledStoreItem,
    StyledStoreItemName,
    StyledInput,
    StyledStoreItemWrapper,
} from '../style';

const StoreItem = ({
    name,
    adress,
    city,
    handleInputChange,
    zipCode,
    selected,
    hours,
    storeId,
}) => {
    const formatName = (input) => {
        return input?.split(/(?=[A-Z])/).join(' ');
    };

    const formatTime = (input) => {
        return input?.slice(6, input.length);
    };
    return (
        <StoreItemWrapper htmlFor={name}>
            <StyledInput
                type="radio"
                value={name}
                onChange={handleInputChange}
                name="store-item"
                checked={selected === name}
                id={name}
                data-storeid={storeId}
            />
            <StyledStoreItemWrapper>
                <StyledStoreItemName>{formatName(name)}</StyledStoreItemName>
                <StyledStoreItem>{adress}</StyledStoreItem>
                <StyledStoreItem>
                    {zipCode} {city}
                </StyledStoreItem>
                {hours && (
                    <StyledHours>
                        Open today until {formatTime(hours)}
                    </StyledHours>
                )}
            </StyledStoreItemWrapper>
        </StoreItemWrapper>
    );
};

StoreItem.propTypes = {
    name: PropTypes.string.isRequired,
    adress: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    zipCode: PropTypes.string.isRequired,
    selected: PropTypes.string,
    hours: PropTypes.string,
    storeId: PropTypes.string.isRequired,
};

StoreItem.defaultProps = {
    selected: null,
    hours: null,
};
export default StoreItem;
