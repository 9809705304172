import React from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment/min/moment-with-locales';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { history } from '../../redux/store';
import { Config } from 'scripts';
import content from 'content';
import SEO from 'components/seo';
import { actionTypes } from 'redux/actions';
import { dateToCustomString, addDays, getDateFromString } from 'scripts/Dates';
import Header from 'components/layout/header';
import { Wrapper } from 'components/layout/wrapper';
import { Button } from 'components/button';
import { Loader } from 'components/loader';

const CancelScreen = ({
    init,
    reroute,
    selectedDate,
    selectedTimeslot,
    setState,
    appLoading,
    language
}) => {
    
    const formatDate = (date) => {
        const fullDate = getDateFromString(date, 'yyyy-mm-dd');
        if (!(fullDate instanceof Date)) {
            return null;
        }

        const D = fullDate.getDay();
        const d = fullDate.getDate();
        const m = fullDate.getMonth();

        return `${moment.weekdays(D)}, ${d} ${moment.months(m)}`;
    };

    const endDate = addDays(new Date(), Config.SHOW_DAYS_NUMBER);

    const dates = {
        startDate: dateToCustomString(new Date(), 'yyyy-mm-dd'),
        endDate: dateToCustomString(endDate, 'yyyy-mm-dd'),
    };

    const { storeId } = useSelector((state) => state && state.reducer) || {};
    const { serviceId } = useSelector((state) => state && state.reducer);
    return (
        <>
            {appLoading && <Loader isBlack />}
            <SEO title={content.metaData.cancelAppointmentTitle} />
            <Header />

            <main>
                <Wrapper isSmall isVerticalAligned>
                    <h1>{content.deleteAppointmentHeader}</h1>
                    <p>
                        {content.formatString(content.deleteAppointmentText, {
                            date: formatDate(selectedDate),
                            time: selectedTimeslot,
                        })}
                    </p>
                    <Button
                        label={content.btn.rechedule}
                        outlineStyle
                        onClick={() => {
                            init(dates);

                            setState({ selectedTimeslot: null });
                            setState({ selectedTimeslotUtc: null });
                            history.push(
                                `/booking/?storeId=${storeId}&serviceId=${serviceId}&fromCancel=true&language=${language||'en'}`
                            );
                            window.location.reload(false);
                        }}
                    />
                </Wrapper>
            </main>
        </>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        selectedTimeslot: reducer.selectedTimeslot,
        selectedDate: reducer.selectedDate,
        appLoading: reducer.appLoading,
        language: reducer.language
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: (payload) => dispatch({ type: actionTypes.INIT, payload }),
        reroute: (payload) => dispatch(push(payload)),
        selectDate: (payload) =>
            dispatch({ type: actionTypes.SELECT_DATE, payload }),
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};

CancelScreen.propTypes = {
    init: PropTypes.func.isRequired,
    reroute: PropTypes.func.isRequired,
    selectedDate: PropTypes.string,
    selectedTimeslot: PropTypes.string,
    selectDate: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
};

CancelScreen.defaultProps = {
    selectedDate: null,
    selectedTimeslot: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelScreen);
