import Url from './Url';
import inIFrame from './inIFrame';
import formatDate from './format';
import shuffleEmployees from './shuffleEmployees';

export default {
    Url,
    formatDate,
    inIFrame,
    shuffleEmployees
};
