import {
    text,
    disabledBg,
    brandColor,
    hoverColor,
    grey,
    backgroundLight,
    background,
    secondaryVariation
} from 'styles/variables';
import { mq,mqm } from 'styles/mediaqueries';

export const getButtonStyle = ({
    isDisabled,
    isLoading,
    outlineStyle,
    styleAdvisor,
    smallFont,
    isSticky,
}) => {
    let color = 'white';
    let backgroundColor = brandColor;
    let hoverBackgroundColor = hoverColor;
    let hoverBorderColor = hoverColor;
    let borderColor = brandColor;
    let width = '100%';
    let padding = '15px 20px';
    let fontSize = '1.4rem';
    let position = 'relative';
    let left;
    let transform;
    let marginTop;
    let bottom;

    if (isDisabled) {
        color = secondaryVariation;
        backgroundColor = backgroundLight;
        hoverBackgroundColor = disabledBg;
        borderColor = background;
    }

    if (outlineStyle) {
        color = text;
        backgroundColor = 'transparent';
        hoverBackgroundColor = 'transparent';
        borderColor = grey;
    }

    if (outlineStyle && isDisabled) {
        color = '#c6c6c6';
        hoverBackgroundColor = 'transparent';
        hoverBorderColor = '#E1E1E1 !important';
    }

    if (styleAdvisor) {
        width = 'auto';
        padding = '10px 25px';
    }

    if (smallFont) {
        fontSize = '1.2rem';
    }

    if (isSticky) {
        position = 'fixed';
        left = '50%';
        transform = 'translateX(-50%)';
        bottom = '30px';
    }

    return {
        outline: 0,
        border: `1px solid ${borderColor}`,
        display: 'block',
        width,
        margin: '10px auto',
        padding,
        borderRadius: 4,
        fontSize,
        lineHeight: '1.2em',
        color,
        backgroundColor,
        cursor: isDisabled ? 'default' : 'pointer',
        transition: 'all .3s ease-out',
        pointerEvents: isDisabled || isLoading ? 'none' : 'auto',
        position,
        left,
        transform,
        minHeight: 46,
        marginTop,
        bottom,

        '&:hover, &:focus': {
            backgroundColor: hoverBackgroundColor,
            borderColor: hoverBorderColor,
        },

        [mq[0]]: {
            maxWidth: 300,
            
        },
        [mqm[0]]: {
            maxWidth: 300,
            bottom:15,
        },
        // [mq[1]]: {
        //     margin: '10px auto',
        // },

        'form &': {
            marginTop: 42,

            [mq[0]]: {
                marginTop: 68,
            },
        },
    };
};
