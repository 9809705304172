import {
    text,
    textDisabled,
    brandColor,
    hoverColor,
    grey,
} from 'styles/variables';
import { mq } from 'styles/mediaqueries';

export const getDayStyle = ({ isSelected, isDisabled }) => {
    let borderColor = grey;
    if (isSelected) {
        borderColor = brandColor;
    }
    if (isDisabled) {
        borderColor = grey;
    }

    let borderColorHover = hoverColor;
    if (isSelected) {
        borderColorHover = brandColor;
    }
    if (isDisabled) {
        borderColorHover = grey;
    }

    return {
        alignItems: 'center',
        outline: 0,
        borderRadius: 3,
        border: `1px solid ${borderColor}`,
        color: isDisabled ? textDisabled : text,
        cursor: isDisabled ? 'default' : 'pointer',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1.8rem',
        lineHeight:'21px',
        textTransform: 'capitalize',
        justifyContent: 'center',
        margin: 'auto',
        transition: 'all .3s ease-out',
        height: '100%',
        width: '100%',
        padding: '16px 7px',
        backgroundColor: 'white',
        '@media (hover: hover)': {
            '&:hover': {
                borderColor: borderColorHover,
                cursor: isSelected ? 'default' : 'pointer',
            },
        },

        [mq[1]]: {
            padding: '25px 15px',
        },

        [mq[3]]: {
            fontSize: '2.4rem',
            lineHeight:'28px',
            padding: '26px 15px',
        },
    };
};

export const getStyledDate = ({isDisabled}) => {
    return{
        paddingTop:'5px',
        color: isDisabled ? textDisabled : text,
        fontSize:'13px',
        lineHeight:'11px',
        fontWeight:'normal',


        [mq[3]]:{
            lineHeight:'16px',

        },
    }
}