import styled from 'styled-components';
import { textError, errorBorder, grey, brandColor } from 'styles/variables';
import { mq } from '../../styles/mediaqueries';

const getFormItemStyle = () => {
    return {
        marginBottom: 42,
        position: 'relative',

        [mq[3]]: {
            width: '272px',
        },
    };
};

const getLabelStyle = ({ isFloating }) => {
    return {
        display: 'block',
        pointerEvents: 'none',
        transition: '200ms ease all',
        transformOrigin: 'left top',
        position: 'absolute',
        left: 0,
        top: isFloating ? -4 : 8,
        fontFamily: 'GT America,-apple-system,Arial,sans-serif',
        fontSize: isFloating ? '1.2rem' : '1.4rem',
        lineHeight: isFloating ? '14px' : '16px',
        fontWeight: isFloating ? 600 : 300,
        transform: `translateY(${isFloating ? '-10' : 0}px)`,
    };
};

const getInputWrapperStyle = ({ hasError }) => {
    return {
        position: 'relative',

        'input, textarea': {
            fontFamily: 'GT America,-apple-system,Arial,sans-serif',
            fontWeight: 300,
            fontSize: '1.5rem',
            lineHeight: '18px',
            border: 0,
            borderRadius: 0,
            borderBottom: `1px solid ${hasError ? errorBorder : grey}`,
            boxSizing: 'border-box',
            width: '100%',
            padding: '5px 20px 5px 0',
            appearance: 'none',
            webkitTapHighlightColor: 'rgba(255,255,255,0)',

            '&:focus': {
                outline: 0,
                borderBottomColor: brandColor,

                '+ label': {
                    display: 'inline',
                    top: '-4px',
                    fontSize: '1.2rem',
                    lineHeight: '14px',
                    fontWeight: 600,
                    transform: 'translateY(-10px)',
                },
                '+ label[for="phone"]:after': {
                    fontWeight: 300,
                },
            },
        },

        svg: {
            width: 20,
            height: 20,
            position: 'absolute',
            top: 5,
            right: 0,
        },
    };
};

const getPhoneInputWrapperStyle = ({ hasError }) => {
    return {
        position: 'relative',
        '.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel]':{
            paddingRight:61,
            paddingLeft:50
        },
        '.intl-tel-input.allow-dropdown .selected-flag':{
            width:35
        },
        'input, textarea': {
            fontFamily: 'GT America,-apple-system,Arial,sans-serif',
            fontWeight: 300,
            fontSize: '1.5rem',
            lineHeight: '18px',
            border: 0,
            borderRadius: 0,
            borderBottom: `1px solid ${hasError ? errorBorder : grey}`,
            boxSizing: 'border-box',
            width: '100%',
            padding: '5px 20px 5px 0',
            appearance: 'none',
            webkitTapHighlightColor: 'rgba(255,255,255,0)',

            '&:focus': {
                outline: 0,
                borderBottomColor: brandColor,

                '+ label': {
                    display: 'inline',
                    top: '-4px',
                    fontSize: '1.2rem',
                    lineHeight: '14px',
                    fontWeight: 600,
                    transform: 'translateY(-10px)',
                },
                '+ label[for="phone"]:after': {
                    fontWeight: 300,
                },
            },
        },

        svg: {
            width: 40,
            height: 20,
            position: 'absolute',
            top: 5,
            right: -9,
        },
    };
};

const getErrorStyle = () => {
    return {
        color: textError,
        fontSize: '1.2rem',
        marginTop: 4,
    };
};



export const StyledFormItem = styled.div(getFormItemStyle);
export const StyledLabel = styled.label(getLabelStyle);
export const StyledInputWrapper = styled.div(getInputWrapperStyle);
export const StyledPhoneInputWrapper = styled.div(getPhoneInputWrapperStyle);
export const StyledError = styled.div(getErrorStyle);
