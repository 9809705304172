import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getAlertStyle } from './style';

const StyledAlert = styled.div(getAlertStyle);

export const Alert = ({ children }) => {
    return <StyledAlert role="alert">{children}</StyledAlert>;
};

Alert.propTypes = {
    children: PropTypes.node.isRequired,
};
