import { text, textAlt, grey, alto, brandColor } from 'styles/variables';
import { mq } from 'styles/mediaqueries';
import styled from 'styled-components';

export const getCalendarStyle = ({ unavailableTimeslots }) => {
    return {
        backgroundColor: unavailableTimeslots ? 'none' : '#fff',
        borderBottom: unavailableTimeslots ? 'none' : `1px solid ${grey}`,
        color: textAlt,
        padding: `17px 0 20px 0`,
        width: '100%',
        boxSizing: 'border-box',

        '.tns-slider': {
            display: 'flex',
        },

        '.tns-inner': {
            marginLeft: '10px !important',
        },

        [mq[0]]: {
            '.tns-inner': {
                marginLeft: 'inherit !important',
            },
        },

        [mq[1]]: {
            '.tns-inner': {
                marginLeft: '50px !important',
            },
        },

        [mq[2]]: {
            padding: '35px 0 25px',
            '.tns-inner': {
                marginLeft: 'inherit !important',
            },
        },

        [mq[3]]: {},
    };
};

export const getCalendarWrapperStyle = () => {
    return {
        [mq[1]]: {
            // width: '90%',
        },
        [mq[2]]: {
            margin: '0 auto',
            width: '80%',
        },
        [mq[3]]: {
            width: '65%',
            maxWidth: 972,
        },
    };
};

export const getCalendarTitleStyle = () => {
    return {
        textAlign: 'center',
    };
};

export const StyledH3 = styled.h3`
    font-size: 15px;
    color: #757575;
    display: block;
    text-transform: capitalize;
    margin: 10px 0 30px;
    text-align: center;
`;

export const StyledEmployeeName = styled.h3`
    font-size: 15px;
    color: #2d2e2c;
    display: block;
    text-transform: capitalize;
    margin: 10px 0;
    text-align: center;
    line-height: 22px;
    font-weight: 500;
`;

export const StyledTinySlider = styled.div`
    .tns-slider{
        transition-duration:0s !important;
    }
`

export const StyledH1 = styled.h1`
    margin: 0 auto;
    line-height: 23px;
    color: #2d2e2c;
    white-space: normal;
`;

export const getCalendarContainerStyle = () => {
    return {
        marginTop: '19px',
        position: 'relative',

        [mq[0]]: {
            padding: '0 50px', // get space for the slider arrows
        },

        [mq[1]]: {
            padding: 0,
        },

        [mq[2]]: {
            padding: '0 50px', // get space for the slider arrows
            marginTop: '42px',
        },
    };
};

export const getCalendarItemStyle = () => {
    return {
        textAlign: 'center',
        color: textAlt,
        fontSize: '1rem',
        flexShrink: 0,

        [mq[2]]: {
            fontSize: '1.2rem',
        },
    };
};

export const getControlBtnStyle = ({ btnNext, isHidden }) => {
    return {
        display: 'none',

        [mq[0]]: {
            backgroundColor: 'white',
            border: `1px solid ${alto}`,
            borderRadius: '100%',
            outline: 0,
            display: isHidden ? 'none' : 'flex',
            padding: 0,
            justifyContent: 'center',
            alignItems: 'center',
            width: 23,
            height: 23,
            position: 'absolute',
            left: btnNext ? 'auto' : '13px',
            right: btnNext ? '13px' : 'auto',
            top: 'calc(50% - 11.5px)',
            transform: btnNext ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: 'border-color .3s ease-out',
            zIndex: 10,

            '&:hover, &:focus': {
                borderColor: brandColor,
                cursor: 'pointer',
            },

            svg: {
                fill: text,
                marginRight: btnNext ? '-1px' : 0,
                height: 12,
                width: 12,
            },
        },

        [mq[1]]: {
            display: 'none',
        },

        [mq[2]]: {
            display: isHidden ? 'none' : 'flex',
            width: 33,
            height: 33,
            position: 'absolute',
            left: btnNext ? 'auto' : '0',
            right: btnNext ? '0' : 'auto',
            backgroundColor: 'transparent',
        },
    };
};
export const getEmployeeAvatarContainer = () => {
    return {
        height: '100px',
        width: '100px',
        margin: '0 auto',

        [mq[2]]: {
            height: '100px',
            width: '100px',
        },
    };
};

export const getEmployeeUnavailableContainer = () => {
    return {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        height: '32vh',
    };
};

export const getEmployeeAvatar = () => {
    return {
        width: '100%',
        height: '100%',
    };
};
export const getNoPrefImageContainer = () => {
    return {
        margin: '0 auto',
        width: '100px',
        // height: '55px',

        [mq[2]]: {
            width: '100px',
            height: '100px',
        },
    };
};

export const getStyledCircularLoaderWrapper = () => {
    return {
        position: 'absolute',
        height: '103px',
        width: '100%',
        top: '-15px',
        zIndex: '9',

        [mq[0]]: {
            right: '-217px',
            top: '-15px',
        },
        [mq[1]]: {
            right: '-348px',
            top: '-5px',
        },

        [mq[2]]: {
            right: '-409px',
            top: '-2px',
        },
        [mq[3]]: {
            right: '-470px',
            top: '3px',
        },
    };
};

export const getStyledNoPrefAvatar = () => {
    return {
        width: 100,
        height: 100,
        flexShrink: 0,
        [mq[2]]: {
            height: '100px',
            width: '100px',
        },
    };
};

export const getStyledOpacityDiv = () => {
    return {
        height: '103px',
        width: '90%',
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: '14px',
        zIndex: 9,
    };
};
