import React from 'react';
import PropTypes from 'prop-types';

import content from 'content';
import { Button } from 'components/button';

import {
    StyledEmployee,
    StyledEmployeeImage,
    StyledTag,
    StyledEmployeeContainer,
} from './style';

export const Employee = ({
    person,
    onClick,
    onAboutMe,
    isDisabled,
    selectedSalesRep,
    lastFitting,
    btnLabel,
    btnDisabled,
    firstChildBorder,
}) => {
    return (
        <StyledEmployee
            key={`employee-${person.id}`}
            isDisabled={isDisabled}
            isSelected={person.id === selectedSalesRep}
            lastFitting={lastFitting}
            firstChild={firstChildBorder}
        >
            <StyledEmployeeContainer onClick={onAboutMe}>
                <StyledEmployeeImage>
                    <picture>
                        <source
                            media="(min-width: 1200px)"
                            srcSet={person.image}
                        />
                        <img src={person.image} alt="" />
                    </picture>
                </StyledEmployeeImage>
                {person.name}
                {lastFitting && <StyledTag>{content.lastFitting}</StyledTag>}
            </StyledEmployeeContainer>
            <Button
                type="button"
                onClick={onClick}
                isSelected={person.id === selectedSalesRep}
                label={btnLabel}
                outlineStyle
                isDisabled={btnDisabled}
            />
        </StyledEmployee>
    );
};

Employee.propTypes = {
    person: PropTypes.oneOfType([PropTypes.object]).isRequired,
    onClick: PropTypes.func.isRequired,
    onAboutMe: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    selectedSalesRep: PropTypes.string,
    lastFitting: PropTypes.bool,
    btnDisabled: PropTypes.bool,
    btnLabel: PropTypes.string,
    firstChildBorder: PropTypes.oneOfType([PropTypes.array]),
};

Employee.defaultProps = {
    selectedSalesRep: null,
    lastFitting: false,
    btnDisabled: null,
    btnLabel: null,
    firstChildBorder: null,
};
