import { mq } from 'styles/mediaqueries';
import { isSafari } from 'react-device-detect';
export const getWrapperStyle = ({ isSmall }) => {
    let mobileWidth = '100%';
    let ipadLandWidth = '80%';
    let smallScreenWidth = '55%';
    let width = '41%';

    if (isSmall) {
        mobileWidth = '80%';
        ipadLandWidth = '80%';
        smallScreenWidth = '80%';
        width = '30%';
    }

    return {
        padding: '0 18px',
        paddingBottom: isSafari ? '20px' : '0',
        position: 'relative',
        boxSizing: 'border-box',
        width: mobileWidth,
        margin: '0 auto',

        [mq[1]]: {
            /**
             * We use this wrapper for the timeslots,
             * but there a sometimes in a 1/3 modals,
             * so we need to think about this case
             */
            '#modal &': {
                width: ipadLandWidth,
                padding: '0 70px',
            },
        },

        [mq[2]]: {
            width: ipadLandWidth,
            padding: '0 70px',

            '#modal &': {
                width: '100%;',
                padding: '0 18px',
            },
        },

        [mq[3]]: {
            width: smallScreenWidth,
            padding: '0 20px',

            '#modal &': {
                width: smallScreenWidth,
                padding: '0 20px',
            },
        },

        [mq[4]]: {
            width,

            '#modal &': {
                width,
            },
        },
    };
};
