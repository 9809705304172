import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Utils } from 'scripts';

import { actionTypes } from '../../redux/actions';
import { getStickyWrapperStyle } from './style';

const StyledStickyWrapper = styled.div(getStickyWrapperStyle);
const inIFrame = Utils.inIFrame();

const StickyWrapper = ({ refreshHeight, children, setState }) => {
    const [stickyHeight, setStickyHeight] = useState(0);
    const [isSticky, setIsSticky] = useState(false);
    const stickyWrapper = document.getElementById('sticky');

    const handleStickiness = () => {
        if (stickyWrapper) {
            const stickyOffset = stickyWrapper.offsetTop;
            setStickyHeight(stickyWrapper.clientHeight);
            setState({ stickyHeight });

            setIsSticky(window.pageYOffset > stickyOffset);
        }
    };
    useLayoutEffect(() => {
        handleStickiness();
    }, [setState, stickyHeight, refreshHeight, stickyWrapper?.clientHeight]);

    return (
        <>
            <StyledStickyWrapper
                id="sticky"
                isSticky={isSticky}
                inIFrame={inIFrame}
            >
                {children}
            </StyledStickyWrapper>
        </>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        context: reducer.context,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};

StickyWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    refreshHeight: PropTypes.bool.isRequired,
    setState: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StickyWrapper);
