import { mq } from 'styles/mediaqueries';

export const getAppContainerStyle = () => {
    return {
        width: '100vw',
        height: 'calc(var(--vh, 1vh) * 100)',
        overflow: 'auto',

        [mq[1]]: {
            margin: '0 auto',
        },
    };
};
