import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import rootReducer from './reducers';
import rootSaga from './sagas';

export const history = createBrowserHistory({ basename: '' });

const sagaMiddleware = createSagaMiddleware();
const routerMiddlewaree = routerMiddleware(history);

const middlewares = [sagaMiddleware, routerMiddlewaree];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
    rootReducer(history),
    composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);
