import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import content from 'content';
import PropTypes from 'prop-types';

const SEO = ({ title, description, context }) => {
    const pageTitle = title || content.metaData.defaultTitle;
    const metaDescription = description || content.metaData.defaultDescription;
    const { locale } = context;

    return (
        <Helmet htmlAttributes={{ lang: locale }}>
            <title>{`${pageTitle} | Suitsupply`}</title>
            <meta name="description" content={metaDescription} />
        </Helmet>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        context: reducer.context,
    };
};

SEO.propTypes = {
    context: PropTypes.shape({
        locale: PropTypes.string,
        mode: PropTypes.string,
    }).isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
};

SEO.defaultProps = {
    title: '',
    description: '',
};

export default connect(mapStateToProps)(SEO);
