import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import Content from 'content';
import { Analytics, Utils } from 'scripts';
import { actionTypes } from 'redux/actions';
import HeaderIframe from 'components/layout/headerIframe';
import Header from 'components/layout/header';
import { Wrapper } from 'components/layout/wrapper';
import { Alert } from 'components/alert';
import { InputField } from 'components/form/inputField';
import { TextareaField } from 'components/form/textareaField';
import { Button } from 'components/button';

import TelephoneInput from 'components/form/phoneInputField';
import {
    DropDownIcon,
    DropDownListContainer,
} from 'screens/storeLocator/style';
import {
    getFormWrapperStyle,
    getH1Style,
    StyledCheckboxText,
    StyledCheckboxLabel,
    StyledInput,
    getStyledConfirmationBlock,
    DropDownListGroup,
    DropDownContainerGroup,
    DropDownHeaderGroup,
    ListItemGroup,
    StyledListItemName,
} from './style';
import configData from '../../config.json';
import content from 'content';

const inIFrame = Utils.inIFrame();
const StyledH1 = styled.h1(getH1Style);
const StyledFormWrapper = styled.div(getFormWrapperStyle);
const StyledConfirmationBlock = styled.div(getStyledConfirmationBlock);



const UserDataScreen = ({
    error,
    loadingReserve,
    postUserData,
    saveUserData,
    selectedTimeslot,
    storeId,
    countryCode,
    userData,
    selectedEmployee,
    storeRef,
    bookingData,
    waitwhileId,
    resourceId,
    serviceId,
    sympaEmployeeId,
    wechatID,
    setState,
    language,
}) => {
    const groupSize = [`2-3 ${content.people}`, `4-5 ${content.people}`, `6+ ${content.people}`];
    const [isOpen, setIsOpen] = useState(false);
    const toggling = () => setIsOpen(!isOpen);
    const [selected, setSelected] = useState('');
    const getFieldValue = wechatID || language === 'zh-cn' ? 1 : 2;

    const validation = (value, key) => {
        let err;

        if (!value) {
            err = Content.formErrors.required;
        } else if (
            key === 'email' &&
            !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(
                value
            )
        ) {
            err = Content.formErrors.email;
        } else if (key === 'email' && value[value.length - 1] === '.') {
            err = Content.formErrors.email;
        } else if (
            key === 'phone' &&
            !/^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{7,15})$/i.test(value)
        ) {
            err = Content.formErrors.phone;
        } else if (key !== 'email' && value.length < getFieldValue) {
            err = Content.formErrors[key];
        }

        return err;
    };
    useEffect(() => {

        if (userData?.phone) {
            saveUserData({
                phoneValid: true,
            });
        }
        setState({ fromUserData: true });
        setState({loadingReserve:false})
    }, []);

    const formatDate = `${bookingData?.date}T${bookingData?.time}`;

    const handleGroupSizeChange = (item) => {
        setSelected(item);

        setIsOpen(false);
    };

    const isGroupAppointment =
        serviceId === configData.SERVICE_ID.GROUP_SERVICE_ID ||
        serviceId === configData.SERVICE_ID.GROUP_SERVICE_ID_TEST;

    return (
        <>
            {inIFrame ? (
                <HeaderIframe
                    title={Content.userDataFormTitle}
                    inIframe={inIFrame}
                />
            ) : (
                <Header />
            )}
            <main>
                {inIFrame && (
                    <StyledH1 hasIframeStyle={inIFrame}>
                        {Content.userDataFormTitle}
                    </StyledH1>
                )}

                <Wrapper isSmall isVerticalAligned>
                    {!inIFrame && (
                        <StyledH1>{Content.userDataFormTitle}</StyledH1>
                    )}
                    <Formik
                        enableReinitialize
                        initialValues={userData}
                        validateOnChange={false}
                        onSubmit={(values) => {
                            Analytics({
                                type: `${
                                    isGroupAppointment ? 'Group' : 'Individual'
                                }_Confirm_Appointment`,
                                label: sympaEmployeeId
                                    ? `${sympaEmployeeId}-${selectedEmployee}`
                                    : 'No preference',
                            });
                            postUserData({
                                data: JSON.stringify({
                                    email: values.email,
                                    phone: values.phone,
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    date: formatDate,
                                    serviceIds: [serviceId],
                                    resourceIds: resourceId
                                        ? [resourceId]
                                        : null,
                                    locationId: waitwhileId,
                                    storeId,
                                    notes: userData?.privateNote,
                                    groupSize: selected,
                                    customerWechatId: wechatID,
                                }),

                                storeId,
                            });
                        }}
                    >
                        {({ errors, handleSubmit, handleChange, touched }) => (
                            <StyledFormWrapper hasIframeStyle={inIFrame}>
                                <Form>
                                    <InputField
                                        storeRef={storeRef}
                                        selectedTimeslot={selectedTimeslot}
                                        label={Content.firstName}
                                        name="firstName"
                                        id="firstName"
                                        value={userData.firstName}
                                        handleChange={handleChange}
                                        isValid={
                                            !errors.firstName &&
                                            touched.firstName
                                        }
                                        validation={(value) =>
                                            validation(value, 'firstName')
                                        }
                                        hasError={
                                            errors.firstName &&
                                            touched.firstName
                                                ? errors.firstName
                                                : null
                                        }
                                        onInput={(e) => {
                                            saveUserData({
                                                firstName: e.target.value,
                                            });
                                        }}
                                    />
                                    <InputField
                                        storeRef={storeRef}
                                        selectedTimeslot={selectedTimeslot}
                                        label={Content.lastName}
                                        name="lastName"
                                        id="lastName"
                                        value={userData.lastName}
                                        handleChange={handleChange}
                                        isValid={
                                            !errors.lastName && touched.lastName
                                        }
                                        validation={(value) =>
                                            validation(value, 'lastName')
                                        }
                                        hasError={
                                            errors.lastName && touched.lastName
                                                ? errors.lastName
                                                : null
                                        }
                                        onInput={(e) => {
                                            saveUserData({
                                                lastName: e.target.value,
                                            });
                                        }}
                                    />
                                    <InputField
                                        storeRef={storeRef}
                                        selectedTimeslot={selectedTimeslot}
                                        label={Content.email}
                                        name="email"
                                        id="email"
                                        type="email"
                                        value={userData.email}
                                        isValid={!errors.email && touched.email}
                                        handleChange={handleChange}
                                        validation={(value) =>
                                            validation(value, 'email')
                                        }
                                        hasError={
                                            errors.email && touched.email
                                                ? errors.email
                                                : null
                                        }
                                        onInput={(e) => {
                                            saveUserData({
                                                email: e.target.value,
                                            });
                                        }}
                                    />
                                    {(serviceId ===
                                        configData.SERVICE_ID
                                            .GROUP_SERVICE_ID ||
                                        serviceId ===
                                            configData.SERVICE_ID
                                                .GROUP_SERVICE_ID_TEST) && (
                                        <DropDownContainerGroup>
                                            <DropDownHeaderGroup
                                                tabIndex={0}
                                                onClick={toggling}
                                            >
                                                {!selected
                                                    ? content.groupSize
                                                    : selected}{' '}
                                                <DropDownIcon>
                                                    <FontAwesomeIcon
                                                        icon={
                                                            isOpen
                                                                ? Icons.faAngleUp
                                                                : Icons.faAngleDown
                                                        }
                                                        pull="right"
                                                    />
                                                </DropDownIcon>
                                            </DropDownHeaderGroup>
                                            {isOpen && (
                                                <DropDownListContainer>
                                                    <DropDownListGroup>
                                                        {groupSize &&
                                                            groupSize?.map(
                                                                (item) => (
                                                                    <ListItemGroup
                                                                        value={
                                                                            item
                                                                        }
                                                                        key={
                                                                            item
                                                                        }
                                                                        onClick={() =>
                                                                            handleGroupSizeChange(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        <StyledListItemName>
                                                                            {
                                                                                item
                                                                            }
                                                                        </StyledListItemName>
                                                                    </ListItemGroup>
                                                                )
                                                            )}
                                                    </DropDownListGroup>
                                                </DropDownListContainer>
                                            )}
                                        </DropDownContainerGroup>
                                    )}
                                    <TelephoneInput
                                        name="phone"
                                        id="phone"
                                        value={userData.phone}
                                        saveUserData={saveUserData}
                                        userData={userData}
                                        countryCode={countryCode}
                                        validation={userData.phoneValid}
                                        wechatID={wechatID}
                                        language={language}
                                    />

                                    {userData.hasNewsletter !== 'True' && (
                                        <StyledCheckboxLabel>
                                            <StyledInput
                                                type="checkbox"
                                                value={userData.subscription}
                                                defaultChecked={
                                                    userData.subscription
                                                }
                                                name="subscription"
                                                onChange={(e) => {
                                                    saveUserData({
                                                        subscription:
                                                            e.target.checked,
                                                    });
                                                }}
                                            />
                                            <StyledCheckboxText>
                                                {content.signUp}
                                            </StyledCheckboxText>
                                        </StyledCheckboxLabel>
                                    )}

                                    <StyledConfirmationBlock
                                        hasIframeStyle={inIFrame}
                                    >
                                        <h2>{Content.note.title}</h2>
                                        <TextareaField
                                            label={Content.note.label}
                                            name="privateNote"
                                            id="privateNote"
                                            value={userData.privateNote}
                                            handleChange={handleChange}
                                            onInput={(e) => {
                                                saveUserData({
                                                    privateNote: e.target.value,
                                                });
                                            }}
                                        />
                                        {error && <Alert>{error}</Alert>}
                                        <Button
                                            label={Content.btn.confirm}
                                            isDisabled={!userData.phoneValid}
                                            type="submit"
                                            isLoading={loadingReserve}
                                            onClick={() => {
                                                return (
                                                    userData.phoneValid &&
                                                    handleSubmit()
                                                );
                                            }}
                                        />
                                    </StyledConfirmationBlock>
                                </Form>
                            </StyledFormWrapper>
                        )}
                    </Formik>
                </Wrapper>
            </main>
        </>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        error: reducer.error,
        loadingReserve: reducer.loadingReserve,
        storeId: reducer.storeId,
        reservationData: reducer.reservationData,
        selectedTimeslot: reducer.selectedTimeslot,
        storeRef: reducer.storeRef,
        userData: reducer.userData,
        countryCode: reducer.countryCode,
        bookingData: reducer.bookingData,
        waitwhileId: reducer.waitwhileId,
        resourceId: reducer.resourceId,
        serviceId: reducer.serviceId,
        sympaEmployeeId: reducer.sympaEmployeeId,
        selectedEmployee: reducer.selectedEmployee,
        wechatID: reducer.wechatID,
        language: reducer.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        postUserData: (payload) =>
            dispatch({ type: actionTypes.POST_USER_DATA, payload }),
        saveUserData: (payload) =>
            dispatch({ type: actionTypes.SAVE_USER_DATA, payload }),
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};

UserDataScreen.propTypes = {
    error: PropTypes.string,
    loadingReserve: PropTypes.bool.isRequired,
    postUserData: PropTypes.func.isRequired,
    reservationData: PropTypes.shape({
        authToken: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.object),
    }),
    saveUserData: PropTypes.func.isRequired,
    selectedTimeslot: PropTypes.string,
    storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    storeRef: PropTypes.string,
    userData: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        lastServedBy: PropTypes.string,
        phone: PropTypes.string,
        privateNote: PropTypes.string,
        phoneValid: PropTypes.bool,
        subscription: PropTypes.bool,
        hasNewsletter: PropTypes.bool,
    }).isRequired,
    countryCode: PropTypes.string.isRequired,
    selectedEmployee: PropTypes.string.isRequired,
    bookingData: PropTypes.shape({
        date: PropTypes.string,
        time: PropTypes.string,
        dateTimeUtc: PropTypes.string,
        storeid: PropTypes.string,
        personId: PropTypes.string,
    }).isRequired,
    waitwhileId: PropTypes.string.isRequired,
    resourceId: PropTypes.string.isRequired,
    serviceId: PropTypes.string.isRequired,
    sympaEmployeeId: PropTypes.string.isRequired,
    wechatID: PropTypes.string,
    
};

UserDataScreen.defaultProps = {
    error: null,
    reservationData: null,
    storeId: null,
    storeRef: null,
    selectedTimeslot: null,
    wechatID: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDataScreen);
