import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { StoreLocatorWrapper } from 'screens/storeLocator/style';
import { actionTypes } from 'redux/actions';
import { Config, Utils } from 'scripts';
import { Loader } from 'components/loader';
import BackButton from 'components/backButton';
import PropTypes from 'prop-types';
import content from 'content';
import ServicePickerHeader from './components/ServicePickerHeader';
import ServicesList from './components/ServicesList';

const urlParams = new Utils.Url(window.location.href).query;

const sendEventMessage = () => {
    window.parent.postMessage('closeRadicalPersonalModal', '*');
};

const ServicePicker = ({
    storeRef,
    setState,
    reroute,
    loadingDatesAndTimeslots,
    loadingTimeslots,
    storeId,
    wechatID,
}) => {
    const services =
        window.location.hostname === 'suitsupply.com' ||
        window.location.hostname === 'appointment.suitsupply.com'
            ? Config.getServices()
            : Config.getServicesTest();

    const handleGoBack = () => {
        reroute(Config.ROUTES.APP_TYPE);
    };
    const [isWeChat, setIsWeChat] = useState(true);
    const formatStoreRef = (ref) => {
        return ref.split(' ').join('');
    };
    useEffect(() => {
        // Reroute to store locator if no store is provided

        if (!storeId && !urlParams?.storeId && !urlParams?.storeRef) {
            reroute('/');
        }
        if (
            (urlParams &&
                urlParams?.serviceId ===
                    Config.CONSUMER_SERVICES.WEBSTORE_INDIVIDUAL_PRD) ||
            urlParams?.serviceId ===
                Config.CONSUMER_SERVICES.WEBSTORE_INDIVIDUAL_TEST
        ) {
            setState({ storeRef: formatStoreRef(urlParams.storeRef) });
            setIsWeChat((current) => !current);
        }
        if (urlParams?.storeId) {
            setState({ storeId: urlParams?.storeId });
        }
        if (urlParams?.employeeId) {
            setState({ storeRef: formatStoreRef(urlParams?.storeRef) });
            setIsWeChat(false);
        }
        if (urlParams?.wechatID) {
            setState({ wechatID: urlParams.wechatID });
        }
        if (wechatID) {
            setState({ renderSecondScreen: false });
            setState({ selectedSalesRep: null });
        }
        setState({ servicePicker: true });
    }, [urlParams]);
    return (
        <>
            {isWeChat && <BackButton onClick={handleGoBack} />}
            {!isWeChat && <BackButton onClick={sendEventMessage} />}

            <StoreLocatorWrapper>
                {loadingDatesAndTimeslots && <Loader isBlack />}
                {loadingTimeslots && <Loader isBlack />}
                <ServicePickerHeader />
                <ServicesList services={services} storeRef={storeRef} />
            </StoreLocatorWrapper>
        </>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        storeRef: reducer.storeRef,
        employees: reducer.employees,
        loadingDatesAndTimeslots: reducer.loadingDatesAndTimeslots,
        loadingTimeslots: reducer.loadingTimeslots,
        storeId: reducer.storeId,
        wechatID: reducer.wechatID,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        reroute: (payload) => dispatch(push(payload)),
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};

ServicePicker.propTypes = {
    reroute: PropTypes.func.isRequired,
    storeRef: PropTypes.string.isRequired,
    loadingDatesAndTimeslots: PropTypes.bool.isRequired,
    loadingTimeslots: PropTypes.bool.isRequired,
    setState: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(ServicePicker);
