import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { actionTypes } from 'redux/actions';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Config, Utils } from 'scripts';
import PropTypes from 'prop-types';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { history } from 'redux/store';
import {
    ServiceItemWrapper,
    StyledServiceItemName,
    StyledIcon,
} from '../style';

const urlParams = new Utils.Url(window.location.href).query;

const Service = ({ name, serviceId, setState, storeId, wechatID,language }) => {
    const storeIdUrlParam = storeId ? `&storeId=${storeId}` : '';
    const wechatIdUrlParam = wechatID ? `&wechatID=${wechatID}` : '';
    const handleClick = () => {
        if (urlParams && urlParams.employeeId) {
            setState({ employeeAlreadySelected: true });
            setState({ selectedSalesRep: urlParams.employeeId });
            setState({ sympaEmployeeId: urlParams.sfscEmployeeId });
            setState({ storeId: urlParams.storeId });
            setState({ storeRef: urlParams.storeRef });
            setState({ employeeAlreadySelected: true });
        }
        setState({ appLoading: true });
        history.push(
            `${Config.ROUTES.BOOKING}?serviceId=${serviceId}${storeIdUrlParam}${wechatIdUrlParam}&language=${language||'en'}`
        );
        setState({ serviceId });
    };
    return (
        <ServiceItemWrapper htmlFor={name} onClick={handleClick}>
            <StyledServiceItemName>{name}</StyledServiceItemName>
            <StyledIcon>
                <FontAwesomeIcon icon={Icons.faAngleRight} />
            </StyledIcon>
        </ServiceItemWrapper>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        storeRef: reducer.storeRef,
        employeeAlreadySelected: reducer.employeeAlreadySelected,
        resourceId: reducer.resourceId,
        storeId: reducer.storeId,
        sfscEmployeeId: reducer.sympaEmployeeId,
        wechatID: reducer.wechatID,
        language: reducer.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getEmployeeList: (payload) =>
            dispatch({
                type: actionTypes.GET_EMPLOYEE_LIST,
                payload,
            }),
        init: (payload) => ({
            type: actionTypes.INIT,
            payload,
        }),
        reroute: (payload) => dispatch(push(payload)),
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};

Service.propTypes = {
    reroute: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    serviceId: PropTypes.string.isRequired,
    setState: PropTypes.func.isRequired,
    storeId: PropTypes.string.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(Service);
