import React from 'react'

import Service from './Service';
import { connect } from 'react-redux';


const ServicesList = ({services,employeeAlreadySelected}) => {

  //To update when we have API endpoint for employee services
  return (
    !employeeAlreadySelected ?
    services.map(service=>(
        <Service name={service.name} key={service.name} serviceId={service.serviceId} />
    )): 
    services.map(service=>(
        <Service name={service.name} key={service.name} serviceId={service.serviceId} />
  ))
  )
}
const mapStateToProps = ({ reducer }) => {
  return {
      storeRef: reducer.storeRef,
      employeeAlreadySelected: reducer.employeeAlreadySelected,
      
  };
};
export default connect(mapStateToProps)(ServicesList)