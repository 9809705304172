import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getWrapperStyle } from './style';

const StyledWrapper = styled.div(getWrapperStyle);

const verticalAlignStyle = {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};

export const Wrapper = ({ children, isSmall, isVerticalAligned }) => {
    return (
        <StyledWrapper
            isSmall={isSmall}
            style={isVerticalAligned ? verticalAlignStyle : {}}
        >
            {children}
        </StyledWrapper>
    );
};

Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
    isSmall: PropTypes.bool,
    isVerticalAligned: PropTypes.bool,
};

Wrapper.defaultProps = {
    isSmall: false,
    isVerticalAligned: false,
};
