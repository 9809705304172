import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getAppContainerStyle } from './style';

const StyledAppContainer = styled.div(getAppContainerStyle);

function useWindowSize() {
    const [vh, setVh] = useState(0);

    useEffect(() => {
        function handleResize() {
            if (vh !== 0) {
                setVh(window.innerHeight * 0.01);
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            }
        }
        window.addEventListener('load', handleResize);

        handleResize();

        return () => window.removeEventListener('load', handleResize);
    }, [vh]);

    return vh;
}

export const AppContainer = ({ children }) => {
    useWindowSize();
    return <StyledAppContainer>{children}</StyledAppContainer>;
};

AppContainer.propTypes = {
    children: PropTypes.node.isRequired,
};
