import React, { Component } from 'react';
import { Analytics } from 'scripts';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { ValidIcon, ErrorIcon } from 'icons';
import {
    StyledFormItem,
    StyledLabel,
    StyledInputWrapper,
    StyledError,
} from '../style';

export class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = { isFloating: false };
        const { value } = this.props;
        if (value) this.state = { isFloating: true };
    }

    componentDidUpdate(newProps) {
        const { selectedTimeslot, storeRef } = this.props;
        const oldProps = this.props;
        if (oldProps.hasError !== newProps.hasError) {
            Analytics({
                type: 'FormError',
                label: `${storeRef}|${
                    selectedTimeslot && selectedTimeslot.replace(':', '')
                }`,
            });
        }
    }

    render() {
        const {
            hasError,
            id,
            isValid,
            label,
            name,
            value,
            placeholder,
            validation,
            onInput,
            onFocus,
            onBlur,
            handleChange,
        } = this.props;

        const { isFloating } = this.state;

        const floatLabel = (val) => {
            if (!val) {
                this.setState({ isFloating: false });
            } else {
                this.setState({ isFloating: true });
            }
        };

        return (
            <StyledFormItem>
                <StyledInputWrapper hasError={hasError}>
                    <Field
                        id={id}
                        name={name}
                        validate={validation}
                        onInput={onInput}
                        value={value}
                        placeholder={placeholder}
                        onFocus={onFocus}
                        onKeyDown={(e) => {
                            if (
                                (e.keyCode === 8 || e.keyCode === 46) &&
                                name === 'phone' &&
                                e.currentTarget.value.length <= 1
                            ) {
                                e.preventDefault();
                            }
                        }}
                        onKeyUp={(e) => {
                            if (
                                name === 'phone' &&
                                e.currentTarget.value.length <= 1 &&
                                e.currentTarget.value !== '+'
                            ) {
                                e.currentTarget.value = '+';
                            }
                        }}
                        onBlur={(e) => {
                            handleChange(e);
                            floatLabel(e.currentTarget.value);
                            onBlur();
                        }}
                        onChange={(e) => {
                            handleChange(e);
                            floatLabel(e.currentTarget.value);
                        }}
                    />
                    <StyledLabel htmlFor={id} isFloating={isFloating}>
                        {label}
                    </StyledLabel>
                    {isValid && <ValidIcon />}
                    {hasError && <ErrorIcon />}
                </StyledInputWrapper>

                {hasError && <StyledError>{hasError}</StyledError>}
            </StyledFormItem>
        );
    }
}

InputField.propTypes = {
    hasError: PropTypes.string,
    id: PropTypes.string,
    isValid: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
    onInput: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    selectedTimeslot: PropTypes.string,
    storeRef: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    validation: PropTypes.func,
    handleChange: PropTypes.func,
};

InputField.defaultProps = {
    hasError: null,
    id: null,
    isValid: false,
    name: null,
    value: null,
    onInput: () => {},
    onBlur: () => {},
    selectedTimeslot: null,
    storeRef: null,
    type: null,
    validation: () => {},
    handleChange: () => {},
};
