import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { actionTypes, setState } from 'redux/actions';
import { connect } from 'react-redux';
import { Config, Utils } from 'scripts';
import { history } from 'redux/store';

import BackButton from 'components/backButton';

import { getHeaderStyle } from './style';

const StyledHeader = styled.header(getHeaderStyle);

const HeaderIframe = ({
    goBackToBooking,
    title,
    storeId,
    reservationData,
    isGrey,
}) => {
    const inIFrame = Utils.inIFrame();

    return (
        <StyledHeader isGrey={isGrey}>
            {history &&
                history.location &&
                history.location.pathname === Config.ROUTES.USER_DATA && (
                    <BackButton
                        onClick={() => {
                            goBackToBooking({
                                storeId,
                                itemId:
                                    reservationData &&
                                    reservationData.items &&
                                    reservationData.items[0].itemId,
                                ClientAuthToken:
                                    reservationData &&
                                    reservationData.authToken,
                                renderSecondScreen: true,
                            });
                            setState({
                                preSelectedTimeslot: null,
                            });
                        }}
                        inIframe={inIFrame}
                    />
                )}

            <h1>{title}</h1>

            {/* {!isStoreLocator && (
                <CloseButton
                    onClick={() => {
                        window.parent.postMessage(
                            'closeRadicalPersonalModal',
                            configData.TARGET_ORIGIN.parent
                        );
                    }}
                >
                    <span className="sr-only">
                        {content.a11yHeaderBtnClose}
                    </span>

                    <CloseIcon />
                </CloseButton>
            )} */}
        </StyledHeader>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        storeId: reducer.storeId,
        reservationData: reducer.reservationData,
        preSelectedTimeslot: reducer.preSelectedTimeslot,
        selectedTimeslot: reducer.selectedTimeslot,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        goBackToBooking: (payload) =>
            dispatch({ type: actionTypes.GO_BACK_BOOKING, payload }),
    };
};

HeaderIframe.propTypes = {
    goBackToBooking: PropTypes.func.isRequired,
    reservationData: PropTypes.shape({
        authToken: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.object),
    }),
    storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string.isRequired,
    isGrey: PropTypes.bool,
};

HeaderIframe.defaultProps = {
    reservationData: null,
    storeId: null,
    isGrey: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderIframe);
