export default class Url {
    constructor(url = null) {
        if (!url) {
            return false;
        }
        this.url = String(url);
        return Url.get(url);
    }

    static get(url) {
        const sUrl = url.toString();

        const parserRe = /^((\w+?):)?((\/\/)?(([^@]+)@)?(\[[0-9a-f:]+\]|[^/|:]+)(:(\d+))?)?([^?#]+)?(\?[^#]*)?(#(.+)?)?$/;
        const [
            href,
            protocol,
            scheme,
            authority,
            ,
            ,
            userinfo,
            host,
            ,
            port,
            pathname,
            search,
            hash,
        ] = parserRe.exec(sUrl);
        const [username, password] = (userinfo && userinfo.split(':')) || [];
        const [, , locale] =
            /^\/(ibe\/)?([a-zA-Z]{2,2})\//.exec(pathname) || [];

        // 0: "https://username:pass@htte:80/qsmdk/qsdqsd?query=a&b=%20c&d=true&e#hashe"
        // 1: "https:"
        // 2: "https"
        // 3: "//username:pass@htte:80"
        // 4: "username:pass@"
        // 5: "username:pass"
        // 6: "htte"
        // 7: ":80"
        // 8: "80"
        // 9: "/qsmdk/qsdqsd"
        // 10: "?query=a&b=%20c&d=true&e"
        // 11: "#hashe"
        // 12: "hashe"

        return {
            authority,
            hash,
            host,
            href,
            locale,
            pathname,
            port,
            protocol,
            query: Url.getSearchParams(search),
            scheme,
            search,
            username,
            password,
        };
    }

    static getSearchParams(querystring) {
        if (!querystring) {
            return undefined;
        }
        const getSearchParams = {};

        querystring.replace(
            /(?:\?|&(?:amp;)?)([^=&#]+)(?:=?([^&#]*))/g,
            (fullPattern, key, value) =>
                Object.assign(getSearchParams, {
                    [key]: decodeURIComponent(value),
                })
        );
        return getSearchParams;
    }
}
