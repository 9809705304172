import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { actionTypes } from 'redux/actions';
import { history } from 'redux/store';
import PropTypes from 'prop-types';
import { Config } from 'scripts';
import content from 'content';
import configData from '../../../../config.json';
import {
    AppointmentTypeWrapper,
    StyledAppointmentTypeContainer,
    StyledAppointmentTypeDescription,
    StyledAppointmentTypeName,
    StyledIcon,
} from '../style';

const AppointmentType = ({ type, setState, storeId }) => {
    const groupId =
        window.location.hostname === 'suitsupply.com' ||
        window.location.hostname === 'appointment.suitsupply.com'
            ? configData.SERVICE_ID.GROUP_SERVICE_ID
            : configData.SERVICE_ID.GROUP_SERVICE_ID_TEST;
    const handlePickAppointment = () => {
        if (type.id === 1) {
            history.push(
                `${Config.ROUTES.SERVICES}?app-type=individual&storeId=${storeId}`
            );
            setState({ groupAppointment: false });
        } else {
            setState({ serviceId: groupId });
            setState({ groupAppointment: true });
            history.push(
                `${Config.ROUTES.BOOKING}?app-type=group&storeId=${storeId}`
            );
        }
    };

    return (
        <AppointmentTypeWrapper onClick={handlePickAppointment}>
            <StyledAppointmentTypeContainer>
                <StyledAppointmentTypeName>
                    {type.name}
                </StyledAppointmentTypeName>
                <StyledAppointmentTypeDescription>
                    {type.description1}
                </StyledAppointmentTypeDescription>
                <StyledAppointmentTypeDescription>
                    {type.description2}
                </StyledAppointmentTypeDescription>
            </StyledAppointmentTypeContainer>
            <StyledIcon>
                <FontAwesomeIcon icon={Icons.faAngleRight} />
            </StyledIcon>
        </AppointmentTypeWrapper>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        storeRef: reducer.storeRef,
        storeId: reducer.storeId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};

AppointmentType.propTypes = {
    type: PropTypes.shape({
        name: PropTypes.string,
        description1: PropTypes.string,
        description2: PropTypes.string,
        id: PropTypes.number,
    }).isRequired,
    setState: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentType);
