import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import content from 'content';
import SEO from 'components/seo';
import { actionTypes } from 'redux/actions';
import Header from 'components/layout/header';
import { Wrapper } from 'components/layout/wrapper';

const ErrorScreen = () => {
    return (
        <>
            <SEO title={content.metaData.pageNotFoundTitle} />
            <Header />

            <main>
                <Wrapper isSmall isVerticalAligned>
                    <h1>{content.errorPageHeader}</h1>
                    <p>{content.errorPageContent}</p>
                </Wrapper>
            </main>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: (payload) => dispatch({ type: actionTypes.INIT, payload }),
        reroute: (payload) => dispatch(push(payload)),
        selectDate: (payload) =>
            dispatch({ type: actionTypes.SELECT_DATE, payload }),
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};

export default connect(null, mapDispatchToProps)(ErrorScreen);
