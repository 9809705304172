import React from 'react';
import styled from 'styled-components';
import content from 'content';
import PropTypes from 'prop-types';

import { ArrowBackIcon } from 'icons';
import { getBackButtonStyle } from './style';
import { connect } from 'react-redux';

const StyledBackButton = styled.button(getBackButtonStyle);

const BackButton = ({ onClick, wechatID }) => {
    return (
        !wechatID && (
            <StyledBackButton onClick={onClick}>
                <span className="sr-only">{content.a11yHeaderBtnBack}</span>
                <ArrowBackIcon />
            </StyledBackButton>
        )
    );
};

BackButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    inIframe: PropTypes.bool,
    wechatID: PropTypes.string,
};

BackButton.defaultProps = {
    inIframe: false,
    wechatID: null,
};
const mapStateToProps = ({ reducer }) => {
    return {
        wechatID: reducer.wechatID,
    };
};

export default connect(mapStateToProps)(BackButton);
