import { mq } from 'styles/mediaqueries';
import { grey } from 'styles/variables';
import { sharedBackButtonStyle } from '../../backButton/style';

export const closeButton = {
    ...sharedBackButtonStyle,
    left: 'auto',
    right: 0,
    zIndex: 20,
};

export const getHeaderStyle = ({ isGrey }) => {
    return {
        backgroundColor: 'white',
        borderBottom: `1px solid ${grey}`,
        position: 'relative',
        width: '100%',
        height: 'auto',
        minHeight: 44,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 45px',
        boxSizing: 'border-box',
        h1: {
            fontSize: '1.4rem',
            fontWeight: 'normal',
            display: 'none',
        },

        '+ main': {
            height: 'calc(100% - 44px)',
        },

        [mq[0]]: {
            backgroundColor: 'white',
            borderBottom: 0,
            padding: '0 45px',

            h1: {
                fontSize: isGrey ? '2.4rem' : '1.4rem',
            },

            '+ main': {
                height: 'calc(100% - 118px)',
            },
        },
    };
};
