import BackButton from 'components/backButton';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Config, Utils } from 'scripts';
import PropTypes from 'prop-types';
import { actionTypes } from 'redux/actions';
import AppointmentTypeHeader from './components/AppointmentTypeHeader';
import AppointmentTypeList from './components/AppointmentTypeList';

const urlParams = new Utils.Url(window.location.href).query;

const AppointmentTypeScreen = ({ reroute, storeRef, setState }) => {
    const appointmentTypes = Config.getAppointmentTypes();
    const handleGoBack = () => {
        reroute(Config.ROUTES.STORE_LOCATOR);
    };

    useEffect(() => {
        // Reroute to store locator if no store is provided
        if (!storeRef && !urlParams?.storeId) {
            reroute('/');
        }
        if (urlParams?.wechatID) {
            setState({ wechatID: urlParams.wechatID });
        }
        if (urlParams?.storeId) {
            setState({ storeId: urlParams?.storeId });
        }
        if (urlParams?.language) {
            setState({ language: urlParams?.language });
        }
    }, []);
    return (
        <>
            <BackButton onClick={handleGoBack} />
            <AppointmentTypeHeader />
            <AppointmentTypeList appointmentTypes={appointmentTypes} />
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        reroute: (payload) => dispatch(push(payload)),
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};
const mapStateToProps = ({ reducer }) => {
    return {
        storeRef: reducer.storeRef,
        wechatID: reducer.wechatID,
        language: reducer.language,
    };
};

AppointmentTypeScreen.propTypes = {
    reroute: PropTypes.func.isRequired,
    storeRef: PropTypes.string.isRequired,
    setState: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppointmentTypeScreen);
