export default function shuffleEmployees(employees,lastFittingId)
{
    let orderValues=employees.map(ar=>ar.order||0);
    let maxEmployeeOrderValue=Math.max(...orderValues);
        
        return employees.map(employee=>{
            if(employee.employeeId==lastFittingId)
            {
                employee.order=0;
                employee.lastFitting = true
                return employee;
            }
            employee.order=employee.order || maxEmployeeOrderValue+Math.random();
            return employee;
        }).sort(compareOrderNumbers);
}
function compareOrderNumbers(a,b)
{
    return a.order-b.order;
}