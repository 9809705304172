import content from 'content';

const SHOW_DAYS_NUMBER = 14;
const LAZY_LOAD_DAYS_NUMBER = 7;
const NO_PREF_LAZY_LOAD_NUMBER = 5;

let API_URL = `${window.location.origin}/api`;

if (process.env.NODE_ENV === 'development') {
    API_URL = 'https://radicalpersonal-tst-web.azurewebsites.net/api';
    // API_URL = 'https://radicalpersonal-prd-web.azurewebsites.net/api';
}

const getEppUrl = () => {
    switch (window.location.hostname) {
        case 'appointment.suitsupply.com':
        case 'radicalpersonal-prd-web.azurewebsites.net':
            return 'https://expert.suitsupply.com/';

        case 'acc.appointment.suitsupply.com':
        case 'radicalpersonal-acc-web.azurewebsites.net':
            return 'https://acc.expert.suitsupply.com/';

        case 'tst.appointment.suitsupply.com':
        case 'radicalpersonal-tst-web.azurewebsites.net':
            return 'https://tst.expert.suitsupply.com/';

        default:
            return 'http://localhost:3003/';
    }
};
function getAppointmentTypes() {
    const appointmentTypes = [
        {
            id: 1,
            name: content.individualAppointment,
            description1: content.individualDescription1,
            description2: content.individualDescription2,
        },
        {
            id: 2,
            name: content.groupAppointment,
            description1: content.groupDescription1,
            description2: content.groupDescription2,
        },
    ];
    return appointmentTypes;
}

const CONSUMER_SERVICES = {
    WEBSTORE_INDIVIDUAL_TEST: '49150',
    WEBSTORE_INDIVIDUAL_PRD: '49031',
    WEBSTORE_GROUP_TEST: '49090',
    WEBSTORE_GROUP_PRD: '48973',
    SIZE_PASSPORT: '50051',
};
const SERVICES = [
    { name: content.suits, serviceId: '0TQwsgczQenqHWbMWfCh' },
    { name: content.customMade, serviceId: 'zwEIh1eOWcftQOUcs49q' },
    { name: content.casual, serviceId: 'Z1PFM717JkUNYG7eVDEk' },
    { name: content.pickUp, serviceId: 'K8pVvdz1gMkPx99aIDOu' },
    { name: content.consultation, serviceId: 'z35KSWQggUGS5vSTmIvn' },
    { name: content.notSureYet, serviceId: 'bwyVcodriOY7DUXx5KPV' },
];
const SERVICES_TEST = [
    { name: content.suits, serviceId: '1Tml8xUuhxCgk5IIg6Gw' },
    { name: content.customMade, serviceId: 'bo4PQVYmfBTINf6embx2' },
    { name: content.casual, serviceId: 'iuEezqbdTM54vQVdWylG' },
    { name: content.pickUp, serviceId: 'VNM7HNhar2L30KPsgze1' },
    { name: content.consultation, serviceId: '9eAciKvffgZvme3CrFVy' },
    { name: content.notSureYet, serviceId: 'jCEcsgQngYK00dUZRO62' },
];

const getServices = () => {
    const services = [
        { name: content.suits, serviceId: '0TQwsgczQenqHWbMWfCh' },
        { name: content.customMade, serviceId: 'zwEIh1eOWcftQOUcs49q' },
        { name: content.casual, serviceId: 'Z1PFM717JkUNYG7eVDEk' },
        { name: content.pickUp, serviceId: 'K8pVvdz1gMkPx99aIDOu' },
        { name: content.consultation, serviceId: 'z35KSWQggUGS5vSTmIvn' },
        { name: content.notSureYet, serviceId: 'bwyVcodriOY7DUXx5KPV' },
    ];
    return services;
};

const getServicesTest = () => {
    const services = [
        { name: content.suits, serviceId: '1Tml8xUuhxCgk5IIg6Gw' },
        { name: content.customMade, serviceId: 'bo4PQVYmfBTINf6embx2' },
        { name: content.casual, serviceId: 'iuEezqbdTM54vQVdWylG' },
        { name: content.pickUp, serviceId: 'VNM7HNhar2L30KPsgze1' },
        { name: content.consultation, serviceId: '9eAciKvffgZvme3CrFVy' },
        { name: content.notSureYet, serviceId: 'jCEcsgQngYK00dUZRO62' },
    ];
    return services;
};
const BEIJING_LINK =
    'https://suitsupply.com/zh-cn/stores-detail?locale=zn-cn&storeID=stores-Beijing';
const RESCHEDULE_LINKS = {
    PRODUCTION:
        'https://appointment.suitsupply.com/booking/?booking=reschedule',
    TEST: 'https://acc.appointment.suitsupply.com/booking/?booking=reschedule',
};

const CANCEL_LINKS = {
    PRODUCTION: 'https://appointment.suitsupply.com/booking/?booking=cancel',
    TEST: 'https://acc.appointment.suitsupply.com/booking/?booking=cancel',
};

const SERVICE_NAME = (id) => {
    const selectedService =
        window.location.hostname === 'suitsupply.com' ||
        window.location.hostname === 'appointment.suitsupply.com'
            ? SERVICES.find((service) => id === service.serviceId)
            : SERVICES_TEST.find((service) => id === service.serviceId);

    return selectedService?.name;
};

const EPP_URL = getEppUrl();

const MODES = {
    BOOKING: 'booking',
    RESCHEDULE: 'reschedule',
    CANCEL: 'cancel',
    STORE_LOCATOR: 'store-locator',
};

const UTM = {
    emailAlteradionReady:
        '?utm_source=ExactTarget&utm_medium=bookingtool&utm_campaign=AlterationReady',
    emailTryAtStore:
        '?utm_source=ExactTarget&utm_medium=bookingtool&utm_campaign=TryAtStore',
    emailCustomMade:
        '?utm_source=ExactTarget&utm_medium=bookingtool&utm_campaign=CustomMade',
    iFrame: '?utm_source=storepage&utm_medium=bookingtool&utm_campaign=employee',
};

const MOBILE = 992;
const IFRAMEWIDTH = 440;

const DAY_TIMES = {
    morning: {
        min: 1,
        max: 12,
    },
    afternoon: {
        min: 12,
        max: 17,
    },
    evening: {
        min: 17,
        max: 23,
    },
};

const DEFAULT_STORE_REF = 'amsterdam';

const ROUTES = {
    BOOKING: '/booking',
    USER_DATA: '/user-data',
    CONFIRM: '/confirm',
    CANCEL: '/cancel',
    STORE_LOCATOR: '/',
    SERVICES: '/services',
    APP_TYPE: '/app-type',
    CONFIRMATION: '/confirmation',
};

export default {
    SHOW_DAYS_NUMBER,
    API_URL,
    DAY_TIMES,
    ROUTES,
    MODES,
    MOBILE,
    IFRAMEWIDTH,
    DEFAULT_STORE_REF,
    LAZY_LOAD_DAYS_NUMBER,
    EPP_URL,
    NO_PREF_LAZY_LOAD_NUMBER,
    UTM,
    SERVICES,
    SERVICES_TEST,
    CONSUMER_SERVICES,
    RESCHEDULE_LINKS,
    CANCEL_LINKS,
    SERVICE_NAME,
    getAppointmentTypes,
    getServices,
    getServicesTest,
    BEIJING_LINK,
};
