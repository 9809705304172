/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
    ReactPlugin,
    withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';

import BookingScreenEmployee from 'screens/bookingEmployee/bookingScreenEmployee';
import UserDataScreen from 'screens/userData/userDataScreen';
import ConfirmationScreen from 'screens/confirm/confirmationScreen';
import CancelScreen from 'screens/cancel/cancelScreen';
import ErrorScreen from 'screens/error/errorScreen';

import SEO from 'components/seo';
import { AppContainer } from 'components/layout/container';
import { actionTypes, changeContext } from 'redux/actions';
import { Config, Utils } from 'scripts';
import GlobalFonts from 'fonts/fonts';
import ServicePicker from 'screens/servicePicker/ServicePicker';
import WeChatConfirmation from 'screens/weChatConfirmation/WeChatConfirmation';
import AppointmentTypeScreen from 'screens/bookingEmployee/appointmentType/AppointmentTypeScreen';
import content from 'content';
import { history } from './redux/store';
import configData from './config.json';
import GlobalStyle from './styles/global';
import StoreLocator from './screens/storeLocator/StoreLocator';

const reactPlugin = new ReactPlugin();

const AppInstrumentationKey =
    window.location.hostname === 'suitsupply.com' ||
    window.location.hostname === 'appointment.suitsupply.com'
        ? configData.APP_KEY.instrumentationKey
        : configData.APP_KEY.instrumentationKeyTest;

const clientId =
    window.location.hostname === 'suitsupply.com' ||
    window.location.hostname === 'appointment.suitsupply.com'
        ? configData.CLIENT_ID.prod
        : configData.CLIENT_ID.test;

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: AppInstrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history },
        },
    },
});

appInsights.loadAppInsights();

const telemetryInitializer = (envelope) => {
    // eslint-disable-next-line no-param-reassign
    envelope.data.client_id = clientId;
};
appInsights.addTelemetryInitializer(telemetryInitializer);
const urlParams = new Utils.Url(window.location.href).query;
class App extends Component {
    // retrieve available dates
    componentDidMount = () => {
        const { setState } = this.props;
        if (urlParams?.booking === Config.MODES.RESCHEDULE) {
            changeContext({ mode: urlParams?.booking });
            setState({ renderSecondScreen: true });
            setState({ isRescheduleScreen: true });
        }

        if (
            (urlParams?.serviceId ===
                Config.CONSUMER_SERVICES.WEBSTORE_GROUP_PRD ||
                urlParams?.serviceId ===
                    Config.CONSUMER_SERVICES.WEBSTORE_GROUP_TEST) &&
            !urlParams?.storeRef
        ) {
            setState({ renderSecondScreen: true });
        }
        if (
            urlParams?.wechatID ||
            urlParams?.language === 'zh-cn' ||
            urlParams?.locale === 'zh-cn'
        ) {
            setState({language:'zh-cn'})
            content.setLanguage('ch');
        }

        window.dataLayer.push({ country: 'NL' }, { language: 'EN' });
    };

    render() {
        return (
            <Router>
                <ConnectedRouter history={history}>
                    <GlobalFonts />
                    <GlobalStyle />
                    <SEO />

                    <AppContainer>
                        <Switch>
                            <Route path={Config.ROUTES.USER_DATA}>
                                <UserDataScreen />
                            </Route>
                            <Route path={Config.ROUTES.CONFIRM}>
                                <ConfirmationScreen />
                            </Route>
                            <Route path={Config.ROUTES.CANCEL}>
                                <CancelScreen />
                            </Route>

                            <Route path={Config.ROUTES.BOOKING}>
                                <BookingScreenEmployee />
                            </Route>

                            <Route path={Config.ROUTES.APP_TYPE}>
                                <AppointmentTypeScreen />
                            </Route>
                            <Route path={Config.ROUTES.CONFIRMATION}>
                                <WeChatConfirmation />
                            </Route>
                            <Route path={Config.ROUTES.SERVICES}>
                                <ServicePicker />
                            </Route>

                            <Route exact path={Config.ROUTES.STORE_LOCATOR}>
                                <StoreLocator />
                            </Route>
                            <Route>
                                <ErrorScreen path="error" />
                            </Route>
                        </Switch>
                    </AppContainer>
                </ConnectedRouter>
            </Router>
        );
    }
}

const mapStateToProps = ({ reducer }) => {
    return {
        context: reducer.context,
        bookingToolNumberOfDays: reducer.bookingToolNumberOfDays,
        serviceId: reducer.serviceId,
        description: reducer.description,
        storeLocator: reducer.storeLocator,
        servicePicker: reducer.servicePicker,
        employeeAlreadySelected: reducer.employeeAlreadySelected,
        fromWeChat: reducer.fromWeChat,
        isRescheduleScreen: reducer.isRescheduleScreen,
        renderSecondScreen: reducer.renderSecondScreen,
        isFromEpp: reducer.isFromEpp,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: (payload) => dispatch({ type: actionTypes.INIT, payload }),
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
    };
};

App.propTypes = {
    context: PropTypes.shape({
        mode: PropTypes.string,
    }).isRequired,
    setState: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withAITracking(reactPlugin, App));
