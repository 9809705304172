import {
    gutter,
    text,
    greymid,
    textDisabled,
    brandColor,
    disabledBg,
    dustyGrey,
} from 'styles/variables';
import { mq } from 'styles/mediaqueries';
import styled from 'styled-components';

export const getTimeslotsStyle = ({ availableTimeslots }) => {
    return {
        padding: `${gutter}px 0 0 0`,
    };
};

export const getTimeslotsContainerStyle = () => {
    return {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        padding: 0,

        listStyleType: 'none',

        [mq[0]]: {
            margin: '33px 42px 0',
        },

        [mq[1]]: {
            margin: '30px -94px',
            minHeight: 165,
        },
    };
};

export const getTimeslotListItemStyle = () => {
    return {
        width: '33.3333%',
        margin: '12px 0',
        textAlign: 'center',
    };
};

export const getNoTimeslotContainerStyle = () => {
    return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 48px 20px 48px',

        [mq[0]]: {
            padding: '0 30px 20px 30px',
        },

        [mq[1]]: {
            padding: '0 200px 20px 200px',
        },
    };
};

export const getNoTimeslotHeaderStyle = () => {
    return {
        fontFamily: 'GT America',
        fontSize: '20px',
        fontWeight: '400',
        color: '#2d2e2c',
        lineHeight: '28px',
        margin: '0 0 10px 0',

        [mq[0]]: {
            fontSize: '24px',
            lineHeight: '34px',
            margin: '0 0 10px 0',
        },
    };
};

export const getNoTimeslotContentStyle = () => {
    return {
        fontSize: '15px',
        fontWeight: '300',
        lineHeight: '22px',
        textAlign: 'center',
        paddingBottom: '30px',
    };
};

export const getTimeslotItemStyle = ({
    isDisabled,
    isPreSelected,
    isSelected,
}) => {
    let color = text;
    let border = 0;

    if (isDisabled) {
        color = textDisabled;
    }

    if (isSelected) {
        color = 'white';
    }

    if (isPreSelected) {
        border = `${greymid} 1px solid`;
    }

    return {
        backgroundColor: isSelected ? brandColor : 'transparent',
        border,
        borderRadius: 40,
        color,
        cursor: isDisabled ? 'default' : 'pointer',
        fontSize: '1.6rem',
        outline: 0,
        padding: '6px 15px 7px',
        pointerEvents: isDisabled ? 'none' : 'auto',

        '&:hover, &:focus': {
            backgroundColor: isSelected ? brandColor : disabledBg,
        },
    };
};

// Elements form Employees Timeslot modal (TM)
export const StyledTMCurrentDate = styled.p`
    font-size: 1.3rem;
    color: ${text};
    margin-top: 11px;

    ${[mq[0]]} {
        margin-top: 15px;
    }

    ${[mq[3]]} {
        margin-top: 25px;
    }
`;

export const StyledTMTitle = styled.h2`
    text-align: center;
    margin-top: 38px;
    margin-bottom: 0;

    ${[mq[0]]} {
        margin-top: 102px;
        font-size: 2.4rem;
    }
`;

export const StyledTMTimezone = styled.p`
    font-size: 1.3rem;
    color: ${dustyGrey};
    margin: 5px auto;

    ${[mq[0]]} {
        margin-top: 11px;
    }
`;
