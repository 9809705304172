import {mq} from '../../styles/mediaqueries';
import styled,{ keyframes } from 'styled-components'

export const StyledCircularLoader =styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    background:none;
    height:100%;
    width:100%;
`
    
    
const pulsate = keyframes `
    0% {
        transform: scale(0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2);
        opacity: 0;
}`;
    
export const StyledPulse = styled.div `
    border:solid 3px black;
    border-radius:100%;
    height:2em;
    width:2em;
    animation:${pulsate} 1s ease-in-out infinite;

${[mq[2]]}{
    height:3em;
    width:3em;
}
`


