import React from 'react'
import AppointmentType from './AppointmentType'

const AppointmentTypeList = ({appointmentTypes}) => {
  return (
    appointmentTypes.map(type=>(
        <AppointmentType type={type} key={type.id} />
    ))
    
  )
}

export default AppointmentTypeList