import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Config, Utils } from 'scripts';
import StoreLocatorHeader from './components/StoreLocatorHeader';
import { Button } from '../../components/button/index';
import StoresList from './components/StoresList';
import { StoreLocatorWrapper, StyledHr } from './style';
import { actionTypes } from '../../redux/actions';
import { Loader } from '../../components/loader/index';
import configData from '../../config.json';
import BackButton from '../../components/backButton/index';
import { history } from 'redux/store';

const urlParams = new Utils.Url(window.location.href).query;
const SizePassportServiceId =
    window.location.hostname === 'suitsupply.com' ||
    window.location.hostname === 'appointment.suitsupply.com'
        ? configData.SERVICE_ID.sizePassport
        : configData.SERVICE_ID.sizePassportTst;

const StoreLocator = ({
    setState,
    defaultCountry,
    reroute,
    skipServices,
    storeId,
    getUserInfo
}) => {
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(
        defaultCountry || 'The Netherlands'
    );
    const [isLoading, setIsLoading] = useState(true);
    const [filteredCountry, setFilteredCountry] = useState({});
    const [selected, setSelected] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    const toggling = () => setIsOpen(!isOpen);

    useEffect(() => {
        // Check if coming from size passport
        if (urlParams?.language) {
            setState({ defaultCountry: urlParams && urlParams.language });
        }
        if (
            urlParams &&
            (urlParams?.serviceId === Config.CONSUMER_SERVICES.SIZE_PASSPORT ||
                urlParams?.description === 'sizepassport')
        ) {
            setState({ skipServices: true });
            setState({ serviceId: SizePassportServiceId });
        }
        if (urlParams && urlParams?.wechatID) {
            setState({ wechatID: urlParams.wechatID });
        }
        //bodgy fix for resch-cancel
        const allParams = window.location.search;
        if (
            urlParams?.booking === 'cancel' ||
            urlParams?.booking === 'reschedule'
        ) {
            reroute(`/booking${allParams}`);
        }
        //end of bodgy fix

        //get user info for store locator
        if (urlParams?.customerId && urlParams?.signature) {
            getUserInfo()
        }
        // eslint-disable-next-line no-undef
        fetch(`${Config.API_URL}${configData.API.countriesWithStores}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setCountries(data);
                setIsLoading(false);
            });
    }, []);

    const storeRef1 = 'location=';

    const stripStoreName = (input) => {
        if (!input) {
            return;
        }
        // eslint-disable-next-line consistent-return
        return input.includes(storeRef1)
            ? input.split('location=')[1].replace(/[^a-zA-Z]/g, '')
            : input.split('storeRef=')[1].replace(/[^a-zA-Z]/g, '');
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setSelected(null);
        setIsOpen(false);
        setState({ defaultCountry: country });
    };

    const getStoresFromCountry = () => {
        const filtered = countries.find(
            (country) => country.country === selectedCountry
        );
        setFilteredCountry(filtered);
    };

    const handleInputChange = (e) => {
        setSelected(e.target.value);
        setState({ storeId: e.target.getAttribute('data-storeId') });
    };

    const formatStoreRef = (input) => {
        return input.replace('[^a-zA-Z0-9 -]', '');
    };

    const formatSelected = (input) => {
        return filteredCountry.country === 'Canada'
            ? input.split(/(?=[A-Z])/).join('-')
            : input;
    };

    const getDefaultCountry = () => {
        const findCountry = countries.find((country) => {
            return (
                country?.countryCode.toLowerCase() ===
                defaultCountry?.toLowerCase()
            );
        });

        // eslint-disable-next-line no-unused-expressions
        findCountry && setSelectedCountry(findCountry?.country);
    };

    useEffect(() => {
        getStoresFromCountry();
        getDefaultCountry();
    });

    const contentWindow = window;

    const sendEventMessage = () => {
        contentWindow.parent.postMessage(
            'goBackFromStoreLocator',
            configData.TARGET_ORIGIN.parent
        );
    };

    return (
        <StoreLocatorWrapper>
            <BackButton onClick={sendEventMessage} />
            <StoreLocatorHeader
                countries={countries}
                handleCountryChange={handleCountryChange}
                selectedCountry={selectedCountry}
                isOpen={isOpen}
                toggling={toggling}
            />

            <StoresList
                handleInputChange={handleInputChange}
                countries={countries}
                filteredCountry={filteredCountry}
                isLoading={isLoading}
                getStoresFromCountry={getStoresFromCountry}
                selected={selected}
                stripStoreName={stripStoreName}
                formatSelected={formatSelected}
            />
            <StyledHr />
            {isLoading && <Loader />}
            <Button
                isDisabled={!selected}
                isSticky
                label="Select store"
                onClick={(e) => {
                    e.preventDefault();
                    if (skipServices) {
                        setState({ appLoading: true });
                        history.push(
                            `${Config.ROUTES.BOOKING}?storeId=${storeId}`
                        );
                    } else {
                        history.push(
                            `${Config.ROUTES.APP_TYPE}?storeId=${storeId}`
                        );
                    }

                    setState({
                        isLoading: true,
                    });

                    setState({ description: 'booking' });
                    setState({ storeRef: formatStoreRef(selected) });
                }}
            />
        </StoreLocatorWrapper>
    );
};

const mapStateToProps = ({ reducer }) => {
    return {
        error: reducer.error,
        storeLocator: reducer.storeLocator,
        description: reducer.description,
        storeRef: reducer.storeRef,
        defaultCountry: reducer.defaultCountry,
        serviceId: reducer.serviceId,
        skipServices: reducer.skipServices,
        storeId: reducer.storeId,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setState: (payload) =>
            dispatch({ type: actionTypes.SET_STATE, payload }),
        reroute: (payload) => dispatch(push(payload)),
        getUserInfo: (payload) =>
            dispatch({ type: actionTypes.GET_USER_INFO, payload }),
    };
};

StoreLocator.propTypes = {
    setState: PropTypes.func.isRequired,
    defaultCountry: PropTypes.string,
    reroute: PropTypes.func.isRequired,
    skipServices: PropTypes.bool.isRequired,
};

StoreLocator.defaultProps = {
    defaultCountry: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreLocator);
