import { grey } from 'styles/variables';
import {mq} from '../../../styles/mediaqueries';

export const getHeaderStyle = () => {
    return {
        textAlign: 'center',
        borderBottom: `1px solid ${grey}`,

        '+ main': {
            height: 'calc(100% - 55px)',
        },
    };
};

export const getLinkStyle = () => {
    return {
        display: 'inline-block',
        padding: '12px 10px 10px',
        margin: '0 auto',

        [mq[3]]:{
            padding: '17px 10px 16px',
        }
    };
};
