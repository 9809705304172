import { mq } from 'styles/mediaqueries';
import { text } from '../../styles/variables';
import styled from 'styled-components';
export const getFormWrapperStyle = ({ hasIframeStyle }) => {
    return {
        position: 'relative',
        width: 272,

        [mq[3]]: {
            width: hasIframeStyle ? '272px' : '584px',
        },

        form: {
            width: '100%',

            [mq[3]]: {
                display: hasIframeStyle ? 'block' : 'flex',
                flexWrap: hasIframeStyle ? 'none' : 'wrap',
                justifyContent: hasIframeStyle ? 'none' : 'space-between',
            },
        },

        h2: {
            textAlign: 'center',
            margin: '60px auto 18px',

            [mq[1]]: {
                marginTop: 86,
            },
        },
    };
};

export const getH1Style = ({ hasIframeStyle }) => {
    return {
        display: hasIframeStyle ? 'none' : 'block', // because title is inside HeaderIframe
        margin: hasIframeStyle ? '50px 0 30px' : '0 0 50px ',

        [mq[0]]: {
            display: 'block',
            marginTop: 50,
        },

        [mq[1]]: {
            margin: hasIframeStyle ? '60px auto 40px' : '59px auto ',
        },

        [mq[2]]: {
            margin: hasIframeStyle ? '40px auto' : '59px auto ',
        },
    };
};

export const DropDownListGroup = styled('ul')`
    padding: 0;
    margin: 0;
    padding-left: 1em;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-top:0;
    box-sizing: border-box;
    font-size: 1.5rem;
    border-radius:4px;
    border-top-right-radius:0;
    border-top-left-radius:0;
    font-weight: 300;
    max-height: 465px;
    padding: 6px 0;
    position:absolute;
    z-index:10;
    width:100%;
    ${[mq[3]]} {
        width: 272px;
        
    }
`;
export const DropDownHeaderGroup = styled('div')`
    padding:1px;
    border-bottom: 1px solid #E8E7E5;
    cursor: pointer;
    font-weight:300;
    font-size:1.4rem;
    margin: 0px;
    appearance: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    &:focus {
        outline: none !important;
        
    }
    
`;

export const ListItemGroup = styled('li')`
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    width: 100%;
    list-style-type: none;
    margin-left:3px;
    padding:1px;
    &:hover {
        color: grey;
        cursor: pointer;
    }
`;
export const DropDownContainerGroup = styled('div')`
    margin-bottom: 42px;
    padding-top:5px;
    ${[mq[3]]} {
        width: 272px;
        
    }
    
`;

export const StyledListItemName = styled.p`
    font-weight:300;
    font-size:1.4rem;
    text-align:left;
    padding:3px;
    margin:0;
`;

export const getStyledConfirmationBlock = ({ hasIframeStyle }) => {
    return {
        [mq[3]]: {
            width: '100%',
        },

        textarea: {
            boxSizing: 'border-box',
            resize: 'none',
            overflow: 'auto',
            height: 'auto',

            [mq[3]]: {
                width: hasIframeStyle ? '100%' : '584px',
            },
        },
    };
};

export const StyledInput = styled.input`
    width: 20px;
    height: 20px;
    margin: 0px;
    appearance: none;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #7f7f7f;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
`;

export const StyledCheckboxText = styled.span`
    ${[mq[0]]} {
        width: 250px;
    }
    ${[mq[3]]} {
        width: 450px;
        padding-top: 2px;
    }
    font-size: 14px;
    line-height: 1.25em;
    font-weight: 300;
    color: ${text};
    padding-top: 0px;
    margin-left: 11px;
`;

export const StyledCheckboxLabel = styled.label`
    display: inline-flex;
    position: relative;
    cursor: pointer;
    margin-top: -21px;

    ${StyledInput}:checked + span:before {
        content: '';
        position: absolute;
        height: 11px;
        width: 5px;
        top: 2px;
        left: 7px;
        transform: rotate(40deg);
        border-bottom: 2px solid #2d2e2c;
        border-right: 2px solid #2d2e2c;
    }
`;
