const leadingZero = (n = null) => {
    if (n === null) {
        return '';
    }
    return Number(n) < 10 ? `0${n}` : `${n}`;
};

/**
 * converts a Date to a string
 * @param  {Date} date   [description]
 * @param  {String} format yyyy<sep>mm<sep>dd|dd<sep>mm|dd<sep>mm<sep>yyyy
 * @return {String}        String yyyy<sep>mm<sep>dd|dd<sep>mm|dd<sep>mm<sep>yyyy
 */
const dateToCustomString = (date, format = 'dd/mm/yyyy') => {
    if (!(date instanceof Date)) {
        return null;
    }
    const year = date.getFullYear();
    const month = leadingZero(date.getMonth() + 1);
    const day = leadingZero(date.getDate());

    let matches = format.match(/yyyy([\W_])?mm([\W_])?dd/);
    if (matches && matches.length && matches.length > 0) {
        const [, separator] = matches;
        return separator
            ? `${year}${separator}${month}${separator}${day}`
            : `${year}${month}${day}`;
    }

    matches = format.match(/^dd([\W_])?mm$/);
    if (matches && matches.length && matches.length > 0) {
        const [, separator] = matches;
        return separator ? `${day}${separator}${month}` : `${day}${month}`;
    }

    matches = format.match(/dd([\W_])?mm([\W_])?yyyy/);
    const separator =
        matches && matches.length && matches.length > 0 ? matches[1] : '-';
    return separator
        ? `${day}${separator}${month}${separator}${year}`
        : `${day}${month}${year}`;
};

const addDays = (date, days = 0) => {
    if (Number.isNaN(new Date(date).getTime())) {
        return null;
    }
    const result = new Date(date);
    const increment =
        (!Number.isNaN(days) && Number(days) * 24 * 3600 * 1000) || 0;

    return new Date(result.getTime() + increment);
};

/**
 * Convers a String reprenting a date to a JS date
 * @param  {String} value           date to parse
 * @param  {String} options.locale  @optional locale used for shortMonthDate format
 * @param  {String} options.pattern format of the date to parse 'dd/mm/yyyy'(default)|'yyyy/mm/dd'|'shorMonthDate'
 * @return {Date}                   parsed date
 */
const getDateFromString = (value, pattern = 'dd/mm/yyyy') => {
    let day;
    let month;
    let year;
    if (String(pattern).toLowerCase() === 'dd/mm/yyyy') {
        [, day, month, year] =
            String(value).match(/(\d{2,2})[/.-]?(\d{2,2})[/.-]?(\d{4,4})/) ||
            [];
    }
    if (String(pattern).toLowerCase() === 'yyyy/mm/dd') {
        [, year, month, day] =
            String(value).match(/(\d{4,4})[/.-]?(\d{2,2})[/.-]?(\d{2,2})/) ||
            [];
    }
    if (String(pattern).toLowerCase() === 'yyyy-mm-dd') {
        [, year, month, day] =
            String(value).match(/(\d{4,4})[/.-]?(\d{2,2})[/.-]?(\d{2,2})/) ||
            [];
    }
    return (
        (year && month && day && new Date(year, month - 1, day, 0, 0, 0)) ||
        null
    );
};

export { dateToCustomString, addDays, getDateFromString };
