const getServiceId = (state) =>
    state && state.reducer && state.reducer.serviceId;
const getDescription = (state) =>
    state && state.reducer && state.reducer.description;

const getLanguage = (state) => state && state.reducer && state.reducer.language;

const getLastFittingId = (state) =>
    (state && state.reducer && state.reducer.userData.lastServedBy) || 0;

const getBookingToolAvailableDates = (state) =>
    state && state.reducer && state.reducer.bookingToolNumberOfDays;

const getStoreId = (state) => state && state.reducer && state.reducer.storeId;

const getAvailableTimeslots = (state) =>
    state && state.reducer && state.reducer.availableTimeSlots;

const getTimeslots = (state) =>
    state && state.reducer && state.reducer.timeslots;

const getStoreRef = (state) => state && state.reducer && state.reducer.storeRef;

const getRescheduled = (state) =>
    state && state.reducer && state.reducer.rescheduled;

const getNumberOfDays = (state) =>
    state && state.reducer && state.reducer.bookingToolNumberOfDays;

const getSelectedTimeslot = (state) =>
    state && state.reducer && state.reducer.selectedTimeslot;

const getContext = (state) => state && state.reducer && state.reducer.context;

const getSelectedSalesRep = (state) =>
    state && state.reducer && state.reducer.selectedSalesRep;

const getStoreName = (state) =>
    state && state.reducer && state.reducer.storeName;
const getDuration = (state) => state && state.reducer && state.reducer.duration;
const getServicePicker = (state) =>
    state && state.reducer && state.reducer.servicePicker;
const getEmployeeAlreadySelected = (state) =>
    state && state.reducer && state.reducer.employeeAlreadySelected;
const getWaitwhileId = (state) =>
    state && state.reducer && state.reducer.waitwhileId;
const getSkipServices = (state) =>
    state && state.reducer && state.reducer.skipServices;
const getErrorScreen = (state) =>
    state && state.reducer && state.reducer.errorScreen;
const getResourceId = (state) =>
    state && state.reducer && state.reducer.resourceId;
const getSympaEmployeeId = (state) =>
    state && state.reducer && state.reducer.sympaEmployeeId;
export {
    getServiceId,
    getDescription,
    getLanguage,
    getLastFittingId,
    getBookingToolAvailableDates,
    getNumberOfDays,
    getStoreId,
    getAvailableTimeslots,
    getTimeslots,
    getStoreRef,
    getSelectedTimeslot,
    getContext,
    getSelectedSalesRep,
    getRescheduled,
    getStoreName,
    getDuration,
    getServicePicker,
    getEmployeeAlreadySelected,
    getWaitwhileId,
    getSkipServices,
    getErrorScreen,
    getResourceId,
    getSympaEmployeeId
};
