import styled from 'styled-components';
import { grey, brandColor } from 'styles/variables';
import { mq } from 'styles/mediaqueries';

const getEmployeeStyle = {
    cursor: 'default',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 0',
    borderStyle: 'solid',
    borderWidth: '1px 0 0 0',
    borderColor: (props) =>
        props.isSelected ? 'rgba(232, 231, 229, 0.1)' : grey,

    opacity: (props) => (props.isDisabled ? 0.1 : 1),

    /* stylelint-disable-next-line */
    [mq[0]]: {
        margin: '0 25px',
    },

    button: {
        margin: '0',
        width: 'auto',
        maxWidth: 'none',
        minHeight: '32px',
        fontSize: '13px',
        lineHeight: '15px',
        padding: '3px 17px',
        // pointerEvents: (props) => (props.isDisabled ? 'none' : 'auto'),
        borderColor: (props) => (props.isSelected ? brandColor : grey),

        '&:focus': {
            borderColor: (props) => (props.isSelected ? brandColor : grey),
        },

        '&:hover': {
            borderColor: brandColor,
        },

        '&:focus&:hover': {
            borderColor: brandColor,
        },

        /* stylelint-disable-next-line */
        [mq[3]]: {
            padding: '10px 18px',
        },
    },
};

const getNoPrefStyle = {
    ...getEmployeeStyle,
};

export const StyledEmployee = styled.li(getEmployeeStyle);
export const StyledNoPref = styled.div(getNoPrefStyle);

const StyledEmployeesListImage = {
    width: 90,
    height: 90,
    borderRadius: '100%',
    marginRight: 20,
    flexShrink: 0,

    /* stylelint-disable-next-line */
    [mq[0]]: {
        width: 100,
        height: 100,
        marginRight: 30,
    },
};

const getEmployeeImageStyle = {
    ...StyledEmployeesListImage,

    img: {
        width: '100%',
        height: '100%',
    },
};

const getdNoPrefImageStyle = {
    ...StyledEmployeesListImage,
    justifyContent: 'center',
    alignItems: 'center',
};

const getAdvisorImageStyle = {
    img: {
        width: '130px',
        height: '50px',
        marginBottom: '10px',
    },
};

export const StyledTag = styled.strong`
    font-size: 0.556em;
    font-weight: normal;
    line-height: 18px;
    color: white;
    display: inline-block;
    background-color: ${brandColor};
    border-radius: 3px;
    padding: 1px 6px 2px;
    margin-left: 6px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    ${[mq[3]]} {
        line-height: 22px;
    }
`;

export const StyledEmployeeContainer = styled.span`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;

    /* stylelint-disable-next-line */

    ${[mq[3]]} {
        font-size: 24;
        line-height: 28px;
    }
`;

export const StyledTextAdvisor = styled.p`
    font-family: GT America;
    font-size: 18px;
    line-height: 18px;
    margin: 15px 0;
`;

export const StyledAdvisorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
    font-size: 16px;
    line-height: 18px;
    padding-top: 20px;

    /* stylelint-disable-next-line */
    ${[mq[2]]} {
        margin-top: 30px;
    }
    ,
    ${[mq[3]]} {
        font-size: 24;
        line-height: 28px;
    }
`;

export const StyledNoPrefImage = styled.span(getdNoPrefImageStyle);
export const StyledEmployeeImage = styled.span(getEmployeeImageStyle);
export const StyledAdvisorImage = styled.span(getAdvisorImageStyle);
