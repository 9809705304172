import React from 'react';

export const ValidIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
            d="M15.84 4.66l-.77.79c-2.13 2.18-4.4 4.65-6.52 6.86L5.61 9.84l-.84-.71-1.39 1.72.84.72 3.71 3.12.77.65.7-.72c2.35-2.41 4.9-5.23 7.21-7.59l.77-.8z"
            fill="#2d2e2c"
            fillRule="evenodd"
        />
    </svg>
);
